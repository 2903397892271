import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map, of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import { Scenario } from './scenario';

@Injectable({
  providedIn: 'root'
})
export class ScenariosService {

  constructor(
    private _httpClient: HttpClient
  ) {
  }

  getScenarios(page: number, size: number): Promise<Scenario[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v5/scenario/paged?itemsPerPage=${size}&pageNumber=${page}`)
        .pipe(
          map((entry) => entry["records"])
        )
    );
  }  

  deleteScenario(id: string){
    return this._httpClient.delete(`${environment.opsapi}/v4/scenario/${id}`);
  }

  updateScenario(id: string, scenario: Scenario) {    
    return this._httpClient.put(`${environment.opsapi}/v4/scenario/${id}`, scenario);
  }

  addScenario(scenario: Scenario) {    
    return this._httpClient.post(`${environment.opsapi}/v4/scenario`, scenario);
  }

  getScenarioById(scenario: Scenario) {
    return this._httpClient.get(`${environment.opsapi}/v4/scenario/${scenario.id}`);
  }
}

