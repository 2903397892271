<div mat-dialog-content>
    <div class="shadow-lg overflow-hidden">
      <div class="flex border-b">
        <!-- Selected product form -->
        <form
          class="flex flex-col w-full"
          [formGroup]="selectedProductForm"
        >
          <div fxLayout="row">
  
            <mat-form-field class="pr-2" fxFlex="40">
              <mat-label>Name</mat-label>
              <input matInput [formControlName]="'name'"/>              
            </mat-form-field>


            <mat-form-field class="pr-2" fxFlex="40">
                <mat-label>Title</mat-label>
                <input matInput [formControlName]="'title'"/>
              </mat-form-field>

              <mat-form-field class="pr-2" fxFlex="20">
                <mat-label>Sort Order</mat-label>
                <input matInput [formControlName]="'sortOrder'"/>             
              </mat-form-field>
          </div>
  
          <div fxLayout="row">
  
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Image url</mat-label>
              <input matInput [formControlName]="'imageUrl'"/>              
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Signal bundle url</mat-label>
              <input matInput [formControlName]="'signalBundleUrl'"/>              
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="33">
                <mat-label>Playlist url</mat-label>
                <input matInput [formControlName]="'playlistUrl'"/>              
              </mat-form-field>
          </div>

          <div fxLayout="row">
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Signal bundle Description</mat-label>
              <input matInput [formControlName]="'signalBundleDescription'"/>              
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
                <mat-label>Playlist Description</mat-label>
                <input matInput [formControlName]="'playlistDescription'"/>              
              </mat-form-field>
          
          </div>
  
          
          <div mat-dialog-actions>
            <div
              class="flex items-center justify-between w-full border-t px-8 py-4"
            >
            <app-close-dialog></app-close-dialog>
              <div class="flex items-center">
                <div
                  class="flex items-center mr-4"
                  *ngIf="flashMessage"
                >
                  <ng-container
                    *ngIf="flashMessage === 'success'"
                  >
                    <mat-icon
                      class="text-green-500"
                      [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <span class="ml-2">{{actionMessage}}</span>
                  </ng-container>
                  <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon
                      class="text-red-500"
                      [svgIcon]="'heroicons_outline:x'"
                    ></mat-icon>
                    <span class="ml-2">{{responseMessage}}</span>
                  </ng-container>
                </div>
                <button
  
                  mat-flat-button
                  [color]="'primary'"
                  (click)="saveCategory()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  