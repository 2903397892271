import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { NotificationsService } from '../notifications.service';
import { Notification } from '../notifications';


@Component({
  selector: 'app-notification-editor',
  templateUrl: './notification-editor.component.html',
  styleUrls: ['./notification-editor.component.scss']
})
export class NotificationEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  imagesForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  notification: Notification;
  responseMessage: string;
  actionMessage: string;
  messageSuccess: Boolean = true;

  constructor(
    private _formBuilder: FormBuilder,
    private _notificationService: NotificationsService,
    public dialogRef: MatDialogRef<NotificationEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    this.selectedProductForm = this._formBuilder.group({
      types: ['', [Validators.required]],
      communicationType: ['', [Validators.required]],
      title: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });
  }

  closeDialog(value = false) {
    this.dialogRef.close(value);
  }

  showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialog();
      }
      this.flashMessage = null;
      // Mark for check
      this._changeDetectorRef.markForCheck();

    }, 3000);
  }


  async addNotification() {
    if (this.selectedProductForm.invalid) {
        return;
      } else {
        const picked = this.selectedProductForm.getRawValue();       
        this.saving = true;
  
        this._notificationService.createNotification(picked).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Notification Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
    }



}