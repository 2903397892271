import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import {mergeMap, Observable, of, switchMap} from 'rxjs';
import {AuthServiceClass} from 'app/core/auth/auth.service';
import {AuthService} from '@auth0/auth0-angular';
import {Inject} from '@angular/core';
import {InventoryProduct} from '../../../modules/admin/apps/accounts/inventory/inventory.types';

@Injectable({
  providedIn: 'root',
})
export class Auth0Guard implements CanActivate, CanActivateChild, CanLoad {
  _user: InventoryProduct = null;

  /**
   * Constructor
   */
  constructor(
    private _AuthServiceClass: AuthServiceClass,
    private _router: Router,
    @Inject(AuthService) public auth: AuthService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._check();
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(): Observable<boolean> {
    // Check the authentication status
    // this.auth.idTokenClaims$.subscribe((claims) => console.log('claims', claims));

    return this.auth.isAuthenticated$.pipe(
      switchMap((authenticated) => {
        // If the user is authenticated...
        if (!authenticated) {
          // Redirect to the root
          this._router.navigateByUrl('');

          // Prevent the access
          return of(false);
        }

        // Allow the access
        return of(true);
      }),

    );
  }
}
