export class Documents {
    id: string;
    text: string;
    documentType: string;
    version: number;
}

export enum DocumentTypes {
    TermsConditions = 'TermsConditions',
    PrivacyPolicy = 'PrivacyPolicy'
  }