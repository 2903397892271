<div fxLayout="row wrap" fxLayoutGap="20px" class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title class="text-2xl font-extrabold tracking-tight">{{ scenario.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="divider"></div>
      <mat-divider class="divider"></mat-divider>
      <div class="divider"></div>
      <h3 class="bold">SignalBundles</h3>

      <mat-chip *ngFor="let blend of blends">
        {{ blend.name }}
      </mat-chip>


      <div class="divider"></div>
      <mat-divider class="divider"></mat-divider>
      <div class="divider"></div>
      <h3 class="bold">Playlists</h3>

  
      <mat-chip class="secondary" *ngFor="let routine of routines">
        {{ routine.name }}        
      </mat-chip>


    </mat-card-content>      
  </mat-card>
</div>

<div mat-dialog-actions>
  <app-close-dialog></app-close-dialog>
</div>