import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SharedDataService } from './../../routines/shared.data.service';
import { NotesDetails } from '../account.notes';
import { InventoryService } from '../inventory/inventory.service';


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesEditorComponent implements OnInit {
  public edited = false;
  rdata = this.sharedDataService.getData();
  isViewData = this.sharedDataService.getViewDataFlag();
  selectedProductForm: FormGroup;
  notesDetails: NotesDetails;
  productId: string;
  saving: boolean = false;
  actionMessage: string;
  flashMessage: 'success' | 'error' | null = null;
  responseMessage: string;
  
  constructor(
    private _formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private _inventoryService: InventoryService,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<NotesEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.productId = data.productId;
      if (data.note!==null){
        this.notesDetails = data.note;
      }
  }

  ngOnInit(): void {

    if(this.isViewData){
      this.edited = false;
      this.selectedProductForm = this._formBuilder.group({
        notes: [{ value: '', disabled: true }, [Validators.required]],              
      });    
    }
    else{
      this.edited = true;
      this.selectedProductForm = this._formBuilder.group({
        notes: ['', [Validators.required]],
        
      });
    }

    if (this.rdata){      
      this.selectedProductForm.get('notes').setValue(this.rdata.notes);      
    }

  }

  closeDialog(value = false) {
    this.dialogRef.close(value);
  }

  showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    if (type === 'success') {
      setTimeout(async () => {
        if (this.flashMessage === 'success')
        {
          this.closeDialog(true);
        }
        // Mark for check
        this._changeDetectorRef.markForCheck();

      }, 1000);
    }
  }

  saveNotes() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
      const picked = this.selectedProductForm.getRawValue();
      
      let newNote = new NotesDetails();
      newNote.notes = picked.notes;
      this.saving = true;
      if (this.notesDetails == null) {
        this._inventoryService.createAccountNotes(this.productId, newNote).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Notes Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      else{
        this._inventoryService.updateAccountNotes(this.productId, this.notesDetails.id, newNote).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Notes Updated';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }

      
    }
  }

}
