<div mat-dialog-content>
  <div class="shadow-lg overflow-hidden">
    <div class="flex border-b">
      <!-- Selected product form -->
      <form
        class="flex flex-col w-full"
        [formGroup]="selectedProductForm"
      >
        <div fxLayout="row">

          <mat-form-field class="pr-2" fxFlex="33">
            <mat-label>Name</mat-label>
            <input matInput [formControlName]="'name'"/>
          </mat-form-field>

      
          <mat-form-field class="pr-2" fxFlex="33">
            <mat-label>Description</mat-label>
            <input matInput [formControlName]="'description'"/>                 
          </mat-form-field>
               

          <mat-form-field class="pr-2" fxFlex="33">
            <mat-label>Category</mat-label>
            <mat-select [formControlName]="'categoryId'" (selectionChange)="filterPlayables()">
              <mat-option *ngFor="let cat of categories" [value]="cat.id">
                {{ cat.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        
        </div>

        <div fxLayout="row">

        </div>

        <div fxLayout="row">
          <mat-form-field class="pr-2" fxFlex="50">
            <mat-label>SignalBundles</mat-label>
            <mat-select [formControlName]="'signalBundleIds'" multiple>
              <mat-option *ngFor="let blend of blends" [value]="blend.id">
                {{ blend.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="pr-2" fxFlex="50">
            <mat-label>Playlists</mat-label>
            <mat-select [formControlName]="'playlistIds'" multiple>
              <mat-option *ngFor="let routine of routines" [value]="routine.id">
                {{ routine.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div mat-dialog-actions>
          <div
            class="flex items-center justify-between w-full border-t px-8 py-4"
          >
          <app-close-dialog></app-close-dialog>
            <div class="flex items-center">
              <div
                class="flex items-center mr-4"
                *ngIf="flashMessage"
              >
                <ng-container
                  *ngIf="flashMessage === 'success'"
                >
                  <mat-icon
                    class="text-green-500"
                    [svgIcon]="'heroicons_outline:check'"
                  ></mat-icon>
                  <span class="ml-2">{{actionMessage}}</span>
                </ng-container>
                <ng-container *ngIf="flashMessage === 'error'">
                  <mat-icon
                    class="text-red-500"
                    [svgIcon]="'heroicons_outline:x'"
                  ></mat-icon>
                  <span class="ml-2">{{responseMessage}}</span>
                </ng-container>
              </div>
              <button

                mat-flat-button
                [color]="'primary'"
                (click)="saveScenario()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
