import { Component, OnInit } from '@angular/core';
import {Device} from './devices';
import {DeviceService} from './devices.service';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";
import {ChangeDetectorRef} from "@angular/core";
import { FormGroup, FormBuilder} from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { DeviceDetailsComponent } from './device-details/device-details.component';
import { DeviceAccountDetailsComponent } from './device-account-details/device-account-details.component';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  isLoading: boolean = false;
  devices: Device[] = [];
  fullDevices: Device[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  userForm: FormGroup;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _deviceService: DeviceService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit() : Promise<void>{
 
    this._titleService.setTitle('Hapbee OpsPortal - Devices');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:device')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:device');
    this.canEdit = perms.includes('update:device');
    this.canDelete = perms.includes('delete:device');
  
    await this.getDevices();
    this.isLoading = false;

    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(800),
        switchMap(async (query) => {

          if (query){
            this.isLoading = true;

          await this._deviceService.getDeviceByCode(query).subscribe({
            next: (e: any) => {             
              if (e.device.id) {
                this.devices = [e.device];
                for (let details of this.devices) {
                  try{details.created = details.created.split("T")[0]; }
                  catch(e:unknown){}
                  
                }
              } else {
                this.devices = [];
              }
            }, error: (err => {
              this.devices = [];
            })
          });

        this._changeDetectorRef.markForCheck();
          }
        
        }),
        map(() => {
          this.isLoading = false;
        })
      )
      .subscribe();

  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.devices;
  }


  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.devices = this.fullDevices;
    this.isLoading = false;
  }


  async getDevices() {
    this.isLoading = true;  
    this.devices = await this._deviceService.getAllDevices();
    for (let details of this.devices) {
        try{details.created = details.created.split("T")[0]; }
        catch(e:unknown){}
    }
    
    this.fullDevices = this.devices;
    this.isLoading = false;
  }

  viewDetails(device: Device) {
    let dialogref = this.dialog.open(DeviceDetailsComponent, {
      disableClose: false,
      width: '550px',
      height: 'auto',
      data: device
    });
    
  }

  viewAccountDetails(device: Device) {
    let dialogref = this.dialog.open(DeviceAccountDetailsComponent, {
      disableClose: false,
      width: '650px',
      height: 'auto',
      data: device
    });
    
  }

}
