export class RechargeCustomer{
    id: string;
    accountId: string;
    customerData: CustomerData;

}

export class Customer{
    billing_address1: string;
    billing_address2: string;
    billing_city: string;
    billing_company: string;
    billing_country: string;
    billing_phone: string;
    billing_province: string;
    billing_zip: string;
    created_at: string;
    email: string;
    first_charge_processed_at: string;
    first_name: string;
    has_card_error_in_dunning: boolean;
    has_valid_payment_method: boolean;
    hash: string;
    id: number;
    last_name: string;
    accepts_marketing: boolean;
    can_add_payment_method: boolean;
    number_active_subscriptions: number;
    number_subscriptions: number;
    processor_type: string;
    reason_payment_method_not_valid: string;
    shopify_customer_id: string;
    status: string;
    updated_at: string;
}

export class CustomerData{
    customer: Customer;
}
