import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map, of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Blend} from './blend';
import { MembershipLevel } from './membership-levels';

@Injectable({
  providedIn: 'root'
})
export class MembershipLevelService {

  constructor(
    private _httpClient: HttpClient
  ) {
  }

  getBlends(): Promise<Blend[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/signalBundles/`)
        .pipe(
          map((entry) => entry["data"])
        )
    );
  }

  getMembershipLevels(page: number, size: number): Promise<MembershipLevel[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v5/membershiplevel/paged?itemsPerPage=${size}&pageNumber=${page}`)
        .pipe(
          map((entry) => entry["records"])
        )
    );
  }  

  deleteMembershipLevel(id: string){
    return this._httpClient.delete(`${environment.opsapi}/v4/membershiplevel/${id}`);
  }

  updateMembershipLevel(id: string, membershiplevel: MembershipLevel) {    
    return this._httpClient.put(`${environment.opsapi}/v4/membershiplevel/${id}`, membershiplevel);
  }

  addMembershipLevel(membershiplevel: MembershipLevel) {    
    return this._httpClient.post(`${environment.opsapi}/v4/membershiplevel`, membershiplevel);
  }

  getMembershipLevelById(membershiplevel: MembershipLevel) {
    return this._httpClient.get(`${environment.opsapi}/v4/membershiplevel/${membershiplevel.id}`);
  }
}

