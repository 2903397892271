import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Blend } from '../../membership-levels/blend';
import {Routine} from '../../routines/routine';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Category } from '../../categories/category';
import { ScenariosService } from '../scenarios.service';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';
import { Scenario } from '../scenario';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-scenario-editor',
  templateUrl: './scenario-editor.component.html',
  styleUrls: ['./scenario-editor.component.scss']
})
export class ScenarioEditorComponent implements OnInit {

  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  blends: Blend[];
  routines: Routine[];
  fullBlends: Blend[];
  fullRountines: Routine[];
  categories: Category[];
  scenario: Scenario;
  saving: boolean = false;
  responseMessage: string;
  actionMessage: string;
  

  constructor(
    private _formBuilder: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ScenarioEditorComponent>,
    private _scenariosService: ScenariosService,
    private closeDialogComponent: CloseDialogComponent,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, //{blends: this.blends, routines: this.routines, offer: null}
  ) {

    this.fullBlends = data.blends;
    this.fullRountines = data.routines;
    this.blends = data.blends;
    this.routines = data.routines;
    this.categories = data.categories;
    if (data.scenario) {
      this.scenario = {...data.scenario};      
    }
  }

  ngOnInit(): void {
    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      description: [''],
      categoryId: ['', [Validators.required]],
      playlistIds: [[]],
      signalBundleIds: [[]],
    });

    if (this.scenario) {
      this.selectedProductForm.get('name').setValue(this.scenario.name);
      this.selectedProductForm.get('description').setValue(this.scenario.description);      
      this.selectedProductForm.get('categoryId').setValue(this.scenario.categoryId);
      this.selectedProductForm.get('playlistIds').setValue(this.scenario.playlistIds);
      this.selectedProductForm.get('signalBundleIds').setValue(this.scenario.signalBundleIds);
      this.filterPlayables();
    } else {
      this.scenario = new Scenario();
      this.scenario.availableBlends = [];
      this.scenario.availableRoutines = [];
      this.blends = [];
      this.routines = [];
    }
  }

  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 2500);
  }

  filterPlayables(){
    let categoryValue = this.selectedProductForm.controls['categoryId'].value;    
    this.blends = this.fullBlends.filter(o => o.categoryId === categoryValue);
    this.routines = this.fullRountines.filter(o => o.categoryId === categoryValue);
  }



  saveScenario() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const scenario = this.selectedProductForm.getRawValue();
      this.saving = true;
      if (this.scenario.id) {
        this._scenariosService.updateScenario(this.scenario.id, scenario).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Scenario Updated';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      } else {
        this._scenariosService.addScenario(scenario).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Scenario Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }

    }
  }
}
