import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { InventoryService } from '../inventory/inventory.service';
import { Offer } from '../../offers/offer';
import moment from 'moment';
import { MembershipLevel } from '../../membership-levels/membership-levels';
import { MembershipLevelService } from '../../membership-levels/membership-levels.service';


@Component({
  selector: 'app-customermembership',
  templateUrl: './customermembership.component.html',
  styleUrls: ['./customermembership.component.scss']
})
export class CustomerMembershipEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  productId: string;
  saving: boolean = false;
  actionMessage: string;
  flashMessage: 'success' | 'error' | null = null;
  responseMessage: string;
  membershipLevels: MembershipLevel[];
  startDate: Date = new Date();
  
  constructor(
    private _formBuilder: FormBuilder,
    private _inventoryService: InventoryService,
    private _membershipLevelService: MembershipLevelService,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CustomerMembershipEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.productId = data.productId;
      this.membershipLevels = data._membershiplevels;
  }

  async ngOnInit(): Promise<void> {
   this.selectedProductForm = this._formBuilder.group({
      membershipId: ['', [Validators.required]],
    });

     
  }


  closeDialog(value = false) {
    this.dialogRef.close(value);
  }

  showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    if (type === 'success') {
      setTimeout(async () => {
        if (this.flashMessage === 'success')
        {
          this.closeDialog(true);
        }
        // Mark for check
        this._changeDetectorRef.markForCheck();

      }, 1000);
    }
  }

  saveMembership() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
      const picked = this.selectedProductForm.getRawValue();

      this._inventoryService.addCustomerMembership(this.productId, picked.membershipId).subscribe({
        next: (e: any) => {
          if (e.modified) {
            this.actionMessage = 'Membership Added';
            this.showFlashMessage('success');
          } else {
            this.showFlashMessage('error');
          }
        }, error: (err => {
          this.responseMessage = err.error.message;
          this.showFlashMessage('error');
        })
      });
           
      
    }
  }

}
