import { Injectable } from "@angular/core";
import { Translation, TranslocoInterceptor } from "@ngneat/transloco";

@Injectable({ providedIn: 'root' })
export class TranslocoCoreInterceptor implements TranslocoInterceptor
{
    preSaveTranslation(translation: Translation, lang: string): Translation {
        console.log(`Original translation value: ${translation.value}`);
        return translation;
    }

    preSaveTranslationKey(key: string, value: string, lang: string): string {
        console.log(`Original translation key: ${key}`);
        return key;
    }
}