<div mat-dialog-content>
    <div class="shadow-lg overflow-hidden">
      <div class="flex border-b">
        <!-- Selected product form -->
        <form class="flex flex-col w-full" [formGroup]="selectedProductForm">
          <div fxLayout="row"> 
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>First Name</mat-label>
              <input matInput [formControlName]="'first_name'"/>        
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
                <mat-label>Last Name</mat-label>
                <input matInput [formControlName]="'last_name'"/>       
              </mat-form-field>
          </div>

          <div fxLayout="row"> 
            <mat-form-field class="pr-2" fxFlex="100">
              <mat-label>Email</mat-label>
              <input matInput [formControlName]="'email'"/>                      
            </mat-form-field>
          </div>

          <div fxLayout="row"> 
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Address 1</mat-label>
              <input matInput [formControlName]="'billing_address1'"/>                    
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
                <mat-label>Address 2</mat-label>
                <input matInput [formControlName]="'billing_address2'"/>                     
              </mat-form-field>
          </div>

          <div fxLayout="row"> 
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>City</mat-label>
              <input matInput [formControlName]="'billing_city'"/>                    
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="33">
                <mat-label>Company</mat-label>
                <input matInput [formControlName]="'billing_company'"/>                     
              </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="33">
                <mat-label>Country</mat-label>
                <input matInput [formControlName]="'billing_country'"/>                    
            </mat-form-field>
          </div>

          <div fxLayout="row"> 
            <mat-form-field class="pr-2" fxFlex="33">
                <mat-label>Province</mat-label>
                <input matInput [formControlName]="'billing_province'"/>                     
              </mat-form-field>

              <mat-form-field class="pr-2" fxFlex="33">
                <mat-label>Phone</mat-label>
                <input matInput [formControlName]="'billing_phone'"/>                    
              </mat-form-field>
  
              <mat-form-field class="pr-2" fxFlex="33">
                  <mat-label>Zip</mat-label>
                  <input matInput [formControlName]="'billing_zip'"/>                     
                </mat-form-field>

          </div>


          <div fxLayout="row"> 
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Recharge Id</mat-label>
              <input matInput [formControlName]="'id'"/>                  
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
                <mat-label>Shopify Id</mat-label>
                <input matInput [formControlName]="'shopify_customer_id'"/>                          
              </mat-form-field>

             
          </div>


          <div mat-dialog-actions>
            <div
              class="flex items-center justify-between w-full border-t px-8 py-4">
            
              <button class="-ml-4" mat-button [color]="'warn'" mat-dialog-close mat-dialog-close="true" (click)="closeDialog()">

                Close
              </button>
              <div class="flex items-center">
                <div
                  class="flex items-center mr-4"
                  *ngIf="flashMessage"
                >
                  <ng-container
                    *ngIf="flashMessage === 'success'"
                  >
                    <mat-icon
                      class="text-green-500"
                      [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <span class="ml-2">{{actionMessage}}</span>
                  </ng-container>
                  <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon
                      class="text-red-500"
                      [svgIcon]="'heroicons_outline:x'"
                    ></mat-icon>
                    <span class="ml-2"
                    >{{responseMessage}}</span
                    >
                  </ng-container>
                </div>
                <button
  
                  mat-flat-button
                  [color]="'primary'"
                  (click)="addRechargeCustomer()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
         
        </form>
      </div>
    </div>
  </div>
  