import {Tag} from '../tags/tags';
import {Category} from '../categories/category';
import {Blend} from '../offers/blend';
import { PlaybackModes } from '../playback-modes';


export class Routine {
  id: string;
  name: string;
  categoryId: string;
  category: Category;
  playbackId: number;
  sortOrder: number;
  tags: string[];
  rtags: Tag[];
  imageUrl: string;
  iconUrl: string;
  heroBannerUrl: string;
  description: string;
  shortDescription: string;
  contentDescription: string;
  items: RoutineItem[];
  playbackModes: PlaybackModes;
  isPubliclyAvailable: boolean;
  isHidden: boolean;
}

export class RoutineItem {
  blend: Blend;
  signalBundleId: string;
  suggestedDuration: string;
  sortOrder: number;
  feedback: RoutineFeedback;
}

export class RoutineFeedback {
  headBand: RoutineHeadband;
  sleepPad: RoutineSleepPad;
}

export enum Haptics {
  none = 'none',
  one = 'one',
  two = 'two'
}

export enum SleeppadHaptics {
  none = 'none',
  one = 'one',
  two = 'two'
}

export class RoutineHeadband {
  haptics: Haptics; //none, one, two
  light: RoutineLight;
}

export class RoutineSleepPad {
  haptics: SleeppadHaptics; //none, one, two
}

export enum LightMode {
  static = 'Static',
  breathe = 'Breathe'
}

export class RoutineLight {
  mode: LightMode; //Static, Breathe
  brightness: number; // 0-255
  color: RoutineColor;
}

export class RoutineColor {
  red: number; // 0-255
  green: number; // 0-255
  blue: number; // 0-255

}



