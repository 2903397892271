export class Category {
  id: string;
  parentId: string;
  name: string;
  title: string;
  color: string;
  sortOrder: string;
  imageUrl: string;
  iconUrl: string;  
}




