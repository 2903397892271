export class Category {
  id: string;
  name: string;
  title: string;
  imageUrl: string;
  signalBundleUrl: string;
  playlistUrl: string;
  signalBundleDescription: string;
  playlistDescription: string;
  sortOrder: string;
}
