import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss']
})
export class DeviceDetailsComponent implements OnInit{
  isLoading: boolean = false;
  device: any;
  hasProfile: boolean;
  hasAuth: boolean;
  accountCreationDate: string;
 

  constructor(
    public dialogRef: MatDialogRef<DeviceDetailsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.device = data;
  }
    

  async ngOnInit(){
    this.isLoading = true;    
    this.isLoading = false;
  }

  
  closeDialog(value = false) {
    this.dialogRef.close(value);
  }


  
}
