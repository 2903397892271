<div fxFlexAlign="stretch" class="container">
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <mat-card>
        <mat-card-header>
          <mat-card-title class="text-2xl font-extrabold tracking-tight">Device Details</mat-card-title>
        </mat-card-header>
  
        <mat-card-content> 
            <div>
                <h4>Code : {{device.code}}</h4>
                <h4>Device Key : {{device.deviceKey}}</h4>
                <h4>Encrypted Device Key : {{device.encryptedDeviceKey}}</h4>
                <h4>Device Status : {{device.deviceStatus}}</h4>
                <h4>Device Type : {{device.deviceType}}</h4>
                <h4>Serial Number : {{device.serialNumber}}</h4>
                <h4>WorkStation Id : {{device.workStationId}}</h4>
                <h4>Ip Address : {{device.ipAddress}}</h4>
                <h4>Advertisement Name : {{device.advertisementName}}</h4>
                <h4>Pinnacle QR Code: : {{device.pinnacleQrCode}}</h4>

            </div>
        </mat-card-content>
    </mat-card>
  </div>
  
  <div mat-dialog-actions>
    <div class="flex items-center justify-between w-full border-t px-8 py-4">                     
      <button class="-ml-4" mat-button  [color]="'warn'" mat-dialog-close mat-dialog-close="true" (click)="closeDialog()">Close</button>
      
    </div>
  </div>
  
    
  