import {ChangeDetectorRef, Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {SignalsService} from '../signals.service';
import {PlayBackIntensities, Signal} from '../signal';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';
import { SharedDataService } from '../../routines/shared.data.service';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-signal-editor',
  templateUrl: './signal-editor.component.html',
  styleUrls: ['./signal-editor.component.scss']
})
export class SignalEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  responseMessage: string;
  signal: Signal;
  actionMessage: string;
  newSignal: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _signalsService: SignalsService,
    public dialogRef: MatDialogRef<SignalEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private closeDialogComponent: CloseDialogComponent,
    private sharedDataService: SharedDataService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.signal = data.signal;
   }

  ngOnInit(): void {
    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      hardwareId: ['', [Validators.required]],
      file: [''],
      fileData: [''],
    });

    if(this.signal)
    {
      this.selectedProductForm.controls.name.setValue(this.signal.name);
      this.selectedProductForm.controls.hardwareId.setValue(this.signal.hardwareId);
    }
    else {
        this.newSignal = true;
        this.signal = new Signal();
        this.signal.playbackIntensities = [];
    }

  }


  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success'){
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 2000);
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.selectedProductForm.patchValue({
      fileData: file,
    });
    this.selectedProductForm.get('fileData').updateValueAndValidity();
  }

  updateSignal() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const signal = this.selectedProductForm.getRawValue();

      let formData: any = new FormData();
      formData.append('name', signal.name);
      formData.append('hardwareId', signal.hardwareId);
      formData.append('file', signal.fileData);

      this.saving = true;      
      if(this.newSignal === true)
      {
        this._signalsService.addSignal(formData).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Signal Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      else
      {
        this._signalsService.updateSignal(this.signal.id, formData).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Signal Updated';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      
    }
  }

  

}
