<div fxFlexAlign="stretch" class="container">
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <mat-card>
        <mat-card-header>
          <mat-card-title>Document : {{ document.documentType}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="modified-mc-content">
            {{document.text}}
        </mat-card-content>
    </mat-card>
    
  </div>
  
  <div mat-dialog-actions>
    <div class="flex items-center justify-between w-full border-t px-8 py-4">                     
    <app-close-dialog></app-close-dialog>
      
    </div>
  </div>
  
    
  