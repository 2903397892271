import {Component, OnInit} from '@angular/core';
import {Routine} from './routine';
import {Tag} from '../tags/tags';
import {Category} from '../categories/category';
import {TagsService} from '../tags/tags.service';
import {CategoriesService} from '../categories/categories.service';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {MatDialog} from '@angular/material/dialog';
import {RoutinesService} from './routines.service';
import {RoutineEditorComponent} from './routine-editor/routine-editor.component';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";
import {Blend} from '../offers/blend';
import {OffersService} from '../offers/offers.service';
import { ViewImagesRoutinesComponent } from '../../common-components/view-images/view-images-routines/view-images-routines.component';

@Component({
  selector: 'app-routines',
  templateUrl: './routines.component.html',
  styleUrls: ['./routines.component.scss']
})
export class RoutinesComponent implements OnInit {
  isLoading: boolean = false;
  routines: Routine[] = [];
  fullRoutines: Routine[] = [];
  blends: Blend[] = []
  tags: Tag[] = [];
  categories: Category[];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  query = "";
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  

  constructor(
    private _routinesService: RoutinesService,
    private _offersService: OffersService,
    private _tagsService: TagsService,
    private _categoriesService: CategoriesService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _auth: AuthServiceClass,
    private _titleService: Title,
    public dialog: MatDialog
  ) {
  }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Routines');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:routine')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:routine');
    this.canEdit = perms.includes('update:routine');
    this.canDelete = perms.includes('delete:routine');

    this.isLoading = true;

    this.blends = await this._offersService.getBlends();
    this.tags = await this._tagsService.getTags();
    this.categories = await this._categoriesService.getCategories();

    await this.getRoutines();
        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(500),
          switchMap(async (query) => {
            this.isLoading = true;
            this.query = query.trim();
            if(this.query){
              this.routines = this.routines.filter(o => o.name.toLowerCase().includes(this.query.toLowerCase()));
            }
            else{
              this.routines = this.fullRoutines;
            }
  
          }),
          map(() => {
            this.isLoading = false;
          })
        )
        .subscribe();
  }

  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.routines = this.fullRoutines;
    this.isLoading = false;
  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.routines;
  }

  edit(routine) {
    let dialogref = this.dialog.open(RoutineEditorComponent,
      {
        width: '900px',
        disableClose: true,
        data: {blends: this.blends, tags: this.tags, categories: this.categories, routine: {...routine}, cloning: null}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getRoutines();
        this.isLoading = false;
      }
    });
  }

  clone(routine) {
    let dialogref = this.dialog.open(RoutineEditorComponent,
      {
        width: '900px',
        disableClose: true,
        data: {blends: this.blends, tags: this.tags, categories: this.categories, routine: {...routine}, cloning: true}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getRoutines();
        this.isLoading = false;
      }
    });
  }

  viewImages(routine) {
    let dialogref = this.dialog.open(ViewImagesRoutinesComponent,
      {width: '425px', disableClose: false, data: {item: routine}});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.getRoutines();
      }
    });
  }

  add() {
    let dialogref = this.dialog.open(RoutineEditorComponent,
      {
        width: '900px',
        disableClose: true,
        data: {blends: this.blends, tags: this.tags, categories: this.categories, routine: null, cloning: null}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getRoutines();
        this.isLoading = false;
      }
      
    });
  }

  async delete(routine: Routine) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Routine?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }
      await this._routinesService.deleteRoutine(routine);
      this.isLoading = true;
      await this.getRoutines();
      this.isLoading = false;
      this.dialog.open(SharedAlertComponent,
        {
          width: 'auto',
          disableClose: false,
          data: {dialogMessage:'Routine : ' + routine.name + ' is deleted successfully'}
      });
    });

  }

  private async getRoutines() {
    this.routines = await this._routinesService.getRoutines();
    this.routines.sort((a, b) => a.sortOrder - b.sortOrder);


    for (let routine of this.routines) {
      let blendIdsToRemove = [];

      routine.category = this.categories.find(c => c.id === routine.categoryId);

      for (let blendItem of routine.items) {
        blendItem.blend = this.blends.find(c => c.id === blendItem.signalBundleId);
        if (!blendItem.blend) {
          console.error('bad data in routine, blend does not exist', routine.id, blendItem.signalBundleId);
          blendIdsToRemove.push(blendItem.signalBundleId);
        }
      }
      for (let blendIdsToRemoveElement of blendIdsToRemove) {
        let item = routine.items.find(b => b.signalBundleId === blendIdsToRemoveElement, 0);
        let index = routine.items.indexOf(item, 0);
        let res = routine.items.splice(index, 1);
        console.warn('removing blend from routine', blendIdsToRemoveElement, index, res);
      }

      routine.items.sort((a, b) => a.sortOrder - b.sortOrder);

      routine.rtags = [];
      for (let tagId of routine.tags) {
        let found = this.tags.filter(t => t.id === tagId);
        routine.rtags.push(found[0]);
      }

      this.fullRoutines = this.routines;
    }
  }
}
