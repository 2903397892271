import { Component, OnInit } from '@angular/core';
import { MembershipLevelService } from './membership-levels.service';
import {Blend} from './blend';
import { MembershipLevel } from './membership-levels';
import {Title} from '@angular/platform-browser';
import {Routine} from '../routines/routine';
import {RoutinesService} from '../routines/routines.service';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import { MembershipLevelEditorComponent } from './membership-level-editor/membership-level-editor.component';
import {MatDialog} from '@angular/material/dialog';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import { Feature } from '../features/feature';
import { FeaturesService } from '../features/features.service';
import { ViewMembershipContentComponent } from './view-content/view-content.component';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";

@Component({
  selector: 'app-membership-levels',
  templateUrl: './membership-levels.component.html',
  styleUrls: ['./membership-levels.component.scss']
})
export class MembershipLevelComponent implements OnInit {

  isLoading: boolean = false;
  blends: Blend[] = [];
  routines: Routine[] = [];
  features: Feature[] = [];
  membershiplevels: MembershipLevel[] = [];
  fullMembershiplevels: MembershipLevel[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  query = "";
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
 

  constructor(
    private _membershipLevelService: MembershipLevelService,
    private _routinesService: RoutinesService,
    private _featuresService: FeaturesService,
    private _auth: AuthServiceClass,
    private _titleService: Title,
    private _fuseConfirmationService: FuseConfirmationService,
    public dialog: MatDialog

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Membership Levels');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:membershiplevel')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:membershiplevel');
    this.canEdit = perms.includes('update:membershiplevel');
    this.canDelete = perms.includes('delete:membershiplevel');

    this.isLoading = true;

    this.blends = await this._membershipLevelService.getBlends();
    this.routines = await this._routinesService.getRoutines();
    this.features = await this._featuresService.getFeatures(1, 1000);
    await this.loadMembershipLevels();
    
    
    
        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(500),
          switchMap(async (query) => {
            this.isLoading = true;
            this.query = query.trim();
            if(this.query){
              this.membershiplevels = this.membershiplevels.filter(o => o.name.toLowerCase().includes(this.query.toLowerCase()));
            }
            else{
              this.membershiplevels = this.fullMembershiplevels;
            }
  
          }),
          map(() => {
            this.isLoading = false;
          })
        )
        .subscribe();
  }

  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.membershiplevels = this.fullMembershiplevels;
    this.isLoading = false;
  }

  async loadMembershipLevels() {
    this.isLoading = true;
    this.membershiplevels = await this._membershipLevelService.getMembershipLevels(1, 1000);
    for (let membershiplevel of this.membershiplevels) {
      membershiplevel.availableBlends = [];
      membershiplevel.availableRoutines = [];
      membershiplevel.features = [];

      for (let blendId of membershiplevel.availableSignalBundleIds) {
        let found = this.blends.filter( (blend) => blend.id === blendId)[0];
        membershiplevel.availableBlends.push(found);
      }
      for (let routineId of membershiplevel.availablePlaylistIds) {
        let found = this.routines.filter( (routine) => routine.id === routineId)[0];
        membershiplevel.availableRoutines.push(found);
      }
      for (let featureId of membershiplevel.availableFeatureFlagsIds) {
        let found = this.features.filter( (feature) => feature.id === featureId)[0];
        membershiplevel.features.push(found);
      }
       
      try{
        membershiplevel.legacyDate = membershiplevel.legacyDate.split("T")[0];          
      }
      catch(e: unknown){}
      

      this.fullMembershiplevels = this.membershiplevels;
    }

    this.membershiplevels.sort((a,b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    this.isLoading = false;

  }


  
  async onTableDataChange(event: any){
    this.page = event;
    await this.membershiplevels;
  }

 
  edit(membershiplevel) {
    let dialogref = this.dialog.open(MembershipLevelEditorComponent,
      {
        width: '700px',
        disableClose: true,
        data: {blends: this.blends, routines: this.routines, features: this.features, membershiplevel: membershiplevel}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.loadMembershipLevels();
        this.isLoading = false;
      }
      
    });
  }

  add() {
    let dialogref = this.dialog.open(MembershipLevelEditorComponent,
      {
        width: '700px',
        disableClose: true,
        data: {blends: this.blends, routines: this.routines, features: this.features, membershiplevel: null}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.loadMembershipLevels();
        this.isLoading = false;
      }
    });
  }

  viewContents(membershiplevel: MembershipLevel) {
    let dialogref = this.dialog.open(ViewMembershipContentComponent, {
      disableClose: false,
      width: 'auto',
      height: 'auto',
      data: membershiplevel
    });
    
  }

  delete(membershiplevel) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Membership Level?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }
      (await this._membershipLevelService.deleteMembershipLevel(membershiplevel.id)).subscribe({
        next: async (e: any) => {
          if (e.message === null) {
            this.isLoading = true;
            await this.loadMembershipLevels();
            this.isLoading = false;
            this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage:'Membership Level : ' + membershiplevel.name + ' is deleted successfully'}
            });
          } 
        }, error: (err => {
           this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage: err.error.message}
            });
        })
      });
  });
}
}
