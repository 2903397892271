import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NotificationsService} from '../notifications.service';
import { Notification } from '../notifications';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';
import moment from 'moment';


@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-notification-filter',
  templateUrl: './notification-filter.component.html',
  styleUrls: ['./notification-filter.component.scss']
})
export class NotificationFilterComponent implements OnInit {
  isLoading: boolean = false;
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  notification: Notification;
  responseMessage: string;
  actionMessage: string;
  notifications: Notification[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<NotificationFilterComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private closeDialogComponent: CloseDialogComponent,
    public dialog: MatDialog,
   
  ) { }
      
  

  async ngOnInit(): Promise<void> {   
    this.selectedProductForm = this._formBuilder.group({
      title: [''],
      body: [''],
      beginDate: [''],
      endDate: [''],
      status: [''],
      communicationType: [''],
      notificationType: ['']        
    });

    
  }
  
  async filterNotification() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
      const picked = this.selectedProductForm.getRawValue();
      let formattedBeginDate = '';
      let formattedEndDate = '';

      if(picked.beginDate !=='')  {
        formattedBeginDate = (moment(picked.beginDate)).format('YYYY-MM-DD');  
      } 
      
      if(picked.endDate !=='')  {
        formattedEndDate = (moment(picked.endDate)).format('YYYY-MM-DD');  
      } 

      this.saving = true;
      this.isLoading = true;
      this.dialogRef.close(true);
      
      this.notifications = await this._notificationsService.getNotificationsPaged(picked.title, picked.body, 
        formattedBeginDate, formattedEndDate, picked.status, picked.communicationType, picked.notificationType);
      this.isLoading = false;
     
    }
  }


}
