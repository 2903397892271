export class ProfileDetails{
    mobileDeviceIdAndTimeZones: MobileDetails[];
}

export class MobileDetails{
    mobileDeviceId: string;
    timeZone: string;
}

export class UpdateAccount{
    profileId: string;
}


export class Profile{
    firstName: string;
    lastName: string;
    email: string;
    accountId: string;
}

