import { Blend } from './blend';
import {Routine} from '../routines/routine';
import { Feature } from '../features/feature';

export class MembershipLevel {
  id: string;
  name: string;
  description: number;
  availablePlaylistIds: string[];
  availableSignalBundleIds: string[];
  availableFeatureFlagsIds: string[];
  subscriptionItemId: string;
  legacyDate: string;
  availableBlends: Blend[];
  availableRoutines: Routine[];
  features: Feature[];

}
