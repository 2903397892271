import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Blend} from '../blend';
import {Routine} from '../../routines/routine';
import { MembershipLevel } from '../membership-levels';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MembershipLevelService } from '../membership-levels.service';
import { Feature } from '../../features/feature';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-membership-level-editor',
  templateUrl: './membership-level-editor.component.html',
  styleUrls: ['./membership-level-editor.component.scss']
})
export class MembershipLevelEditorComponent implements OnInit {

  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  blends: Blend[];
  routines: Routine[];
  features: Feature[];
  membershipLevel: MembershipLevel;
  saving: boolean = false;
  responseMessage: string;
  actionMessage: string;
  

  constructor(
    private _formBuilder: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<MembershipLevelEditorComponent>,
    private _membershiplevelService: MembershipLevelService,
    private closeDialogComponent: CloseDialogComponent,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, //{blends: this.blends, routines: this.routines, offer: null}
  ) {

    this.blends = data.blends;
    this.routines = data.routines;
    this.features = data.features;
    if (data.membershiplevel) {
      this.membershipLevel = {...data.membershiplevel};
      
    }

  }

  ngOnInit(): void {
    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      description: [''],
      availableFeatureFlagsIds: [[]],
      availableSignalBundleIds: [[]],
      availablePlaylistIds: [[]],

    });

    if (this.membershipLevel) {
      this.selectedProductForm.get('name').setValue(this.membershipLevel.name);
      this.selectedProductForm.get('description').setValue(this.membershipLevel.description);      
      this.selectedProductForm.get('availableSignalBundleIds').setValue(this.membershipLevel.availableSignalBundleIds);
      this.selectedProductForm.get('availablePlaylistIds').setValue(this.membershipLevel.availablePlaylistIds);
      this.selectedProductForm.get('availableFeatureFlagsIds').setValue(this.membershipLevel.availableFeatureFlagsIds);
  
    } else {
      this.membershipLevel = new MembershipLevel();
      this.membershipLevel.availableSignalBundleIds = [];
      this.membershipLevel.availablePlaylistIds = [];
      this.membershipLevel.availableFeatureFlagsIds = [];
    }
  }

  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 1500);
  }

  saveMembershipLevel() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const membershipLevel = this.selectedProductForm.getRawValue();

      this.saving = true;

      if (this.membershipLevel.id) {
        this._membershiplevelService.updateMembershipLevel(this.membershipLevel.id, membershipLevel).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'MembershipLevel Updated';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      } else {
        this._membershiplevelService.addMembershipLevel(membershipLevel).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'MembershipLevel Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }

    }
  }
}
