import {ChangeDetectorRef, Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {SignalsService} from '../signals.service';
import {PlayBackIntensities, Signal} from '../signal';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';
import { SharedDataService } from '../../routines/shared.data.service';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-signal-intensity-editor',
  templateUrl: './signal-intensity-editor.component.html',
  styleUrls: ['./signal-intensity-editor.component.scss']
})
export class SignalIntensityEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  responseMessage: string;
  signal: Signal;
  actionMessage: string;
  newSignal: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _signalsService: SignalsService,
    public dialogRef: MatDialogRef<SignalIntensityEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private closeDialogComponent: CloseDialogComponent,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.signal = data.signal;
   }

  ngOnInit(): void {
    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      hardwareId0: ['', [Validators.required]],
      intensity0: ['', [Validators.required]],
      file0: ['', [Validators.required]],
      hardwareId1: [''],
      intensity1: [''],
      file1: [''],
      hardwareId2: [''],
      intensity2: [''],
      file2: [''],
      fileData0: [''],
      fileData1: [''],
      fileData2: [''],
    });

    if(this.signal)
    {
      this.selectedProductForm.controls.name.setValue(this.signal.name); 

      let i = 0;
      for(let intensity of this.signal.playbackIntensities)
      {
        if (i == 0){
          this.selectedProductForm.controls.hardwareId0.setValue(intensity.hardwareId);
          this.selectedProductForm.controls.intensity0.setValue(intensity.intensity);
        }
        if (i == 1){
          this.selectedProductForm.controls.hardwareId1.setValue(intensity.hardwareId);
          this.selectedProductForm.controls.intensity1.setValue(intensity.intensity);
        }
        if (i == 2){
          this.selectedProductForm.controls.hardwareId2.setValue(intensity.hardwareId);
          this.selectedProductForm.controls.intensity2.setValue(intensity.intensity);
        }
        i = i+ 1;
      }


    }
    else {
        this.newSignal = true;
        this.signal = new Signal();
        this.signal.playbackIntensities = [];
    }

  }


  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success'){
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 2000);
  }

  uploadFile0(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.selectedProductForm.patchValue({
      fileData0: file,
    });
    this.selectedProductForm.get('fileData0').updateValueAndValidity();
  }

  uploadFile1(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.selectedProductForm.patchValue({
      fileData1: file,
    });
    this.selectedProductForm.get('fileData1').updateValueAndValidity();
  }

  uploadFile2(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.selectedProductForm.patchValue({
      fileData2: file,
    });
    this.selectedProductForm.get('fileData2').updateValueAndValidity();
  }

  updateSignalIntensity() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {     
      this.saving = true;      
      if(this.newSignal === true)
      {
        const signal = this.selectedProductForm.getRawValue();
        console.log('signalformdata', signal)
        let formData: any = new FormData();
        formData.append('name', signal.name);
        if (signal.hardwareId0 !== '') formData.append('createSignalModels[0].HardwareId', signal.hardwareId0);
        if (signal.intensity0 !== '') formData.append('createSignalModels[0].Intensity', signal.intensity0);
        if (signal.fileData0 !== '') formData.append('createSignalModels[0].File', signal.fileData0);

        if (signal.hardwareId1 !== '') formData.append('createSignalModels[1].HardwareId', signal.hardwareId1);
        if (signal.intensity1 !== '') formData.append('createSignalModels[1].Intensity', signal.intensity1);
        if (signal.fileData1 !== '') formData.append('createSignalModels[1].File', signal.fileData1);

        if (signal.hardwareId2 !== '') formData.append('createSignalModels[2].HardwareId', signal.hardwareId2);
        if (signal.intensity2 !== '') formData.append('createSignalModels[2].Intensity', signal.intensity2);
        if (signal.fileData2 !== '') formData.append('createSignalModels[2].File', signal.fileData2);
        console.log('formdata', formData)
        this._signalsService.addSignalIntensity(formData).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Signal Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      else
      {
        const signal = this.selectedProductForm.getRawValue();
        console.log('signalformdata', signal)
        let formData: any = new FormData();
        formData.append('name', signal.name);
        if (signal.hardwareId0 !== '') formData.append('updateSignalModels[0].HardwareId', signal.hardwareId0);
        if (signal.intensity0 !== '') formData.append('updateSignalModels[0].Intensity', signal.intensity0);
        if (signal.fileData0 !== '') formData.append('updateSignalModels[0].File', signal.fileData0);

        if (signal.hardwareId1 !== '') formData.append('updateSignalModels[1].HardwareId', signal.hardwareId1);
        if (signal.intensity1 !== '') formData.append('updateSignalModels[1].Intensity', signal.intensity1);
        if (signal.fileData1 !== '') formData.append('updateSignalModels[1].File', signal.fileData1);

        if (signal.hardwareId2 !== '') formData.append('updateSignalModels[2].HardwareId', signal.hardwareId2);
        if (signal.intensity2 !== '') formData.append('updateSignalModels[2].Intensity', signal.intensity2);
        if (signal.fileData2 !== '') formData.append('updateSignalModels[2].File', signal.fileData2);
        console.log('formdata', formData)
        this._signalsService.updateSignalIntensity(this.signal.id, formData).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Signal Updated';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      
    }
  }

  

}
