<div fxFlexAlign="stretch" class="container">
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <mat-card>
        <mat-card-header>
          <mat-card-title  class="headerTitle">Device Account Pair Information</mat-card-title>
        </mat-card-header>
  
        <mat-card-content>
  
          <mat-accordion>
               
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  View Details
                </mat-panel-title>
              </mat-expansion-panel-header>
              
                <div     
                    class="inventory-grid z-10  top-0 grid gap-1 py-1 px-9 md:px-9 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                    *ngIf="deviceAccountDetails.length > 0">
                      <div class="hidden md:block" fxFlex="80">Email</div>                                   
                      <div class="hidden md:block" fxFlex="20">Created</div>
                      
                </div>
                <div class="grid" *ngIf="deviceAccountDetails.length > 0 ;else no_Details">                 
                  <div class="inventory-grid grid items-left gap-1 py-2 px-8 md:px-10 border-b"  *ngFor="let detail of deviceAccountDetails;">
                    <div>{{ detail.email }}</div>
                    <div>{{ detail.created }} </div> 
                    
                  </div>   
                </div>            
                <ng-template #no_Details>
                  <mat-chip-set >
                    <mat-chip>
                      No Details found.
                    </mat-chip>             
                  </mat-chip-set>
      
                </ng-template>   
               
            </mat-expansion-panel>
    
          </mat-accordion>
  
        </mat-card-content>
  
       
  
      
    </mat-card>
  </div>
  
  <div mat-dialog-actions>
    <div class="flex items-center justify-between w-full border-t px-8 py-4">                     
      <button class="-ml-4" mat-button  [color]="'warn'" mat-dialog-close mat-dialog-close="true" (click)="closeDialog()">Close</button>
     
    </div>
  </div>
  
    
  