import {ChangeDetectorRef, Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiKeysService } from '../apikeys.service';
import { ApiKey } from '../apikeys';


@Component({
  selector: 'app-apikey-editor',
  templateUrl: './apikey-editor.component.html',
  styleUrls: ['./apikey-editor.component.scss']
})
export class ApiKeyEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  imagesForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  apiKey: ApiKey;
  responseMessage: string;
  actionMessage: string;
  messageSuccess: Boolean = true;
  apiPermission: string[] = ['create:activitystream', 'create:logs', 'create:notification', 'delete:notification', 'read:activitystream', 
    'read:device', 'read:logs', 'read:notification', 'read:profile', 'update:notification'];

  constructor(
    private _formBuilder: FormBuilder,
    private _apiKeysService: ApiKeysService,
    public dialogRef: MatDialogRef<ApiKeyEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
        this.apiKey = data.key;
  }

  async ngOnInit(): Promise<void> {
    this.selectedProductForm = this._formBuilder.group({
        description: ['', [Validators.required]],
        permissions: ['', [Validators.required]],      
    });

    if (this.apiKey) {
        this.selectedProductForm.get('permissions').setValue(this.apiKey.permissions);
        this.selectedProductForm.get('description').setValue(this.apiKey.description);        
      } 
  }

  closeDialog(value = false) {
    this.dialogRef.close(value);
  }

  showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialog();
      }
      this.flashMessage = null;
      // Mark for check
      this._changeDetectorRef.markForCheck();

    }, 2000);
  }


  saveKey() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
      const picked = this.selectedProductForm.getRawValue();
      this.saving = true;
      if (this.apiKey == null) {
        this._apiKeysService.createKey(picked).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Key Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      else{
        this._apiKeysService.updateKey(this.apiKey.id, picked).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Key Updated';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }

      
    }
  }


//   async addKey() {
//     if (this.selectedProductForm.invalid) {
//         return;
//       } else {
//         const picked = this.selectedProductForm.getRawValue();       
//         this.saving = true;
  
//         this._apiKeysService.createKey(picked).subscribe({
//           next: (e: any) => {
//             if (e.data.id) {
//               this.actionMessage = 'Key Added';
//               this.showFlashMessage('success');
//             } else {
//               this.showFlashMessage('error');
//             }
//           }, error: (err => {
//             this.responseMessage = err.error.message;
//             this.showFlashMessage('error');
//           })
//         });
//       }
//     }



}