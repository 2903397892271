import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Routine } from '../../routines/routine';
import { Blend } from '../../membership-levels/blend';
import { Feature } from '../../features/feature';
import { Scenario } from '../scenario';

@Component({
  selector: 'view-scenariocontent',
  templateUrl: './view-scenariocontent.component.html',
  styleUrls: ['./view-scenariocontent.component.scss']
})
export class ViewScenarioContentComponent implements OnInit{
  scenario: Scenario;
  blends: Blend[];
  routines: Routine[];
  features: Feature[];
  feature
  constructor(
    public dialogRef: MatDialogRef<ViewScenarioContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.scenario = data;
  }
    

  async ngOnInit(): Promise<void> {
    this.blends = this.scenario.availableBlends;
    this.routines = this.scenario.availableRoutines;
  
  }


}
