import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import { RechargeCustomer } from './recharge-customer';


@Injectable({
  providedIn: 'root'
})
export class RechargeCustomerService {
  constructor(
    private _httpClient: HttpClient
  ) {
  }


  getAllRechargeCustomers(): Promise<RechargeCustomer[]> {
    return firstValueFrom(this._httpClient
      .get<RechargeCustomer[]>(
        `${environment.opsapi}/v4/rechargecustomer`
      )
      .pipe(
       )
      );
  }


  addRechargeCustomer(data: any): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.post(
      `${environment.opsapi}/v4/rechargecustomer`,
      data,
      { headers: headers }
    );
  }

}
