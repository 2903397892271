import {
    DefaultTranspiler,
    TRANSLOCO_CONFIG,
    TRANSLOCO_FALLBACK_STRATEGY,
    TRANSLOCO_INTERCEPTOR,
    TRANSLOCO_LOADER,
    TRANSLOCO_MISSING_HANDLER,
    TRANSLOCO_TRANSPILER,
    translocoConfig,
    TranslocoModule,
    TranslocoService,
} from '@ngneat/transloco';

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from 'environments/environment';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { TranslocoCoreMissingHandler } from './transloco.missing';
import { TranslocoCoreInterceptor } from './transloco.interceptor';
import { TranslocoCoreFallbackStrategy } from './transloco.fallback';

export function appInitializerFactory(translocoService: TranslocoService): () => Promise<void> {
    return () => new Promise((resolve) => {
        translocoService.setActiveLang('en');

        translocoService.load('en').subscribe(() => {
            console.log('English translation loaded during app initialization');
            resolve();
        });
    });
};

@NgModule({
    imports: [
        TranslocoModule,
    ],
    exports: [
        TranslocoModule
    ],
    providers: [
        TranslocoService,
        {
            // Provide the default Transloco configuration
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: ['en'],
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: environment.production,
            }),
        },
        {
            // Provide the default Transloco loader
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader,
        },
        {
            provide: TRANSLOCO_TRANSPILER,
            useClass: DefaultTranspiler,
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            deps: [TranslocoService],
            useFactory: appInitializerFactory,
            multi: true
        },
        {
            provide: TRANSLOCO_MISSING_HANDLER,
            useClass: TranslocoCoreMissingHandler,
        },
        {
            provide: TRANSLOCO_INTERCEPTOR,
            useClass: TranslocoCoreInterceptor,
        },
        {
            provide: TRANSLOCO_FALLBACK_STRATEGY,
            useClass: TranslocoCoreFallbackStrategy,
        }
    ],
})
export class TranslocoCoreModule {}
