import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CategoriesService} from '../categories.service';
import { Category } from '../category';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';
import {Observable} from 'rxjs';


@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-category-editor',
  templateUrl: './category-editor.component.html',
  styleUrls: ['./category-editor.component.scss'],
})
export class CategoryEditorComponent implements OnInit {
  color: string;
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  category: Category;
  responseMessage: string;
  actionMessage: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _categoriesService: CategoriesService,
    public dialogRef: MatDialogRef<CategoryEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private closeDialogComponent: CloseDialogComponent,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
      this.category = data.category;
      
  }

  async ngOnInit(): Promise<void> {   
    this.selectedProductForm = this._formBuilder.group({
      name: [''],
      title: ['', [Validators.required, Validators.minLength(4)]],
      sortOrder: ['', [Validators.required]],
      imageUrl: [''],
      iconUrl: [''],
      parentId: [''],
      color: ['']
        
    });

    if (this.category) {
      this.color = this.category.color;
      this.selectedProductForm.get('name').setValue(this.category.name);
      this.selectedProductForm.get('title').setValue(this.category.title);
      this.selectedProductForm.get('sortOrder').setValue(this.category.sortOrder);
      this.selectedProductForm.get('imageUrl').setValue(this.category.imageUrl);
      this.selectedProductForm.get('parentId').setValue(this.category.parentId);
      this.selectedProductForm.get('iconUrl').setValue(this.category.iconUrl);      
    } 
  }
 
  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 1500);
  }


  saveCategory() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
      const picked = this.selectedProductForm.getRawValue();
      let newCategory = new Category();
      newCategory.name = picked.name;
      newCategory.title = picked.title;
      newCategory.sortOrder = picked.sortOrder;
      newCategory.imageUrl = picked.imageUrl;
      newCategory.iconUrl = picked.iconUrl;
      newCategory.parentId = picked.parentId;
      newCategory.color = this.color;
      this.saving = true;
      if (this.category == null) {
        this._categoriesService.addCategory(newCategory).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Category Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      else{
        this._categoriesService.updateCategory(this.category.id, newCategory).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Category Updated';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }

      
    }
  }


}
