import {Component, OnInit, ChangeDetectorRef, Inject} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {User} from 'app/core/user/user.types';
import {InventoryService} from '../inventory/inventory.service';
import {InventoryProduct} from '../inventory/inventory.types';
import { MembershipLevel } from '../../membership-levels/membership-levels';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-create-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  membershiplevel: MembershipLevel[];
  saving: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UpdateUserComponent>,
    private _inventoryService: InventoryService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: InventoryProduct
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.selectedProductForm = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      membershipIds: ['', [Validators.required]],
    });

    this.membershiplevel = await firstValueFrom(this._inventoryService.getMembershipLevels());

    if(this.data){   
      this.selectedProductForm.get('firstName').setValue(this.data.firstName);
      this.selectedProductForm.get('lastName').setValue(this.data.lastName);
      this.selectedProductForm.controls.membershipIds.setValue(this.data.membershipLevels.map(o => o.id));
      this.selectedProductForm.controls['membershipIds'].disable();
    }
       
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  createUser() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const product = this.selectedProductForm.getRawValue();

      this.saving = true;

      this._inventoryService.updateUser(this.data.id, product).subscribe((e) => {
        if (e.id) {
          this.showFlashMessage('success');
        } else {
          this.showFlashMessage('error');
        }
      });
    }
  }

   showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    setTimeout(async () => {
      this.flashMessage = null;
      this.closeDialog();
      // Mark for check
      this._changeDetectorRef.markForCheck();

    }, 2000);
  }
}
