import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthServiceClass } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private _AuthServiceClass: AuthServiceClass) {}

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (
            // this._AuthServiceClass.accessToken &&
            // !AuthUtils.isTokenExpired(this._AuthServiceClass.accessToken)
            true
        ) {
            newReq = req.clone({
                // headers: newreq.headers.set(
                //     'Authorization',
                //     'Bearer ' + this._AuthServiceClass.accessToken
                // ),
                headers: new HttpHeaders({
                    //'Content-Type':  'application/json',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Access-Control-Allow-Methods': 'GET',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + this._AuthServiceClass.accessToken
                  })
            });

            // newReq = newReq.clone({
            //     headers: req.headers.set(
            //         'Access-Control-Allow-Origin',
            //         '*'
            //     ),
            // });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
                // Catch "401 Unauthorized" responses
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 401
                ) {
                    // Sign out
                    this._AuthServiceClass.signOut();
                    // Reload the app
                    location.reload();
                }

                return throwError(error);
            })
        );
    }
}
