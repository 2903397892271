import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Tag} from './tags';
import {firstValueFrom, map} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(
    private _httpClient: HttpClient
  ) {
  }

  async getTags(): Promise<Tag[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/Tags`)
        .pipe(
          map((entry) => entry["data"])
        )
    );
  }

  updateTag(id: string, tag: Tag) {
    return this._httpClient.put(`${environment.opsapi}/v4/Tags/${id}`, tag);
  }

  addTag(tag: Tag) {
    return this._httpClient.post(`${environment.opsapi}/v4/Tags`, tag);
  }

  removeTag(tag: Tag) {
    return this._httpClient.delete(`${environment.opsapi}/v4/Tags/${tag.id}`);
  }
}
