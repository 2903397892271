import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {DeviceService} from '../../devices/devices.service';
import {Device, DeviceAccountPair} from './../devices';
import {InventoryProduct,} from "app/modules/admin/apps/accounts/inventory/inventory.types";
import {InventoryService} from "app/modules/admin/apps/accounts/inventory/inventory.service";
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-device-account-details',
  templateUrl: './device-account-details.component.html',
  styleUrls: ['./device-account-details.component.scss']
})
export class DeviceAccountDetailsComponent implements OnInit{
  isLoading: boolean = false;
  device: Device;
  deviceAccountDetails: DeviceAccountPair[] = [];
  account: InventoryProduct;

  constructor(
    public dialogRef: MatDialogRef<DeviceAccountDetailsComponent>,
    private _deviceService: DeviceService,
    private _inventoryService: InventoryService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.device = data;
  }
    

  async ngOnInit(){
    this.isLoading = true; 

    try{
        if (this.device.code !== null){
          let result = await firstValueFrom(this._deviceService.getDeviceAccountInformation(this.device.code));
          if (result.message === null || result.message === undefined){
            this.deviceAccountDetails = result;
          }
        }    
    }
    catch(e:unknown){ }

    for (let detail of this.deviceAccountDetails) {
        try{
            let accountResult = await firstValueFrom(this._inventoryService.getAccountbyId(detail.accountId));
            if (accountResult.message === null || accountResult.message === undefined){
                detail.email = accountResult.data.email;
                detail.created = detail.created.split('T')[0];
            }
        }
        catch(e:unknown){ }
    }
    this.deviceAccountDetails = this.deviceAccountDetails.filter(t => !!t.email);
    this.isLoading = false;
  }

  
  closeDialog(value = false) {
    this.dialogRef.close(value);
  }


  
}
