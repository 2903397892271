import { Injectable } from '@angular/core';
import {firstValueFrom, map, of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Routine} from './routine';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RoutinesService {

  constructor(
    private _httpClient: HttpClient

  ) { }

  async getRoutines(): Promise<Routine[]> {
    await new Promise(f => setTimeout(f, 1000));
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/Playlists`)
        .pipe(
          map((entry) => entry["data"])
        )
    );
  }

  saveRoutine(id: string, routine: Routine) {
    return this._httpClient.put(`${environment.opsapi}/v4/Playlists/${id}`, routine);
  }

  newRoutine(routine: any) {
    return this._httpClient.post(`${environment.opsapi}/v4/Playlists/`, routine);
  }

  deleteRoutine(routine: Routine) {
    return firstValueFrom(this._httpClient.delete(`${environment.opsapi}/v4/Playlists/${routine.id}`));
  }

  async delay(){
    await new Promise(f => setTimeout(f, 2000));
  }

  uploadRoutineImages(id, images: any) {
    let formData: any = new FormData();
    if (images.herobannerFileData) {
      formData.append('herobannerFile', images.herobannerFileData);
    }
    if (images.imageFileData) {
      formData.append('imageFile', images.imageFileData);
    }
    if (images.iconFileData) {
      formData.append('iconFile', images.iconFileData);
    }

    if (images.iconWithNoBGFileData) {
      formData.append('iconWithNoBGFile', images.iconWithNoBGFileData);
    }
    if (images.largeRectImageFileData) {
      formData.append('largeRectImageFile', images.largeRectImageFileData);
    }
    if (images.wideRectImageFileData) {
      formData.append('wideRectImageFile', images.wideRectImageFileData);
    }

    if (images.herobannerFileData || images.imageFileData || images.iconFileData || images.iconWithNoBGFileData
      || images.largeRectImageFileData || images.wideRectImageFileData
    ) {
      return this._httpClient.put(`${environment.opsapi}/v4/Playlists/${id}/upload`, formData);
    } else {
      return of(true);
    }
  }
}
