import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import { Blend } from 'app/modules/admin/apps/offers/blend';

@Component({
  selector: 'app-view-images',
  templateUrl: './view-images.component.html',
  styleUrls: ['./view-images.component.scss']
})


export class ViewImagesComponent implements OnInit {
  isLoading: boolean = false;
  imageObject = [];
  name: any;
  constructor(
    public dialogRef: MatDialogRef<ViewImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.name = data.item.name;
    this.imageObject = [{
      image: data.item.heroBannerUrl,
      thumbImage: data.item.heroBannerUrl,
      title: 'Hero Banner',
    }, {
      image: data.item.imageUrl,
      thumbImage: data.item.imageUrl,
      title: 'Image'
    },{
      image: data.item.iconUrl,
      thumbImage: data.item.iconUrl,
      title: 'Icon'
    }];
      
      //this.imageObject = [this.blend.heroBannerUrl, this.blend.imageUrl, this.blend.iconUrl]
  }

  ngOnInit(): void {
    
  }

  
  closeDialog(value = false) {
    this.dialogRef.close(value);
  }

}


