export class Membership {
    id: string;
    name: string;
    type: string;
    value: number;

}

export enum Type {
  Free = 'Free',
  VIP = 'VIP',
  AllAccess = 'AllAccess',
  PartialAccess = 'PartialAccess',
}

