export class AppVersion {
    id: string;
    minimumVersion: string;
    latestVersion: string;
    platform: string;
}


export class mobileVersion{
    platform: string;
    latestVersion: VersionInfo;
    minimumVersion: VersionInfo;
}

export class VersionInfo{
    major: number;
    minor: number;
    patch: number;
}
