import { Component, OnInit } from '@angular/core';
import {ApiKey} from './apikeys';
import {ApiKeysService} from './apikeys.service';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';
import { ApiKeyEditorComponent } from './apikey-editor/apikey-editor.component';

@Component({
  selector: 'app-apikeys',
  templateUrl: './apikeys.component.html',
  styleUrls: ['./apikeys.component.scss']
})
export class ApiKeyComponent implements OnInit {
  isLoading: boolean = false;
  apiKeys: ApiKey[] = [];
  fullApiKeys: ApiKey[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  query = '';

  constructor(
    private _apiKeysService: ApiKeysService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    public dialog: MatDialog,
    private _fuseConfirmationService: FuseConfirmationService,

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - ApiKeys');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:apikey')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:apikey');
    this.canEdit = perms.includes('update:apikey');
    this.canDelete = perms.includes('delete:apikey');

    
    await this.getApiKeys();
    this.isLoading = false;

    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        switchMap(async (query) => {
          this.isLoading = true;
          this.query = query.trim();
          if(this.query){
            this.apiKeys = this.apiKeys.filter(t => t.description.toLowerCase().includes(query.toLowerCase()));
          }
          else{
            this.apiKeys = this.fullApiKeys;
          }
    
        }),
        map(() => {
          this.isLoading = false;
        })
      )
      .subscribe();

  }

  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.apiKeys = this.fullApiKeys;
    this.isLoading = false;
  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.apiKeys;
  }

  async getApiKeys() {
    this.isLoading = true;
    this.apiKeys = await this._apiKeysService.getKeys();
    this.isLoading = false;
    this.fullApiKeys = this.apiKeys;
  }

  addApiKey() {
    let dialogref = this.dialog.open(ApiKeyEditorComponent, 
      {
        width: '400px', 
        disableClose: true,
        data: {key: null}
      });
      dialogref.afterClosed().subscribe(async (res) => {
        this.isLoading = true;
        await this.getApiKeys();
        this.isLoading = false; 
    });
  }

  editKey(key) {
    let dialogref = this.dialog.open(ApiKeyEditorComponent, 
    {
      width: '400px',
      disableClose: true,
      data: {key: key}
    });
    dialogref.afterClosed().subscribe(async (res) => {
        this.isLoading = true;
        await this.getApiKeys();
        this.isLoading = false;
    });
  }

  removeKey(key) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Key?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }
      (await this._apiKeysService.removeKey(key.id)).subscribe({
        next: async (e: any) => {
          if (e.message === null || e.message === undefined) {
            this.isLoading = true;
            await this.getApiKeys();
            this.isLoading = false;
            this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage:'Key : ' + key.id + ' is deleted successfully'}
            });
          } 
        }, error: (err => {
           this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage: err.error.message}
            });
        })
      });
  });

  }
}
