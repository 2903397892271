import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Signal} from './signal';

@Injectable({
  providedIn: 'root'
})
export class SignalsService {

  constructor(
    private _httpClient: HttpClient
  ) {
  }

  async getSignals(): Promise<Signal[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/Signals`)
        .pipe(
          map((entry) => entry["data"])
        )
    );
  }

  addSignal(signal: FormData) {
    return this._httpClient.post(`${environment.opsapi}/v4/Signals`, signal);
  }

  updateSignal(id: string, signal: FormData) {
    return this._httpClient.put(`${environment.opsapi}/v4/Signals/${id}`, signal);
  }
}
