import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit{
  isLoading: boolean = false;
  customer: any;
  hasProfile: boolean;
  hasAuth: boolean;
  accountCreationDate: string;
 

  constructor(
    public dialogRef: MatDialogRef<CustomerDetailsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.customer = data;
  }
    

  async ngOnInit(){
    this.isLoading = true;    
    this.isLoading = false;
  }

  
  closeDialog(value = false) {
    this.dialogRef.close(value);
  }


  
}
