import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Experience, ProgramCodes } from '../experience';


@Component({
  selector: 'view-codes',
  templateUrl: './view-codes.component.html',
  styleUrls: ['./view-codes.component.scss']
})
export class ViewProgramCodesComponent implements OnInit{
  experience: Experience
  programCodes: ProgramCodes[]
  feature
  constructor(
    public dialogRef: MatDialogRef<ViewProgramCodesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.experience = data;
    this.programCodes = data.programCodes;
  }
    

  async ngOnInit(): Promise<void> {
    
  }


}
