import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AppVersionService} from '../appversion.service';
import {AppVersion, mobileVersion, VersionInfo} from './../appversion';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';


@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-appversion-editor',
  templateUrl: './appversion-editor.component.html',
  styleUrls: ['./appversion-editor.component.scss']
})
export class AppVersionEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  appVersion: AppVersion;
  responseMessage: string;
  actionMessage: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _appVersionService: AppVersionService,
    public dialogRef: MatDialogRef<AppVersionEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private closeDialogComponent: CloseDialogComponent,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
      this.appVersion = data.version;
  }


  async ngOnInit(): Promise<void> {   
    this.selectedProductForm = this._formBuilder.group({
        platform: [{ value: '', disabled: true }, [Validators.required]],    
        minimumVersionMajor: ['', [Validators.required]],
        minimumVersionMinor: ['', [Validators.required]],
        minimumVersionPatch: ['', [Validators.required]],
        latestVersionMajor: ['', [Validators.required]],
        latestVersionMinor: ['', [Validators.required]],
        latestVersionPatch: ['', [Validators.required]],

    });

    if (this.appVersion) {
      this.selectedProductForm.get('platform').setValue(this.appVersion.platform);
      this.selectedProductForm.get('minimumVersionMajor').setValue(this.appVersion.minimumVersion.split(".")[0]);
      this.selectedProductForm.get('minimumVersionMinor').setValue(this.appVersion.minimumVersion.split(".")[1]);
      this.selectedProductForm.get('minimumVersionPatch').setValue(this.appVersion.minimumVersion.split(".")[2]);
      this.selectedProductForm.get('latestVersionMajor').setValue(this.appVersion.latestVersion.split(".")[0]);
      this.selectedProductForm.get('latestVersionMinor').setValue(this.appVersion.latestVersion.split(".")[1]);
      this.selectedProductForm.get('latestVersionPatch').setValue(this.appVersion.latestVersion.split(".")[2]);
           
    } 
  }
  

  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 1500);
  }


  saveVersion() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
        const picked = this.selectedProductForm.getRawValue();
      
        let newversion = new mobileVersion();
        newversion.platform = picked.platform;
        let minimumVersionItem = new VersionInfo();
        minimumVersionItem.major = picked.minimumVersionMajor;
        minimumVersionItem.minor = picked.minimumVersionMinor;
        minimumVersionItem.patch = picked.minimumVersionPatch;

        newversion.minimumVersion = minimumVersionItem;

        let latestVersionItem = new VersionInfo();
        latestVersionItem.major = picked.latestVersionMajor;
        latestVersionItem.minor = picked.latestVersionMinor;
        latestVersionItem.patch = picked.latestVersionPatch;

        newversion.latestVersion = latestVersionItem;
        this.saving = true;

        

        this._appVersionService.updateMobileVersion(this.appVersion.id, newversion).subscribe({
        next: (e: any) => {
        if (e.data.id) {
            this.actionMessage = 'Version Updated';
            this.showFlashMessage('success');
        } else {
            this.showFlashMessage('error');
        }
        }, error: (err => {
        this.responseMessage = err.error.message;
        this.showFlashMessage('error');
        })
        });
    }
  }


}
