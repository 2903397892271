import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Routine } from '../../routines/routine';
import { Blend } from '../blend';
import { Feature } from '../../features/feature';
import { MembershipLevel } from '../membership-levels';

@Component({
  selector: 'view-content',
  templateUrl: './view-content.component.html',
  styleUrls: ['./view-content.component.scss']
})
export class ViewMembershipContentComponent implements OnInit{
  membershiplevel: MembershipLevel;
  blends: Blend[];
  routines: Routine[];
  features: Feature[];
  feature
  constructor(
    public dialogRef: MatDialogRef<ViewMembershipContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.membershiplevel = data;
  }
    

  async ngOnInit(): Promise<void> {
    this.blends = this.membershiplevel.availableBlends;
    this.routines = this.membershiplevel.availableRoutines;
    this.features = this.membershiplevel.features

  }


}
