import { Component, OnInit } from '@angular/core';
import {RechargeCustomer} from './recharge-customer';
import {RechargeCustomerService} from './recharge-customer.service';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";
import {ChangeDetectorRef} from "@angular/core";
import { FormGroup, FormBuilder} from '@angular/forms';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { RechargeCustomerEditorComponent } from './recharge-customer-editor/recharge-customer-editor.component';

@Component({
  selector: 'app-recharge-customer',
  templateUrl: './recharge-customer.component.html',
  styleUrls: ['./recharge-customer.component.scss']
})
export class RechargeCustomerComponent implements OnInit {
  isLoading: boolean = false;
  rechargeCustomers: RechargeCustomer[] = [];
  fullrechargeCustomers: RechargeCustomer[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  userForm: FormGroup;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  query = '';


  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _rechargeCustomerService: RechargeCustomerService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit() : Promise<void>{
 
    this._titleService.setTitle('Hapbee OpsPortal - Recharge Customers');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:recharge-customer')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:recharge-customer');
    this.canEdit = perms.includes('update:recharge-customer');
    this.canDelete = perms.includes('delete:recharge-customer');
  
    await this.getRechargeCustomers();
    this.isLoading = false;

    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        switchMap(async (query) => {
          this.isLoading = true;
          this.query = query.trim();
          if(this.query){
            this.rechargeCustomers = this.rechargeCustomers.filter(t => t.customerData.customer.first_name.toLowerCase().includes(query.toLowerCase()) || t.customerData.customer.last_name.toLowerCase().includes(query.toLowerCase()) || t.customerData.customer.email.toLowerCase().includes(query.toLowerCase()) || t.customerData.customer.shopify_customer_id.includes(query));
          }
          else{
            this.rechargeCustomers = this.fullrechargeCustomers;
          }
    
        }),
        map(() => {
          this.isLoading = false;
        })
      )
      .subscribe();

  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.rechargeCustomers;
  }


  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.rechargeCustomers = this.fullrechargeCustomers;
    this.isLoading = false;
  }

  addRechargeCustomer() {
    let dialogref = this.dialog.open(RechargeCustomerEditorComponent, 
    {
      width: '700px',
      disableClose: true,
      data: {promotion: null}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      this.isLoading = true;
      if (res) {
        await this.getRechargeCustomers();
      }
      this.isLoading = false;
    });
  }

  async getRechargeCustomers() {
    this.isLoading = true;  
    this.rechargeCustomers = await this._rechargeCustomerService.getAllRechargeCustomers();
    for (let details of this.rechargeCustomers) {
        try{details.customerData.customer.first_charge_processed_at = details.customerData.customer.first_charge_processed_at.split("T")[0]; }
        catch(e:unknown){}
    }
   
    this.fullrechargeCustomers = this.rechargeCustomers;
    this.isLoading = false;
  }

  viewDetails(customer: RechargeCustomer) {
    let dialogref = this.dialog.open(CustomerDetailsComponent, {
      disableClose: true,
      width: '550px',
      height: 'auto',
      data: customer
    });
    
  }

}
