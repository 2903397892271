import { Component, OnInit } from '@angular/core';
import { Experience } from './experience';
import { ExperiencesService } from './experience.service';
import { MembershipLevel } from '../membership-levels/membership-levels';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import { MembershipLevelService } from '../membership-levels/membership-levels.service';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';
import { ExperienceEditorComponent } from './experience-editor/experience-editor.component';

@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.scss']
})
export class ExperiencesComponent implements OnInit {
  isLoading: boolean = false;
  experiences: Experience[] = [];
  membershipLevel: MembershipLevel[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  constructor(
    private _experiencesService: ExperiencesService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    private _membershipLevelService: MembershipLevelService,
    private _fuseConfirmationService: FuseConfirmationService,

    public dialog: MatDialog

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Experiences');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:experience')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:experience');
    this.canEdit = perms.includes('update:experience');
    this.canDelete = perms.includes('delete:experience');

    this.membershipLevel = await this._membershipLevelService.getMembershipLevels(1, 1000);

    await this.getExperiences();
    this.isLoading = false;

  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.experiences;
  }


  async getExperiences() {
    this.isLoading = true;
    this.experiences = await this._experiencesService.getExperiences();
    for (let experience of this.experiences) {
      try{experience.membershipLevel = this.membershipLevel.filter(o => o.id === experience.membershipLevelID)[0];}
      catch(e:unknown){
      }
      
      if(experience.expirationDate !== null && experience.expirationDate !== undefined && experience.expirationDate !==""){
        experience.expirationDate = experience.expirationDate.split("T")[0];
      }
    }


    
    this.experiences.sort(function(a, b) {
      return (a.expirationDate < b.expirationDate) ? -1 : ((a.expirationDate > b.expirationDate) ? 1 : 0);
    });

    this.isLoading = false;
  }

  addExperience() {
    let dialogref = this.dialog.open(ExperienceEditorComponent, 
    {
      width: '700px',
      disableClose: true,
      data: {experience: null, membershiplevels: this.membershipLevel}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      this.isLoading = true;
      await this.getExperiences();
      this.isLoading = false;
    });
  }

  editExperience(experience) {
    let dialogref = this.dialog.open(ExperienceEditorComponent, 
    {
      width: '700px',
      disableClose: true,
      data: {experience: experience, membershiplevels: this.membershipLevel}
    });
    dialogref.afterClosed().subscribe(async (res) => {
        this.isLoading = true;
        await this.getExperiences();
        this.isLoading = false;
    });
  }

  removeExperience(experience) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Experience?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }    
      this._experiencesService.removeExperience(experience.code).then( async () => {    
        await this.getExperiences();
        this.isLoading = false;
        this.dialog.open(SharedAlertComponent,
        {
          width: 'auto',
          disableClose: false,
          data: {dialogMessage:'Experience : ' + experience.code + ' is deleted successfully'}
        });
    });

  });
}
}
