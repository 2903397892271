import { Component, OnInit } from '@angular/core';
import {Category} from './category';
import {CategoriesService} from './categories.service';
import {MatDialog} from '@angular/material/dialog';
import {CategoryEditorComponent} from './category-editor/category-editor.component';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  isLoading: boolean = false;
  categories: Category[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;

  constructor(
    private _categoriesService: CategoriesService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    private _fuseConfirmationService: FuseConfirmationService,

    public dialog: MatDialog

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Categories');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:category')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:category');
    this.canEdit = perms.includes('update:category');
    this.canDelete = perms.includes('delete:category');

    
    await this.getCategories();
    this.isLoading = false;

  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.categories;
  }

  async getCategories() {
    this.isLoading = true;
    this.categories = await this._categoriesService.getCategories();
    
    this.categories.sort(function(a, b) {
      return (a.sortOrder < b.sortOrder) ? -1 : ((a.sortOrder > b.sortOrder) ? 1 : 0);
    });


    this.isLoading = false;
  }

  addCategory() {
    let dialogref = this.dialog.open(CategoryEditorComponent, 
    {
      disableClose: true,
      data: {category: null}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.getCategories();
        this.isLoading = false;
      }
     
    });
  }

  editCategory(category) {
    let dialogref = this.dialog.open(CategoryEditorComponent, 
    {
      disableClose: true,
      data: {category: category}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.getCategories();
        this.isLoading = false;
      }
    });
  }

  removeCategory(category) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Category?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }
      (await this._categoriesService.removeCategory(category.id)).subscribe({
        next: async (e: any) => {
          if (e.message === null || e.message === undefined) {
            this.isLoading = true;
            await this.getCategories();
            this.isLoading = false;
            this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage:'Category : ' + category.title + ' is deleted successfully'}
            });
          } 
        }, error: (err => {
           this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage: err.error.message}
            });
        })
      });
  });
}
}
