import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, RouterModule } from "@angular/router";
import { MarkdownModule } from "ngx-markdown";
import { FuseModule } from "@fuse";
import { FuseConfigModule } from "@fuse/services/config";
import { FuseMockApiModule } from "@fuse/lib/mock-api";
import { CoreModule } from "app/core/core.module";
import { appConfig } from "app/core/config/app.config";
import { mockApiServices } from "app/mock-api";
import { LayoutModule } from "app/layout/layout.module";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: "enabled",
};

import { TranslocoCoreModule } from "./core/transloco/transloco.module";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import { AuthModule } from "@auth0/auth0-angular";
import {environment} from '../environments/environment';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatChipsModule} from '@angular/material/chips';
import {BlendsComponent} from './modules/admin/apps/blends/blends.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { TagsComponent } from './modules/admin/apps/tags/tags.component';
import {MatIconModule} from '@angular/material/icon';
import { AddTagComponent } from './modules/admin/apps/tags/add-tag/add-tag.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { EditTagComponent } from './modules/admin/apps/tags/edit-tag/edit-tag.component';
import { SignalsComponent } from './modules/admin/apps/signals/signals.component';
import { AddSignalComponent } from './modules/admin/apps/signals/add-signal/add-signal.component';
import { EditSignalComponent } from './modules/admin/apps/signals/edit-signal/edit-signal.component';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import { EditBlendComponent } from './modules/admin/apps/blends/edit-blend/edit-blend.component';
import { AddBlendComponent } from './modules/admin/apps/blends/add-blend/add-blend.component';
import { RoutinesComponent } from './modules/admin/apps/routines/routines.component';
import { RoutineEditorComponent } from './modules/admin/apps/routines/routine-editor/routine-editor.component';
import { BlendPickerComponent } from './modules/admin/apps/routines/blend-picker/blend-picker.component';
import { UserSummaryComponent } from './modules/admin/apps/accounts/user-summary/user-summary.component';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { SharedDataService } from './modules/admin/apps/routines/shared.data.service';
import { CategoriesComponent } from "./modules/admin/apps/categories/categories.component";
import { CategoryEditorComponent } from "./modules/admin/apps/categories/category-editor/category-editor.component";
import { FeaturesComponent } from "./modules/admin/apps/features/features.component";
import { InventoryListComponent } from "./modules/admin/apps/accounts/inventory/list/inventory.component";
import { FeatureEditorComponent } from "./modules/admin/apps/features/feature-editor/feature-editor.component";
import { MembershipsComponent } from "./modules/admin/apps/memberships/memberships.component";
import { MembershipEditorComponent } from "./modules/admin/apps/memberships/membership-editor/membership-editor.component";
import { SharedAlertComponent } from "./modules/admin/apps/shared-alert/shared-alert.component";
import { NotesEditorComponent } from "./modules/admin/apps/accounts/notes/notes.component";
import { VersionComponent } from "./modules/admin/apps/appversion/appversion.component";
import { AppVersionEditorComponent } from "./modules/admin/apps/appversion/appversion-editor/appversion-editor.component";
import { ProfileEditorComponent } from "./modules/admin/apps/accounts/profile/profile.component";
import {RechargeCustomerComponent} from "./modules/admin/apps/recharge-customer/recharge-customer.component";
import {CustomerDetailsComponent} from "./modules/admin/apps/recharge-customer/customer-details/customer-details.component";
import { RechargeCustomerEditorComponent } from "./modules/admin/apps/recharge-customer/recharge-customer-editor/recharge-customer-editor.component";
import { DocumentsComponent } from "./modules/admin/apps/documents/documents.component";
import { DocumentsEditorComponent } from "./modules/admin/apps/documents/documents-editor/documents-editor.component";
import { DocumentsViewComponent } from "./modules/admin/apps/documents/documents-view/documents-view.component";
import { DevicesComponent } from "./modules/admin/apps/devices/devices.component";
import { DeviceDetailsComponent } from "./modules/admin/apps/devices/device-details/device-details.component";
import { DeviceAccountDetailsComponent } from "./modules/admin/apps/devices/device-account-details/device-account-details.component";
import { PowerBIComponent } from "./modules/admin/apps/powerbi/powerbi.component"
import { NgxPaginationModule } from "ngx-pagination";
import { MembershipLevelEditorComponent } from "./modules/admin/apps/membership-levels/membership-level-editor/membership-level-editor.component";
import { MembershipLevelComponent } from "./modules/admin/apps/membership-levels/membership-levels.component";
import { ViewMembershipContentComponent } from "./modules/admin/apps/membership-levels/view-content/view-content.component";
import { CustomerMembershipEditorComponent } from "./modules/admin/apps/accounts/customermembership/customermembership.component";
import { CloseDialogComponent } from './modules/admin/common-components/close-dialog/close-dialog.component';
import { ViewImagesComponent } from './modules/admin/common-components/view-images/view-images/view-images.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { ExperiencesComponent } from "./modules/admin/apps/experiences/experiences.component";
import { ExperienceEditorComponent } from "./modules/admin/apps/experiences/experience-editor/experience-editor.component";
import { provideHttpClient } from "@angular/common/http";
import { NotificationsComponent } from "./modules/admin/apps/notifications/notifications.component";
import { NotificationFilterComponent } from "./modules/admin/apps/notifications/notification-filter/notification-filter.component"; 
import { NotificationEditorComponent } from "./modules/admin/apps/notifications/notification-editor/notification-editor.component";
@NgModule({
  declarations: [
    AppComponent,
    BlendsComponent,
    TagsComponent,
    AddTagComponent,
    EditTagComponent,
    SignalsComponent,
    AddSignalComponent,
    EditSignalComponent,
    EditBlendComponent,
    AddBlendComponent,
    RoutinesComponent,
    RoutineEditorComponent,
    BlendPickerComponent,
    UserSummaryComponent,
    CategoriesComponent,
    CategoryEditorComponent,
    FeaturesComponent,
    InventoryListComponent,
    FeatureEditorComponent,
    MembershipsComponent,
    MembershipEditorComponent,
    SharedAlertComponent,
    NotesEditorComponent,
    VersionComponent,
    AppVersionEditorComponent,
    ProfileEditorComponent,
    RechargeCustomerComponent,
    CustomerDetailsComponent,
    RechargeCustomerEditorComponent,
    DocumentsComponent,
    DocumentsEditorComponent,
    DocumentsViewComponent,
    DevicesComponent,
    DeviceDetailsComponent,
    DeviceAccountDetailsComponent,
    PowerBIComponent,
    MembershipLevelEditorComponent,
    MembershipLevelComponent,
    ViewMembershipContentComponent,
    CustomerMembershipEditorComponent,
    CloseDialogComponent,
    ViewImagesComponent,
    ExperiencesComponent,
    ExperienceEditorComponent,
    NotificationsComponent,
    NotificationFilterComponent,
    NotificationEditorComponent,
  ],
  exports: [AuthModule],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    TranslocoCoreModule,
    PowerBIEmbedModule,
    NgxPaginationModule,
    NgImageSliderModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    MaterialFileInputModule,
    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),
    MatExpansionModule,

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),
    MatAutocompleteModule,
    MatSelectModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        audience: environment.auth0.audience,
        redirect_uri: window.location.origin,
      }
    }),
    FlexModule,
    MatChipsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    ExtendedModule,
    MatIconModule, MatDialogModule, MatInputModule, MatCardModule, MatDividerModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, 
    NgxUiLoaderModule, 
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
    }),

    TranslocoCoreModule,
         
  ],
  providers: [
    SharedDataService, 
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, 
      useValue: {appearance: 'outline'}
    },
    provideHttpClient(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
