import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Documents} from './../documents';


@Component({
  selector: 'app-documents-view',
  templateUrl: './documents-view.component.html',
  styleUrls: ['./documents-view.component.scss']
})
export class DocumentsViewComponent implements OnInit {
    isLoading: boolean = false;
    document: Documents;


  constructor(
    public dialogRef: MatDialogRef<DocumentsViewComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.document = data;
  }


  async ngOnInit(): Promise<void> {   
    this.isLoading = true;    
    this.isLoading = false;
  }
  
}
