// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    opsapi: "https://dev-hapbee-ops-api.azurewebsites.net",
    auth0: {
        audience: "https://opsapi.dev.hapbeeops.io",

        domain: "hapbee-platform-dev.us.auth0.com",
        clientId: "Ibia8sZH8yUP8d1HvX7OtdMksE42B5xC"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
