import { Blend } from './../membership-levels/blend';
import {Routine} from '../routines/routine';
import { Category } from '../categories/category';

export class Scenario {
  id: string;
  name: string;
  description: string;
  categoryId: string;
  playlistIds: string[];
  signalBundleIds: string[];
  availableBlends: Blend[];
  availableRoutines: Routine[];
  scenarioCategory: Category;
}
