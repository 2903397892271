
<div fxFlexAlign="stretch" class="container">
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <div class="text-2xl font-extrabold tracking-tight">
        {{ name}}
    </div>
    <div style="width: 425px; height: 200px; text-align:center;margin:0; padding:0;">
        <ng-image-slider #nav
          [images]="imageObject"
          [infinite]="false"
          [autoSlide]="1"
          [imageSize]="{width: '50%', height: 200}"
          slideImage="1">
        </ng-image-slider>
    </div>
  
  <div mat-dialog-actions>
    <div class="flex items-center justify-between w-full border-t px-8 py-4">                     
      <button class="-ml-4" mat-button  [color]="'warn'" mat-dialog-close mat-dialog-close="true" (click)="closeDialog()">Close</button>    
    </div>    
  </div>

</div>
  
    
  