
<div mat-dialog-content>
    <div class="shadow-lg overflow-hidden">
      <div class="flex border-b">
        <form
          class="flex flex-col w-full" [formGroup]="selectedProductForm">
          <div>
            <div fxLayout="row">
              <mat-form-field class="pr-2" fxFlex="100" appearance="outline">
                <mat-label>Memberships</mat-label>
                <mat-select [formControlName]="'membershipId'">
                  <mat-option *ngFor="let level of membershipLevels" [value]="level.id">
                    {{ level.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>  
          </div>
             

          <div mat-dialog-actions>
            <div class="flex items-center justify-between w-full border-t px-8 py-4">       
              <button class="-ml-4" mat-button [color]="'warn'" mat-dialog-close mat-dialog-close="true" (click)="closeDialog()">
                Cancel
              </button>

              <div class="flex items-center">
                <div class="flex items-center mr-4" *ngIf="flashMessage">
                  <ng-container  *ngIf="flashMessage === 'success'">
                    <mat-icon
                      class="text-green-500"
                      [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <span class="ml-2">{{actionMessage}}</span>
                  </ng-container>
                  <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon
                      class="text-red-500"
                      [svgIcon]="'heroicons_outline:x'"
                    ></mat-icon>
                    <span class="ml-2">{{responseMessage}}</span>
                  </ng-container>
                </div>
                <button              
                  mat-flat-button
                  [color]="'primary'"
                  (click)="saveMembership()"
                >
                  Save
                </button>
              </div>
              
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  