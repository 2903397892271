import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {ApiKey} from './apikeys';

@Injectable({
  providedIn: 'root'
})
export class ApiKeysService {

  constructor(
    private _httpClient: HttpClient

  ) { }

  async getKeys(): Promise<ApiKey[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v1/apikey/paged?itemsPerPage=1000&pageNumber=1`)
        .pipe(
          map((entry) => entry["records"])
        )
    );
  }
  
  createKey(apiKey: any) {
    return this._httpClient.post(`${environment.opsapi}/v1/apikey`, apiKey);
  }

 
  async getKey(key: string): Promise<Notification[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v1/apikey/${key}`)
        .pipe(
          map((entry) => entry["data"])
        )
    );
  }

  updateKey(key: string, apiKey: ApiKey) {
    return this._httpClient.put(`${environment.opsapi}/v1/apikey/${key}`, apiKey);
  }


  async removeKey(key: string): Promise<any> {
    return this._httpClient.delete(`${environment.opsapi}/v1/apikey/${key}`);

  }

}
