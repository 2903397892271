<div mat-dialog-content>
  <div class="shadow-lg overflow-hidden">
    <div class="flex border-b">
      <!-- Selected product form -->
      <form
        class="flex flex-col w-full"
        [formGroup]="formGroup"
      >
        <!-- Name -->
        <mat-form-field [style.width.%]="100" class="pr-2">
          <mat-label>New E-Mail</mat-label>
          <input matInput [formControlName]="'email'"/>
        </mat-form-field>

        <div mat-dialog-actions>
          <div
            class="flex items-center justify-between w-full border-t px-8 py-4"
          >
            <button
              class="-ml-4"
              mat-button
              [color]="'warn'"
              mat-dialog-close="true"
              (click)="closeDialog()"
            >
              Close
            </button>
            <div class="flex items-center">
              <div
                class="flex items-center mr-4"
                *ngIf="flashMessage"
              >
                <ng-container
                  *ngIf="flashMessage === 'success'"
                >
                  <mat-icon
                    class="text-green-500"
                    [svgIcon]="'heroicons_outline:check'"
                  ></mat-icon>
                  <span class="ml-2">Email Changed</span>
                </ng-container>
                <ng-container *ngIf="flashMessage === 'error'">
                  <mat-icon
                    class="text-red-500"
                    [svgIcon]="'heroicons_outline:x'"
                  ></mat-icon>
                  <span class="ml-2"
                  >{{actionMessage}}</span
                  >
                </ng-container>
              </div>
              <button
                mat-flat-button
                [color]="'primary'"
                (click)="changeEmail()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>

    </div>
