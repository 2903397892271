<div fxFlexAlign="stretch" class="container">
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <mat-card>
        <mat-card-header>
          <mat-card-title>Recharge Customer Details</mat-card-title>
        </mat-card-header>
  
        <mat-card-content> 
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title class="headerTitle">
                  Personal Details
                </mat-panel-title>
              </mat-expansion-panel-header>            
                <div>
                    <h4>First Name : {{customer.customerData.customer.first_name}}</h4>
                    <h4>Last Name : {{customer.customerData.customer.last_name}}</h4>
                    <h4>Email : {{customer.customerData.customer.email}}</h4>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="headerTitle">
                    Billing Details
                  </mat-panel-title>
                </mat-expansion-panel-header>            
                  <div>
                    <h4>Billing Address1 : {{customer.customerData.customer.billing_address1}}</h4>
                    <h4>Billing Address2 : {{customer.customerData.customer.billing_address2}}</h4>
                    <h4>Billing City : {{customer.customerData.customer.billing_city}}</h4>
                    <h4>Billing Company : {{customer.customerData.customer.billing_company}}</h4>
                    <h4>Billing Country : {{customer.customerData.customer.billing_country}}</h4>
                    <h4>Billing Phone : {{customer.customerData.customer.billing_phone}}</h4>
                    <h4>Billing Province : {{customer.customerData.customer.billing_province}}</h4>
                    <h4>Billing Zip : {{customer.customerData.customer.billing_zip}}</h4>
                  </div>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="headerTitle">
                    Other Details
                  </mat-panel-title>
                </mat-expansion-panel-header>            
                  <div>
                    <h4>Created Date : {{customer.customerData.customer.created_at}}</h4>
                    <h4>Updated Date : {{customer.customerData.customer.updated_at}}</h4>
                    <h4>First Charge Date : {{customer.customerData.customer.first_charge_processed_at}}</h4>
                    <h4>Recharge ID : {{customer.customerData.customer.id}}</h4>
                    <h4>Shopify ID : {{customer.customerData.customer.shopify_customer_id}}</h4>
                    <h4>Total Subscriptions : {{customer.customerData.customer.number_subscriptions}}</h4>
                    <h4>Active Subscriptions : {{customer.customerData.customer.number_active_subscriptions}}</h4>
                    <h4>Processor : {{customer.customerData.customer.processor_type}}</h4>
                    <h4>Status : {{customer.customerData.customer.status}}</h4>
                    <h4>Can Add Payment Method : {{customer.customerData.customer.can_add_payment_method}}</h4>
                    <h4>Has Valid Payment Method : {{customer.customerData.customer.has_valid_payment_method}}</h4>
                    <h4>Has Card Error : {{customer.customerData.customer.has_card_error_in_dunning}}</h4>
                    <h4>Reason Payment Method Not Valid : {{customer.customerData.customer.reason_payment_method_not_valid}}</h4>

                  </div>
              </mat-expansion-panel>
          </mat-accordion> 
        </mat-card-content>
    </mat-card>
  </div>
  
  <div mat-dialog-actions>
    <div class="flex items-center justify-between w-full border-t px-8 py-4">                     
      <button class="-ml-4" mat-button  [color]="'warn'" mat-dialog-close mat-dialog-close="true" (click)="closeDialog()">Close</button>
      
    </div>
  </div>
  
    
  