import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Haptics, LightMode, SleeppadHaptics} from '../routine';
import { SharedDataService } from './../shared.data.service';
import {Blend} from '../../offers/blend';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';


@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-blend-picker',
  templateUrl: './blend-picker.component.html',
  styleUrls: ['./blend-picker.component.scss']
})
export class BlendPickerComponent implements OnInit {
  public edited = false;
  rdata = this.sharedDataService.getData();
  isViewData = this.sharedDataService.getViewDataFlag();
  selectedProductForm: FormGroup;

  blends: Blend[];
  hours: string;
  minutes: string;

  constructor(
    private _formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    public dialogRef: MatDialogRef<BlendPickerComponent>,
    private closeDialogComponent: CloseDialogComponent,
    @Inject(MAT_DIALOG_DATA) public data: Blend[]
  ) {
    this.blends = data;
  }

  public get HapticsValues() {
    return Haptics;
  }

  public get SleepPadHapticsValues() {
    return SleeppadHaptics;
  }

  public get LightModeValues() {
    return LightMode;
  }

  ngOnInit(): void {

    if(this.isViewData){
      this.selectedProductForm = this._formBuilder.group({
        blend: [{ value: '', disabled: true }, [Validators.required]],
        sortOrder: [{ value: '', disabled: true }, [Validators.required]],      
        sleeppadhaptics: [{ value: '', disabled: true }, [Validators.required]],
        hours: [{ value: '', disabled: true }, [Validators.required]],
        minutes: [{ value: '', disabled: true }, [Validators.required]],
        haptics: [{ value: '', disabled: true }, [Validators.required]],
        lightMode: [{ value: '', disabled: true }, [Validators.required]],
        brightness: [{ value: '', disabled: true }, [Validators.required]],
        red: [{ value: '', disabled: true }, [Validators.required]],
        green: [{ value: '', disabled: true }, [Validators.required]],
        blue: [{ value: '', disabled: true }, [Validators.required]],
      });    
    }
    else{
      this.edited = true;
      this.selectedProductForm = this._formBuilder.group({
        blend: ['', [Validators.required]],
        sortOrder: ['', [Validators.required]],      
        sleeppadhaptics: ['', [Validators.required]],
        hours: ['', [Validators.required]],
        minutes: ['', [Validators.required]],
        haptics: ['', [Validators.required]],
        lightMode: ['', [Validators.required]],
        brightness: ['', [Validators.required]],
        red: ['', [Validators.required]],
        green: ['', [Validators.required]],
        blue: ['', [Validators.required]],
      });
    }
  
    if (this.rdata){
      this.selectedProductForm.get('blend').setValue(this.rdata.blend.id);
      this.selectedProductForm.get('sortOrder').setValue(this.rdata.sortOrder);
      if (this.rdata.feedback.sleepPad.haptics == "None" || this.rdata.feedback.sleepPad.haptics == "none"){
        this.selectedProductForm.get('sleeppadhaptics').setValue(SleeppadHaptics.none);
      }
      else if (this.rdata.feedback.sleepPad.haptics == "One" || this.rdata.feedback.sleepPad.haptics == "one"){
        this.selectedProductForm.get('sleeppadhaptics').setValue(SleeppadHaptics.one);
      }
      else if (this.rdata.feedback.sleepPad.haptics == "Two" || this.rdata.feedback.sleepPad.haptics == "two"){
        this.selectedProductForm.get('sleeppadhaptics').setValue(SleeppadHaptics.two);
      }
      
      this.selectedProductForm.get('hours').setValue(this.rdata.suggestedDuration.split(":")[0]);
      this.selectedProductForm.get('minutes').setValue(this.rdata.suggestedDuration.split(":")[1]);
      if (this.rdata.feedback.headBand.haptics == "None" || this.rdata.feedback.headBand.haptics == "none"){
        this.selectedProductForm.get('haptics').setValue(Haptics.none);
      }
      else if (this.rdata.feedback.headBand.haptics == "One" || this.rdata.feedback.headBand.haptics == "one"){
        this.selectedProductForm.get('haptics').setValue(Haptics.one);
      }
      else if (this.rdata.feedback.headBand.haptics == "Two" || this.rdata.feedback.headBand.haptics == "two"){
        this.selectedProductForm.get('haptics').setValue(Haptics.two);
      }
      this.selectedProductForm.get('lightMode').setValue(this.rdata.feedback.headBand.light.mode);
      this.selectedProductForm.get('brightness').setValue(this.rdata.feedback.headBand.light.brightness);
      this.selectedProductForm.get('red').setValue(this.rdata.feedback.headBand.light.color.red);
      this.selectedProductForm.get('green').setValue(this.rdata.feedback.headBand.light.color.green);
      this.selectedProductForm.get('blue').setValue(this.rdata.feedback.headBand.light.color.blue);
      
    }
    else{
      this.selectedProductForm.get('sortOrder').setValue(1);
      this.selectedProductForm.get('sleeppadhaptics').setValue(SleeppadHaptics.none);
      this.selectedProductForm.get('hours').setValue(1);
      this.selectedProductForm.get('minutes').setValue(0);
      this.selectedProductForm.get('haptics').setValue(Haptics.none);
      this.selectedProductForm.get('lightMode').setValue(LightMode.breathe);
      this.selectedProductForm.get('brightness').setValue(255);
      this.selectedProductForm.get('red').setValue(255);
      this.selectedProductForm.get('green').setValue(255);
      this.selectedProductForm.get('blue').setValue(255);
    }
 

  }

  selectBlend() {
    const picked = this.selectedProductForm.getRawValue();
    this.dialogRef.close({
      blendId: picked.blend,
      suggestedDuration: `${String(picked.hours).padStart(2, '0')}:${String(picked.minutes).padStart(2,'0')}:00`,
      sortOrder: this.selectedProductForm.get('sortOrder').value,
      sleeppadhaptics: this.selectedProductForm.get('sleeppadhaptics').value,
      haptics: this.selectedProductForm.get('haptics').value,
      lightMode: this.selectedProductForm.get('lightMode').value,
      brightness: this.selectedProductForm.get('brightness').value,
      red: this.selectedProductForm.get('red').value,
      green: this.selectedProductForm.get('green').value,
      blue: this.selectedProductForm.get('blue').value,

    })
  }
}
