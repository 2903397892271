<div *ngIf="hasAccess === 'yes'"
     class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"
>
  <!-- Header -->
  <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
    <!-- Loader -->
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <!-- Title -->
    <div class="text-4xl font-extrabold tracking-tight">Users</div>
    <!-- Actions -->

    <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
      <!-- Search -->
      <div style="height: 20px">
        <mat-form-field appearance="outline">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [formControl]="searchInputControl" [autocomplete]="'off'" [placeholder]="'Search user'"/>
          <button matSuffix mat-icon-button aria-label="Clear" (click)="clearInput()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <!-- Add product button -->
      <div class="add-button" style="height: 10px">
        <button *ngIf="canAdd" class="ml-4" mat-flat-button [color]="'primary'" (click)="openDialog()" >
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Add</span>
        </button>
      </div>
      
    </div>
  </div>

  <!-- Main -->
  <div class="flex flex-auto overflow-hidden">
    <!-- Products list -->
    <div
      class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto"
    >
      <ng-container>
        <ng-container>
          <div class="grid">
            <!-- Header -->
            <div
              class="inventory-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
              matSort
              matSortDisableClear
            >
              <div class="hidden md:block" [mat-sort-header]="'Name'">
                Name
              </div>
              <div class="hidden md:block" [mat-sort-header]="'email'">
                Email
              </div>

              <div class="hidden sm:block">Login Allowed</div>
              <div class="hidden sm:block">Membership Levels</div>
              <div class="hidden md:block">
                Actions
              </div>

            </div>
            <!-- Rows -->
            <ng-container *ngIf="products.length > 0 ;else no_account">
              <ng-container *ngFor="let product of products  | paginate: {itemsPerPage: tableSize, currentPage: page, totalItems: count}; let i = index">
                <div class="inventory-grid2 grid items-center gap-4 py-3 px-6 md:px-8 border-b">
                  <!-- Name -->
                  <div>
                    {{ product.lastName }} {{ product.firstName }}
                    <div class="warn" *ngIf="!product.loginAllowed"> (Deactivated)</div>
                    <div class="green" *ngIf="product.loginAllowed && product.auth0Id!=null && product.auth0Id!=''"> (Active)</div>
                  </div>

                  <div>
                    {{ product.email }}
                  </div>

                  <div>
                    {{ product.loginAllowed }}
                  </div>

                  <div>
                    <div *ngFor="let level of product.membershipLevels">
                      <div class="chip">{{ level.name }}</div>
                    </div>
                  </div>

                  <div>
                    <button mat-flat-button [matMenuTriggerFor]="menu">Actions Menu</button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item *ngIf="!product.loginAllowed && canEdit"  (click)="activate(product)">Activate</button>
                      <button mat-menu-item *ngIf="canEdit" (click)="changeEmail(product)">Change Email</button>
                      <button mat-menu-item *ngIf="product.loginAllowed && canEdit"  (click)="deactivate(product)">Deactivate</button>
                      <button mat-menu-item class="warn" *ngIf="canDelete" (click)="delete(product)">Delete</button>                      
                      <button mat-menu-item *ngIf="canEdit" (click)="edit(product)">Edit</button>
                      <button mat-menu-item *ngIf="product.loginAllowed && canEdit"  (click)="reset(product)">Reset Password</button>
                      <button mat-menu-item (click)="viewSummary(product)">User Summary</button>
                     
             
                    </mat-menu>

                  </div>
                </div>
                <!-- <div class="grid">
                  <ng-container *ngIf="selectedProduct?.id === product.id">
                    <ng-container
                      *ngTemplateOutlet="
                        rowDetailsTemplate;
                        context: { $implicit: product }
                      "
                    ></ng-container>
                  </ng-container>
                </div> -->
              </ng-container>
            </ng-container>

            <ng-template #no_account>
              <mat-chip-list fxLayout="row" style="padding:10px;">
                <mat-chip>
                  No Search Result found.
                </mat-chip>             
              </mat-chip-list>

              <div class="add-button" style="height: 10px" fxLayout="row"> 
                <button *ngIf="canAdd" class="ml-4" mat-flat-button [color]="'primary'" (click)="restore()">
                  <span class="ml-2 mr-1">Restore</span>
                </button>
              </div>
  
          </ng-template>  

          </div>

          
        </ng-container>
      </ng-container>

      <ng-template #rowDetailsTemplate let-product>
        <div class="shadow-lg overflow-hidden">
          <div class="flex border-b">
            <!-- Selected product form -->
            <form class="flex flex-col w-full" [formGroup]="selectedProductForm">
              <div class="flex flex-col sm:flex-row p-8">
                <div class="flex flex-auto flex-wrap">
                  <!-- Name, SKU & etc. -->
                  <div class="flex w-full lg:w-2/4 sm:pl-8">
                    <!-- Name -->
                    <mat-form-field class="w-1/3 pr-2">
                      <mat-label>First Name</mat-label>
                      <input matInput [formControlName]="'firstName'"/>
                    </mat-form-field>

                    <mat-form-field class="w-1/3 pr-2">
                      <mat-label>Last Name</mat-label>
                      <input matInput [formControlName]="'lastName'"/>
                    </mat-form-field>

                    <mat-form-field class="w-1/3 pr-2">
                      <mat-label>Email</mat-label>
                      <input matInput [formControlName]="'email'"/>
                    </mat-form-field>

                  </div>
                </div>
              </div>

              <div class="flex items-center justify-between w-full border-t px-8 py-4">
                <button
                  class="-ml-4"
                  mat-button
                  [color]="'warn'"
                  (click)="deleteSelectedProduct()"
                >
                  Delete
                </button>
                <div class="flex items-center">
                  <div class="flex items-center mr-4" *ngIf="flashMessage">
                    <ng-container *ngIf="flashMessage === 'success'">
                      <mat-icon
                        class="text-green-500"
                        [svgIcon]="'heroicons_outline:check'"
                      ></mat-icon>
                      <span class="ml-2">Product updated</span>
                    </ng-container>
                    <ng-container *ngIf="flashMessage === 'error'">
                      <mat-icon
                        class="text-red-500"
                        [svgIcon]="'heroicons_outline:x'"
                      ></mat-icon>
                      <span class="ml-2">An error occurred, try again!</span>
                    </ng-container>
                  </div>
                  <button
                    mat-flat-button
                    [color]="'primary'"
                    (click)="updateSelectedProduct()"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  
  <div class='parent' > 
    <div class="child" align="end">
      <pagination-controls (pageChange)="onTableDataChange($event)"></pagination-controls>
    </div>
  </div>


</div>
<div *ngIf="hasAccess === 'no'" class="flex flex-col flex-auto min-w-0">
  <h1>No Access</h1>
</div>
<div *ngIf="hasAccess === 'validating'" class="flex flex-col flex-auto min-w-0">
  <h1>Validating Access</h1>
</div>
