import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { Auth0Guard } from 'app/core/auth/guards/auth0.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import {BlendsComponent} from './modules/admin/apps/blends/blends.component';
import {TagsComponent} from './modules/admin/apps/tags/tags.component';
import {SignalsComponent} from './modules/admin/apps/signals/signals.component';
import {RoutinesComponent} from './modules/admin/apps/routines/routines.component';
import { CategoriesComponent } from './modules/admin/apps/categories/categories.component';
import { FeaturesComponent } from './modules/admin/apps/features/features.component';
import { MembershipsComponent } from './modules/admin/apps/memberships/memberships.component';
import { VersionComponent } from './modules/admin/apps/appversion/appversion.component';
import {RechargeCustomerComponent} from "./modules/admin/apps/recharge-customer/recharge-customer.component";
import { DocumentsComponent } from './modules/admin/apps/documents/documents.component';
import { DevicesComponent } from "./modules/admin/apps/devices/devices.component";
import { PowerBIComponent } from "./modules/admin/apps/powerbi/powerbi.component"
import { MembershipLevelComponent } from './modules/admin/apps/membership-levels/membership-levels.component';
import { ExperiencesComponent } from './modules/admin/apps/experiences/experiences.component';
import { NotificationsComponent } from './modules/admin/apps/notifications/notifications.component';
import { ApiKeyComponent } from './modules/admin/apps/apikeys/apikeys.component';
import { ScenarioComponent } from './modules/admin/apps/scenarios/scenarios.component';

export const appRoutes: Route[] = [
    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },

            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',

        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    {
        path: 'apps',
        canActivate: [Auth0Guard],
        canActivateChild: [Auth0Guard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            // Dashboards

            {
                path: 'manufacturing',
                loadChildren: () =>
                  import(
                    'app/modules/admin/apps/manufacturing/manufacturing.module'
                    ).then((m) => m.ManufacturingModule),
            },
            {
                path: 'dashboards',
                loadChildren: () =>
                    import(
                        'app/modules/admin/dashboards/project/project.module'
                    ).then((m) => m.ProjectModule),
            },

            {
                path: 'account',
                loadChildren: () =>
                    import(
                        'app/modules/admin/apps/accounts/account.module'
                    ).then((m) => m.AccountModule),
            },
            {
                path: 'blends',
                component: BlendsComponent
            },
            {
                path: 'routines',
                component: RoutinesComponent
            },

            {
                path: 'tags',
                component: TagsComponent
            },

            {
                path: 'signals',
                component: SignalsComponent
            },
            {
                path: 'categories',
                component: CategoriesComponent
            },
            {
                path: 'features',
                component: FeaturesComponent
            },
            {
                path: 'memberships',
                component: MembershipsComponent
            },
            {
                path: 'appversion',
                component: VersionComponent
            },
            {
                path: 'notifications',
                component: NotificationsComponent
            },
            {
                path: 'recharge-customer',
                component: RechargeCustomerComponent
            },
            {
                path: 'membership-levels',
                component: MembershipLevelComponent
            },
            {
                path: 'experiences',
                component: ExperiencesComponent
            },
            {
                path: 'scenarios',
                component: ScenarioComponent
            },
            {
                path: 'documents',
                component: DocumentsComponent
            },
            {
                path: 'devices',
                component: DevicesComponent
            },
            {
                path: 'powerbi',
                component: PowerBIComponent
            },
            {
                path: 'apikeys',
                component: ApiKeyComponent
            },
        ],
    },
];
