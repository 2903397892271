import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, Observable, map} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import { Device, DeviceAccountPair } from './devices';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  constructor(
    private _httpClient: HttpClient
  ) {
  }


  getAllDevices(): Promise<Device[]> {
    return firstValueFrom(this._httpClient
      .get<Device[]>(
        `${environment.opsapi}/v5/device/paged?itemsPerPage=25&sortFieldName=created&sortBy=asc&pageNumber=1`
      )
      .pipe(
        map((entry) => entry["records"])       
      ));     
  }

  getDeviceAccountInformation(code: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/DeviceAccountPair/find-by-code/${code}`,);
  }


  getDeviceByCode(code: string): Observable<any> {
    try{
      return this._httpClient.get(
        `${environment.opsapi}/v4/Device/by-code/${code}`,);
    }
    catch(e: unknown){}
    
  }




}
