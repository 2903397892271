import { Component, ElementRef, OnInit } from '@angular/core';
import { IReportEmbedConfiguration, models, service } from 'powerbi-client';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';

@Component({
  selector: 'app-powerbi',
  templateUrl: './powerbi.component.html',
  styleUrls: ['./powerbi.component.scss']
})
export class PowerBIComponent implements OnInit {
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  constructor(
    private _auth: AuthServiceClass,
    private _titleService: Title
  ) {
  }
  

  // CSS Class to be passed to the wrapper
  reportClass = 'report-container';

  // Flag which specify the type of embedding
  phasedEmbeddingFlag = false;

  reportConfig: IReportEmbedConfiguration = {
    type: "report",
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Embed,
    settings: {
      filterPaneEnabled: true,
      navContentPaneEnabled: true,
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage
      }
    }
  }

  eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
    ['loaded', () => console.log('Report has loaded')],
    ['rendered', () => console.log('Report rendered')],
    ['error', (event?: service.ICustomEvent<any>) => console.error(event ? event.detail : "no event obj")],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => console.log(event)],
  ]);



  async ngOnInit(){
    this._titleService.setTitle('Hapbee OpsPortal - Power BI Reports');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:usage-report')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }

    await this.embedReport();
  }

  async embedReport() {
        this.reportConfig = {
          ...this.reportConfig,
          id: '2e18abd0-4f6a-4f0e-9d91-b0b9dfa3a6c5',
          embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=2e18abd0-4f6a-4f0e-9d91-b0b9dfa3a6c5&groupId=e07fa8ab-41eb-4204-958e-cc539d6dc8ef',
          accessToken: 'H4sIAAAAAAAEAB3Tt660ZgBF0Xe5LZbIydJfkBnCDDl15AwfaQiW391X7k-1dPY_P1Z6D3Na_Pz9o2jm6y2NI3hNj4zDuB_mo0MUtarzg0PbZa66Fn3cwZEk1SashisLJOmCtahRjkkTluyfozyd4mXxInPBRC0ezRRo31Gjn8kadi_xtC4QLZBAaD516dhADiABajl3ay90VCBS19NYbbwHMt9lf31kY747AS80gKOp91GBEg4U5N0GweTPpjYZH_sP_JnbZdusuAzHDhkbK8YQJVvjHr1nq879rZDVdYWpOMcRnc7hoqKrjgsZIh8CMSl8nCnGeF7vTNE8kHLBBcEOC7-a5H6_OJ-TaQRB4IxE6nbL5ixRCemDqvV85cdhsXSfOiUnPrE0dMJDCfs8moWmXVTsMKPq2xOQ1HJuV1BgHs5j6Fe9lpbIIao-5WPP1VyMaJSbvFYf7gY9QsOEXvLCUmVS3iuaK1hbmKzbuJ9dHkvgfeEhuaVfKNjFFz1JcOPEcwfp5ilPiGrDWgjHHspqaIg-I795LSZJcR-6E7x6hSrGX5r3wmsXvXYqs2YXqQFrIV3q2efOwHhVZRCUoWoimkxfOxidwLxR8g0Oq2nLsPfwhnEE7_Pw0NkFAEiG-lFldx1ey4y16VwcAenBS3a88osZE8T63mQ0Uo6ESCLjpLJFvvrNXUzO6LUMEblCKZZhPKLt49Jb8Fk7xrNLuAYzSYFKc3PzUj5YvEyOQ0ENMRRjnjLccRK2W_F3dgUI3pkfUqm3tJDhnT12Fk2Mjgj7Q7itmDWRl5RkPItlr-GgVY0_dVPXVTue5j8_f_0I6w32WS_v3-sXlV6RWrz364fsm8jNrLSgabPHA1PwciV4J_bJy5UlSemR8xBk-3UeYXvI-D4TjKagymc7bXAhYJToQa0khAy7FBtYNrFFNr0wJVf4rWe0ufQtEr01RDiLMo-mmGzUW45lwwBl-8OzydOrTAPWLagvAw5dXL3bnFISExOeMJuZICKLgGNM7vl1eXUP5e03t8WQRmiKKH5UleRrzLuHb-xjNgXGWBXaAs5up1qpWuOpq-p8eo2JG5V-yf0cBruiT710nRYEE2m2cVQBFUoXWY5dynX2WR5LipBtJYZD1N-4FV_cObw7eqPGqfloNE9TcWbMZOpdsdGbONzIb7EM7ykrdfvP_8w3aMr1FfwqJzh5X7cKyI5sq4SVv8pN3PX_K7etp3Q_1vJ3ZgryuWtLeh1fNjUMVnX0-TwMhWBrBL95zc6068XTPIR71TxwQhZWb1L2SHjq5-KdxXcPo92-1PMaOORrc8mnGW3LY9vaS5p1CVJHsAh8WmVHS4jXYzfYqLRwt3n1qO-qD99OAuxkEbEySAISUCODhlG80p-wHCRnrDJgx9l7PTAh6Gl8i5CuNyspyZcuxIKtSRnPZIF0Adr3ODhzEh5GucoWTSYbtA_10rHLtLCIpUAJiVuiUyDqBLbXrJEKqcXrq_MtJHBo1Hau8et0u3zsSJKpPJnGl3SqMEUhPruagWT2hEpDzNJ3Aczuu_Qowvie6446M-Di9moOraGY0PBRMoz7Zf73P7p6sQLuBQAA.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImV4cCI6MTY5ODc3NDk5NCwiYWxsb3dBY2Nlc3NPdmVyUHVibGljSW50ZXJuZXQiOnRydWV9',
        };

        // Get the reference of the report-container div
        //const reportDiv = this.element.nativeElement.querySelector('.report-container');
        //console.log("element -> nativeElement -> report-container:", reportDiv);
        
        //if (reportDiv) {
          // When Embed report is clicked, show the report container div
          //reportDiv.classList.remove('hidden');
        //}
      error => {
        console.log(error);
      }
  }

}