import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom,  tap} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {AppVersion, mobileVersion} from './appversion';


@Injectable({
  providedIn: 'root'
})
export class AppVersionService {  
  constructor(
    private _httpClient: HttpClient
  ) {
  }

  

  getAndroidVersion(){
    return firstValueFrom(this._httpClient
      .get<AppVersion[]>(
        `${environment.opsapi}/v4/mobileversions/android`
      )
      );
  }

  getIOSVersion(){
    return firstValueFrom(this._httpClient
      .get<AppVersion[]>(
        `${environment.opsapi}/v4/mobileversions/IOS`
      )
      );
  }

  updateMobileVersion(id: string, mobileversion: mobileVersion) {
    return this._httpClient.put(`${environment.opsapi}/v4/mobileversions/${id}`, mobileversion);
  }

  
}
