<div mat-dialog-content>
    <div class="shadow-lg overflow-hidden">
      <div class="flex border-b">
        <!-- Selected product form -->
        <form
          class="flex flex-col w-full"
          [formGroup]="selectedProductForm"
        >
          <div fxLayout="row">  
            <mat-form-field class="pr-2" fxFlex="25">
                <mat-label>Platform</mat-label>
                <input matInput [formControlName]="'platform'"/>                            
            </mat-form-field>            
          </div>

          <div fxLayout="row">
            <mat-label class="heading">Minimum Version</mat-label>
          </div>

          <div fxLayout="row">  
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Major</mat-label>
              <input matInput type="number" [formControlName]="'minimumVersionMajor'" min=1 max=9/>
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Minor</mat-label>
              <input matInput type="number" [formControlName]="'minimumVersionMinor'" min=0 max=9/>
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Patch</mat-label>
              <input matInput type="number" [formControlName]="'minimumVersionPatch'" min=0 max=99/>
            </mat-form-field>
                    
          </div>

          <div fxLayout="row">
            <mat-label class="heading">Latest Version</mat-label>
          </div>
          
          <div fxLayout="row">  
            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Major</mat-label>
              <input matInput type="number" [formControlName]="'latestVersionMajor'" min=1 max=9/>
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Minor</mat-label>
              <input matInput type="number" [formControlName]="'latestVersionMinor'" min=0 max=9/>
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="33">
              <mat-label>Patch</mat-label>
              <input matInput type="number" [formControlName]="'latestVersionPatch'" min=0 max=99/>
            </mat-form-field>
    
          </div>
          
          <div mat-dialog-actions>
            <div
              class="flex items-center justify-between w-full border-t px-8 py-4"
            >
            <app-close-dialog></app-close-dialog>
              <div class="flex items-center">
                <div
                  class="flex items-center mr-4"
                  *ngIf="flashMessage"
                >
                  <ng-container
                    *ngIf="flashMessage === 'success'"
                  >
                    <mat-icon
                      class="text-green-500"
                      [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <span class="ml-2">{{actionMessage}}</span>
                  </ng-container>
                  <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon
                      class="text-red-500"
                      [svgIcon]="'heroicons_outline:x'"
                    ></mat-icon>
                    <span class="ml-2">{{responseMessage}}</span>
                  </ng-container>
                </div>
                <button
                  
                  mat-flat-button
                  [color]="'primary'"
                  (click)="saveVersion()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  