<div *ngIf="hasAccess === 'yes'"
  class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"
>
  <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
    <!-- Loader -->
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>

    <div class="text-4xl font-extrabold tracking-tight">Devices</div>
     
      <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
        <div style="height: 20px">
          <mat-form-field appearance="outline">          
            <input matInput [formControl]="searchInputControl" [autocomplete]="'off'" [placeholder]="'Search Device'"/>
            <button matSuffix mat-icon-button aria-label="Clear" (click)="clearInput()">
                <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> 
        </div>       
      </div>   

    
  </div>

  <div class="flex flex-auto overflow-hidden">
    <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
      <ng-container>
        <ng-container>
          <div class="grid">
            <!-- Header -->
            <div
              class="inventory-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
            >
              <div class="hidden md:block">
                Code
              </div>
              <div class="hidden md:block">
                Device Type
              </div>
              <div class="hidden md:block">
                Created Date
              </div>
              <div class="hidden md:block">
                Status
              </div>
                                    
              <div class="hidden md:block">
                Actions
              </div>
            </div>
            <!-- Rows -->
            <div class="grid" *ngIf="devices.length > 0 ;else no_devices">  
                <ng-container>
                    <ng-container *ngFor="let device of devices | paginate: {itemsPerPage: tableSize, currentPage: page, totalItems: count}; let i = index">
                      <div class="inventory-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b">
                        <!-- Name -->
                        <div>
                          {{ device.code }}
                        </div>
      
                        <div >
                          {{ device.deviceType }}
                        </div>
      
                        <div>
                          {{ device.created }}
                        </div>
      
                        <div>
                          {{ device.deviceStatus }}
                        </div>
      
                        <div>
                          <button mat-button [matMenuTriggerFor]="menu">Actions Menu</button>
                          <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="viewDetails(device)">View Details</button>
                            <button mat-menu-item (click)="viewAccountDetails(device)">Device Account Pair</button>
                          </mat-menu>
      
                        </div>
                      </div>
      
                    </ng-container>
                  </ng-container>
            </div>  
            
            <ng-template #no_devices>
                <mat-chip-list >
                  <mat-chip>
                    No device found.
                  </mat-chip>             
                </mat-chip-list>
    
            </ng-template>   

            
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class='parent' > 
    <div class="child" align="end">
      <pagination-controls (pageChange)="onTableDataChange($event)"></pagination-controls>
    </div>
  </div>
</div>
<div *ngIf="hasAccess === 'no'" class="flex flex-col flex-auto min-w-0">
  <h1>No Access</h1>
</div>
<div *ngIf="hasAccess === 'validating'" class="flex flex-col flex-auto min-w-0">
  <h1>Validating Access</h1>
</div>
