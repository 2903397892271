<div *ngIf="hasAccess === 'yes'">
    <div class="report-div">
        <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass" [phasedEmbedding]="phasedEmbeddingFlag" [eventHandlers]="eventHandlersMap">
        </powerbi-report>
    </div>
</div>
<div *ngIf="hasAccess === 'no'" class="flex flex-col flex-auto min-w-0">
  <h1>No Access</h1>
</div>
<div *ngIf="hasAccess === 'validating'" class="flex flex-col flex-auto min-w-0">
  <h1>Validating Access</h1>
</div>
