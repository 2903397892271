import { Component, OnInit } from '@angular/core';
import {Feature} from './feature';
import {FeaturesService} from './features.service';
import {MatDialog} from '@angular/material/dialog';
import {FeatureEditorComponent} from './feature-editor/feature-editor.component';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  isLoading: boolean = false;
  features: Feature[] = [];
  fullfeatures: Feature[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  query = '';

  constructor(
    private _featuresService: FeaturesService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    private _fuseConfirmationService: FuseConfirmationService,

    public dialog: MatDialog

  ) { }

  async ngOnInit() : Promise<void> {
    this._titleService.setTitle('Hapbee OpsPortal - Features Flags');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:featureflag')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:featureflag');
    this.canEdit = perms.includes('update:featureflag');
    this.canDelete = perms.includes('delete:featureflag');

    await this.getFeatures();
    this.isLoading = false;

    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        switchMap(async (query) => {
          this.isLoading = true;
          this.query = query.trim();
          if(this.query){
            this.features = this.features.filter(t => t.name.toLowerCase().includes(query.toLowerCase()));
          }
          else{
            this.features = this.fullfeatures;
          }
    
        }),
        map(() => {
          this.isLoading = false;
        })
      )
      .subscribe();

  }

  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.features = this.fullfeatures;
    this.isLoading = false;
  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.features;
  }


  async getFeatures() {
    this.isLoading = true;   
    this.features = await this._featuresService.getFeatures(1,25);  
    this.features.sort(function(a, b) {
      return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0);
    });
    
    this.isLoading = false;
    this.fullfeatures = this.features;
  }

  addFeature() {
    let dialogref = this.dialog.open(FeatureEditorComponent, 
    {
      width: '700px',
      disableClose: true,
      data: {feature: null}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.getFeatures();
        this.isLoading = false;
      }
    });
  }

  editFeature(feature) {
    let dialogref = this.dialog.open(FeatureEditorComponent, 
    {
      width: '700px',
      disableClose: true,
      data: {feature: feature}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.getFeatures();
        this.isLoading = false;
      }
      
    });
  }

  removeFeature(feature) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Feature flag?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }    
      this._featuresService.removeFeature(feature.id).then( async () => {
        this.isLoading = true;
        await this.getFeatures();
        this.isLoading = false;
        this.dialog.open(SharedAlertComponent,
        {
          width: 'auto',
          disableClose: false,
          data: {dialogMessage:'Feature Flag: ' + feature.name + ' is deleted successfully'}
        });
    });

  });
}
}
