import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom,  map} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Membership} from './membership';
import { InventoryPagination} from "app/modules/admin/apps/accounts/inventory/inventory.types";


@Injectable({
  providedIn: 'root'
})
export class MembershipsService {
  private count: number;
  private _pagination: InventoryPagination;
  constructor(
    private _httpClient: HttpClient
  ) {
  }

  get pagination(): InventoryPagination {
    return this._pagination;
  }



  getMemberships(): Promise<Membership[]> {
    return firstValueFrom(this._httpClient
      .get<Membership[]>(
        `${environment.opsapi}/v5/membershipstatuses/paged?itemsPerPage=25&pageNumber=1`
      )
      .pipe(
        map((entry) => entry["records"]) 
      ));
  }

  addMembership(membership: Membership) {
    return this._httpClient.post(`${environment.opsapi}/v4/membershipstatuses`, membership);
  }

  updateMembership(id: string, membership: Membership) {
    return this._httpClient.put(`${environment.opsapi}/v4/membershipstatuses/${id}`, membership);
  }


  async removeMembership(id){
    return this._httpClient.delete(`${environment.opsapi}/v4/membershipstatuses/${id}`);

  }

  getMembershipById(membership: Membership) {
    return this._httpClient.get(`${environment.opsapi}/v4/membershipstatuses/${membership.id}`);
  }


  getStats() {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/membershipstatuses/count`));
}

  
}
