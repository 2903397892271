import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Tag} from '../tags/tags';
import {firstValueFrom, map} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Category} from './category';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private _httpClient: HttpClient

  ) { }

  async getCategories(): Promise<Category[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/Categories`)
        .pipe(
          map((entry) => entry["data"])
        )
    );
  }

  addCategory(category: Category) {
    return this._httpClient.post(`${environment.opsapi}/v4/Categories`, category);
  }

  updateCategory(id: string, category: Category) {
    return this._httpClient.put(`${environment.opsapi}/v4/Categories/${id}`, category);
  }


  async removeCategory(id){
    return this._httpClient.delete(`${environment.opsapi}/v4/Categories/${id}`);

  }
}
