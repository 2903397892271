import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MembershipsService} from '../memberships.service';
import { Membership } from '../membership';
import {Type} from '../membership';


@Component({
  selector: 'app-membership-editor',
  templateUrl: './membership-editor.component.html',
  styleUrls: ['./membership-editor.component.scss']
})
export class MembershipEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  membership: Membership;
  responseMessage: string;
  actionMessage: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _membershipsService: MembershipsService,
    public dialogRef: MatDialogRef<MembershipEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
      this.membership = data.membership;
  }

  public get TypeValues() {
    return Type;
  }


  async ngOnInit(): Promise<void> {   
    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
     
    });

    if (this.membership) {
      this.selectedProductForm.get('name').setValue(this.membership.name);
      this.selectedProductForm.get('type').setValue(this.membership.type);      
    } 
  }
  

  closeDialog(value = false) {
    this.dialogRef.close(value);
  }

  showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialog();
      }
      

      this.flashMessage = null;
      
      // Mark for check
      this._changeDetectorRef.markForCheck();

    }, 3000);
  }


  saveMembership() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
      const picked = this.selectedProductForm.getRawValue();
      
      let newMembership = new Membership();
      newMembership.name = picked.name;
      newMembership.type = picked.type;      

      this.saving = true;
      if (this.membership == null) {
        this._membershipsService.addMembership(newMembership).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Membership Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      else{
        this._membershipsService.updateMembership(this.membership.id, newMembership).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Membership Updated';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }

      
    }
  }


}
