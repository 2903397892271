import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map,  of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Experience} from './experience';
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ExperiencesService {
  constructor(
    private _httpClient: HttpClient
  ) {
  }

  async getExperiences(): Promise<Experience[]>  {
    await new Promise(f => setTimeout(f, 1000));
    return firstValueFrom(
        this._httpClient.get(`${environment.opsapi}/v1/experience/paged?itemsPerPage=1000&pageNumber=1`)
          .pipe(
            map((entry) => entry["records"])
          )
      );
  }

  async getExperienceByCode(experience: Experience) {
    return this._httpClient.get(`${environment.opsapi}/v1/experience/${experience.code}`);
  }


  addExperience(experience: Experience) {
    return this._httpClient.post(`${environment.opsapi}/v1/experience/`, experience);
  }



  updateExperience(code: string, experience: Experience) {
    return this._httpClient.put(`${environment.opsapi}/v1/experience/${code}`, experience);
  }


  async removeExperience(code): Promise<any> {
    return firstValueFrom(this._httpClient.delete(`${environment.opsapi}/v1/experience/${code}`));
  }

  async delay(){
    await new Promise(f => setTimeout(f, 2000));
  }

  uploadImage(code, images: any) {    
    let formData: any = new FormData();
    if (images.bannerFileData) {
      formData.append('bannerFile', images.bannerFileData);
    }
    if (images.imageFileData) {
      formData.append('imageFile', images.imageFileData);
    }

    if (images.bannerFileData || images.imageFileData) {
      return this._httpClient.put(`${environment.opsapi}/v1/experience/${code}/upload`, formData);
    } else {
      return of(true);
    }

  }

 

}