import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Customer, CustomerData, RechargeCustomer } from '../recharge-customer';
import { RechargeCustomerService } from '../recharge-customer.service';
import { firstValueFrom } from 'rxjs';
import { InventoryService } from '../../accounts/inventory/inventory.service';


@Component({
  selector: 'app-recharge-customer-editor',
  templateUrl: './recharge-customer-editor.component.html',
  styleUrls: ['./recharge-customer-editor.component.scss']
})

export class RechargeCustomerEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  responseMessage: string;
  actionMessage: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _rechargeCustomerService: RechargeCustomerService,
    private _inventoryService: InventoryService,
    public dialogRef: MatDialogRef<RechargeCustomerEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
      
  }

  async ngOnInit(): Promise<void> {
    this.selectedProductForm = this._formBuilder.group({
        first_name: ['', [Validators.required, Validators.minLength(4)]],
        last_name: ['', [Validators.required, Validators.minLength(4)]],
        email: ['', [Validators.required]],
        billing_address1: [''],
        billing_address2: [''],
        billing_city: [''],
        billing_company: [''],
        billing_country: [''],
        billing_province: [''],
        billing_phone: [''],
        billing_zip: [''],
        id: ['', [Validators.required]],
        shopify_customer_id: ['', [Validators.required]],
    });
  }

  
  

  closeDialog(value = false) {
    this.dialogRef.close(value);
  }

  showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialog();
      }
      this.flashMessage = null;
      // Mark for check
      this._changeDetectorRef.markForCheck();

    }, 3000);
  }


  async addRechargeCustomer() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
    const picked = this.selectedProductForm.getRawValue();
    
    let newCustomer = new Customer();
    newCustomer.first_name = picked.first_name;
    newCustomer.last_name = picked.last_name;
    newCustomer.email = picked.email;
    newCustomer.billing_address1 = picked.billing_address1;
    newCustomer.billing_address2 = picked.billing_address2;
    newCustomer.billing_city = picked.billing_city;
    newCustomer.billing_company = picked.billing_company;
    newCustomer.billing_country = picked.billing_country;
    newCustomer.billing_province = picked.billing_province;
    newCustomer.billing_phone = picked.billing_phone;
    newCustomer.billing_zip = picked.billing_zip;
    newCustomer.id = picked.id;
    newCustomer.shopify_customer_id = picked.shopify_customer_id;

    let newCustomerData = new CustomerData();
    newCustomerData.customer = newCustomer;

    this.saving = true;
    let res = await firstValueFrom(this._inventoryService.getAccountByUserEmail(picked.email));
    if (res.message === undefined){

        let newRechargeCustomer = new RechargeCustomer();
        newRechargeCustomer.accountId = res.id;
        newRechargeCustomer.customerData = newCustomerData;

        this._rechargeCustomerService.addRechargeCustomer(newRechargeCustomer).subscribe({
            next: (e: any) => {
            if (e.id) {
                this.actionMessage = 'Recharge Customer Added';
                this.showFlashMessage('success');
            } else {
                this.showFlashMessage('error');
            }
            }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
            })
        });
         
    }
    else{
        this.responseMessage = 'Unable to find account using given email id';
        this.showFlashMessage('error');
    }

    }
  }


}
