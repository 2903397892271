import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DocumentsService} from '../documents.service';
import {Documents, DocumentTypes} from './../documents';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';


@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-documents-editor',
  templateUrl: './documents-editor.component.html',
  styleUrls: ['./documents-editor.component.scss']
})
export class DocumentsEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  document: Documents;
  responseMessage: string;
  actionMessage: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _documentsService: DocumentsService,
    public dialogRef: MatDialogRef<DocumentsEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private closeDialogComponent: CloseDialogComponent,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
      
  }


  async ngOnInit(): Promise<void> {   
    this.selectedProductForm = this._formBuilder.group({        
        text: ['', [Validators.required]],
        documentType: ['', [Validators.required]],
    });
  }
  
  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 1500);
  }

  public get DocumentTypesValues() {
    return DocumentTypes;
  }


  saveDocument() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
        const picked = this.selectedProductForm.getRawValue();
      
        let doc = new Documents();
        doc.text = picked.text;        
        this.saving = true;

        if (picked.documentType === 'TermsConditions'){
          this._documentsService.addTCDocument(doc).subscribe({
            next: (e: any) => {
            if (e.id) {
                this.actionMessage = 'Document added';
                this.showFlashMessage('success');
            } else {
                this.showFlashMessage('error');
            }
            }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
            })
            });
        }
        else{
          this._documentsService.addPrivacyPolicyDocument(doc).subscribe({
            next: (e: any) => {
            if (e.id) {
                this.actionMessage = 'Document added';
                this.showFlashMessage('success');
            } else {
                this.showFlashMessage('error');
            }
            }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
            })
            });
        }
       
        
    }
  }


}
