import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Signal} from '../../signals/signal';
import {Tag} from '../../tags/tags';
import {OffersService} from '../../offers/offers.service';
import {TagsService} from '../../tags/tags.service';
import {SignalsService} from '../../signals/signals.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Blend} from '../../offers/blend';
import {Category} from '../../categories/category';
import {PlaybackModes, SleepMode} from '../../playback-modes';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-edit-blend',
  templateUrl: './edit-blend.component.html',
  styleUrls: ['./edit-blend.component.scss']
})
export class EditBlendComponent implements OnInit {
  selectedProductForm: FormGroup;
  imagesForm: FormGroup;

  flashMessage: 'success' | 'error' | null = null;

  signals: Signal[];
  tags: Tag[];
  blend: Blend;
  categories: Category[];
  saving: boolean = false;
  responseMessage: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _blendsService: OffersService,
    private _tagsService: TagsService,
    private _signalsService: SignalsService,
    public dialogRef: MatDialogRef<EditBlendComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private closeDialogComponent: CloseDialogComponent,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.tags = data.tags;
    this.signals = data.signals;
    this.blend = data.blend;
    this.categories = data.categories;

  }

  ngOnInit(): void {
    this.imagesForm = this._formBuilder.group({
      imageFile: [''],
      iconFile: [''],
      herobannerFile: [''],
      imageFileData: [''],
      iconFileData: [''],
      herobannerFileData: [''],
      iconWithNoBGFile: [''],
      smallIconWithBGFile: [''],
      iconWithBGFile: [''],
      squareImageFile: [''],
      iconWithNoBGFileData: [''],
      smallIconWithBGFileData: [''],
      iconWithBGFileData: [''],
      squareImageFileData: [''],
    });

    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      subTitle: ['', [Validators.required, Validators.minLength(4)]],
      sleepMode: [''],
      isPubliclyAvailable: [''],
      isHidden: [''],
      playbackId: ['', [Validators.required]],
      categoryId: ['', [Validators.required]],
      sortOrder: ['', [Validators.required]],
      tags: [''],
      imageUrl: [''],
      iconUrl: [''],
      heroBannerUrl: [''],
      iconWithNoBGUrl: [''],
      smallIconWithBGUrl: [''],
      iconWithBGUrl: [''],
      squareImageUrl: [''],
      description: ['', [Validators.required]],
      shortDescription: ['', [Validators.required]],
      contentDescription: ['', [Validators.required]],
      signalIds: ['', [Validators.required]]
    });

    this.selectedProductForm.get('name').setValue(this.blend.name);
    this.selectedProductForm.get('subTitle').setValue(this.blend.subTitle);
    this.selectedProductForm.get('sleepMode').setValue(this.blend.playbackModes.sleepMode.isEnabled);
    this.selectedProductForm.get('isPubliclyAvailable').setValue(this.blend.isPubliclyAvailable);
    this.selectedProductForm.get('isHidden').setValue(this.blend.isHidden);
    this.selectedProductForm.get('playbackId').setValue(this.blend.playbackId);
    this.selectedProductForm.get('categoryId').setValue(this.blend.categoryId);

    this.selectedProductForm.get('sortOrder').setValue(this.blend.sortOrder);
    this.selectedProductForm.get('tags').setValue(this.blend.tags);
    this.selectedProductForm.get('imageUrl').setValue(this.blend.imageUrl);
    this.selectedProductForm.get('iconUrl').setValue(this.blend.iconUrl);
    this.selectedProductForm.get('heroBannerUrl').setValue(this.blend.heroBannerUrl);

    this.selectedProductForm.get('iconWithNoBGUrl').setValue(this.blend.iconWithNoBGUrl);
    this.selectedProductForm.get('smallIconWithBGUrl').setValue(this.blend.smallIconWithBGUrl);
    this.selectedProductForm.get('iconWithBGUrl').setValue(this.blend.iconWithBGUrl);
    this.selectedProductForm.get('squareImageUrl').setValue(this.blend.squareImageUrl);

    this.selectedProductForm.get('description').setValue(this.blend.description);
    this.selectedProductForm.get('shortDescription').setValue(this.blend.shortDescription);
    this.selectedProductForm.get('contentDescription').setValue(this.blend.contentDescription);
    this.selectedProductForm.get('signalIds').setValue(this.blend.signalIds);
  }

  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 1500);
  }

  saveBlend() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const blend: Blend = this.selectedProductForm.getRawValue();

      blend.playbackModes = new PlaybackModes();
      blend.playbackModes.sleepMode = new SleepMode();
      blend.playbackModes.sleepMode.isEnabled = blend['sleepMode'];
      blend.isPubliclyAvailable = blend['isPubliclyAvailable'];
      blend.isHidden = blend['isHidden'];
      delete blend['sleepMode'];

      this.saving = true;

      this._blendsService.saveBlend(this.blend.id, blend).subscribe({
        next: async (e: any) => {
          if (e.data.id) {

            const images = this.imagesForm.getRawValue();
            if (images['herobannerFile'] === '') delete images['herobannerFile'];
            if (images['imageFile'] === '') delete images['imageFile'];
            if (images['iconFile'] === '') delete images['iconFile'];

            if (images['iconWithNoBGFile'] === '') delete images['iconWithNoBGFile'];
            if (images['smallIconWithBGFile'] === '') delete images['smallIconWithBGFile'];
            if (images['iconWithBGFile'] === '') delete images['iconWithBGFile'];
            if (images['squareImageFile'] === '') delete images['squareImageFile'];

            await this._blendsService.delay() 
            this._blendsService.uploadBlendImages(e.data.id, images).subscribe({
                next: (e: any) => {
                  if (e) {
                    this.showFlashMessage('success');
                  } else {
                    this.saving = false;
                    this.showFlashMessage('error');
                  }
                },
                error: (err) => {
                  this.responseMessage = err.error.message;
                  this.saving = false;

                  this.showFlashMessage('error');
                }
              }
            );
          } else {
            this.saving = false;

            this.showFlashMessage('error');
          }
        }, error: (err => {
          this.responseMessage = err.error.message;
          this.saving = false;

          this.showFlashMessage('error');
        })
      });
    }
  }

  uploadHerobannerFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        herobannerFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        herobannerFileData: file,
      });
      this.imagesForm.get('herobannerFile').updateValueAndValidity();
    }
  }

  uploadImageFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        imageFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        imageFileData: file,
      });
      this.imagesForm.get('imageFile').updateValueAndValidity();
    }

  }

  uploadIconFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        iconFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        iconFileData: file,
      });
      this.imagesForm.get('iconFile').updateValueAndValidity();
    }

  }

  uploadIconWithNoBGFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        iconWithNoBGFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        iconWithNoBGFileData: file,
      });
      this.imagesForm.get('iconWithNoBGFile').updateValueAndValidity();
    }

  }

  uploadSmallIconWithBGFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        smallIconWithBGFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        smallIconWithBGFileData: file,
      });
      this.imagesForm.get('smallIconWithBGFile').updateValueAndValidity();
    }

  }

  uploadIconWithBGFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        iconWithBGFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        iconWithBGFileData: file,
      });
      this.imagesForm.get('iconWithBGFile').updateValueAndValidity();
    }

  }

  uploadSquareImageFile($event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        squareImageFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        squareImageFileData: file,
      });
      this.imagesForm.get('squareImageFile').updateValueAndValidity();
    }

  }

  
}
