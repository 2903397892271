import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SignalsService} from '../signals.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Signal} from '../signal';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-edit-signal',
  templateUrl: './edit-signal.component.html',
  styleUrls: ['./edit-signal.component.scss']
})
export class EditSignalComponent implements OnInit {

  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  responseMessage: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _signalsService: SignalsService,
    public dialogRef: MatDialogRef<EditSignalComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private closeDialogComponent: CloseDialogComponent,
    @Inject(MAT_DIALOG_DATA) public data: Signal,

  ) { }

  ngOnInit(): void {
    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      hardwareId: ['', [Validators.required]],
      file: [''],
      fileData: [''],
    });

    this.selectedProductForm.controls.name.setValue(this.data.name);
    this.selectedProductForm.controls.hardwareId.setValue(this.data.hardwareId);

  }

  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 1500);
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.selectedProductForm.patchValue({
      fileData: file,
    });
    this.selectedProductForm.get('fileData').updateValueAndValidity();
  }

  updateSignal() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const signal = this.selectedProductForm.getRawValue();

      let formData: any = new FormData();
      formData.append('name', signal.name);
      formData.append('hardwareId', signal.hardwareId);
      formData.append('file', signal.fileData);

      this.saving = true;

      this._signalsService.updateSignal(this.data.id, formData).subscribe({
        next: (e: any) => {
          if (e.data.id) {
            this.showFlashMessage('success');
          } else {
            this.showFlashMessage('error');
          }
        }, error: (err => {
          this.responseMessage = err.error.message;
          this.showFlashMessage('error');
        })
      });
    }
  }

}
