import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {DeviceItem} from '../account.devices';
import {InventoryService} from "app/modules/admin/apps/accounts/inventory/inventory.service";
import { firstValueFrom } from 'rxjs';
import { MobileDetails, UpdateAccount, Integration } from '../account.profile';
import { NotesDetails } from '../account.notes';
import {FuseConfirmationService} from '@fuse/services/confirmation';
import { SharedAlertComponent } from '../../shared-alert/shared-alert.component';
import { NotesEditorComponent } from '../notes/notes.component';
import { SharedDataService } from '../../routines/shared.data.service';
import { ProfileEditorComponent } from '../profile/profile.component';
import { UsageDetails } from '../account.usagelog';
import { Blend } from '../../offers/blend';
import { Routine } from '../../routines/routine';
import { RoutinesService } from '../../routines/routines.service';
import { saveAs } from 'file-saver';
import { AuthLogDetails, AppLogDetails } from '../account.auth0log';
import { MembershipLevel } from '../../membership-levels/membership-levels';
import { MembershipLevelService } from '../../membership-levels/membership-levels.service';
import { CustomerMembershipEditorComponent } from '../customermembership/customermembership.component';
import { CategoriesService } from '../../categories/categories.service';
import { Category } from '../../categories/category';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss']
})
export class UserSummaryComponent implements OnInit{
  isLoading: boolean = false;
  deviceItems: DeviceItem[] = [];
  blends: Blend[] = [];
  routines: Routine[] = [];
  product: any;
  email: string;
  playbackCount: any;
  hasProfile: boolean;
  hasAuth: boolean;
  accountCreationDate: string;
  mobileDetails: MobileDetails[] = [];
  notesDetails: NotesDetails[] = [];
  usageDetails: UsageDetails[] = [];
  fullUsageDetails: UsageDetails[] = [];
  slicedUsageDetails: UsageDetails[] = [];
  authlogDetails: AuthLogDetails[] = [];
  fullauthlogDetails: AuthLogDetails[] = [];
  fullDevicelogDetails: AppLogDetails[] = [];
  DevicelogDetails: AppLogDetails[] = [];
  fullApplogDetails: AuthLogDetails[] = [];
  fullIntegrationDetails: any;
  membershiplevels: MembershipLevel[];
  customerMemberships: MembershipLevel[] = [];
  integration: Integration[] = [];
  roles: string[] = [];
  accountLock: boolean;
  isProfileExists: boolean;
  profilePlaylist: Routine[] = [];
  profileId: string;
  categories: Category[];
  userCategory: string;
 

  constructor(
    public dialogRef: MatDialogRef<UserSummaryComponent>,
    private _inventoryService: InventoryService,
    private _membershipLevelService: MembershipLevelService,
    private _routinesService: RoutinesService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _categoriesService: CategoriesService,
    public dialog: MatDialog,
    private sharedDataService: SharedDataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.product = data;
    this.email = data.email;
  }
    

  async ngOnInit(){
    this.isLoading = true;
    let result = await firstValueFrom(this._inventoryService.viewDevices(this.product.id)); 
    this.deviceItems = result.data.associatedDevices;
    this.hasAuth = result.data.hasAuth0;
    this.hasProfile = result.data.hasProfile;
    this.accountCreationDate = result.data.accountCreationDate.split("T")[0];

    await this.getProfileByEmail(this.email);
    await this.getRoles();
    await this.getAccountLockStatus();
    this.isLoading = false;
  }

  async getAccountLockStatus(){
    try{
      let res = await firstValueFrom(this._inventoryService.getAccountLock(this.product.id));
      this.accountLock = res.accountLocked;
    }
    catch(e:unknown){
    }
  }


  async getRoles(){
    try{
      let res = await firstValueFrom(this._inventoryService.getAccountRoles(this.product.email));
      if (res.message === null || res.message === undefined){
        this.roles = res.roles;
      }
    }
    catch(e:unknown){

    }
  }

  async getMobileDeviceDetails(){
    try{
      if (this.product.profileId !== null){
        let mobileResult = await firstValueFrom(this._inventoryService.getProfile(this.product.profileId));
        if (mobileResult.message === null){
          this.mobileDetails = mobileResult.data.mobileDeviceIdAndTimeZones;
        }
      }      
    }
    catch(e:unknown){

    }
  }

  downloadFile() {
    const items = this.fullUsageDetails;
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(items[0]);
    let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, this.product.id + ".csv");
  }

  downloadDeviceLogFile() {
    var blob = new Blob([JSON.stringify(this.fullDevicelogDetails)], {
      type: 'application/json'
    });
    saveAs(blob, this.product.id + ".txt");
  }

  downloadAppLogFile() {
    var blob = new Blob([JSON.stringify(this.fullApplogDetails)], {
      type: 'application/json'
    });
    saveAs(blob, this.product.id + ".txt");
  }



  async getUsageLog() {
    this.blends = await this._membershipLevelService.getBlends();
    this.routines = await this._routinesService.getRoutines();

    let usageResult = await firstValueFrom(this._inventoryService.getAccountUsageLog(this.product.id));
    if (usageResult.message === null || usageResult.message === undefined){  
      await usageResult.sort((a, b) => a.timestamp > b.timestamp);
      this.usageDetails = usageResult;
      this.fullUsageDetails = usageResult;
      this.usageDetails = this.usageDetails.filter(t => t.usageEventType === 'PlayStart');
      this.playbackCount = this.usageDetails.length;
      await this.usageDetails.reverse();
      for (let usageDetail of this.usageDetails) {
        if(usageDetail.signalBundleId!==null){
          try{usageDetail.signalBundleName = this.blends.find(c => c.id === usageDetail.signalBundleId).name;}
          catch(e:unknown){
            usageDetail.signalBundleName = 'NA';
          }
        }

        if(usageDetail.playlistId!==null){
          try{usageDetail.playlistName = this.routines.find(c => c.id === usageDetail.playlistId).name;}
          catch(e:unknown){
            usageDetail.playlistName = 'NA';
          }
        }

        usageDetail.timestamp = usageDetail.timestamp.split("T")[0];      
        
      }

      this.slicedUsageDetails = this.usageDetails.slice(0,25);
    }
    else{
      this.usageDetails = [];
    }

    
    

  }

  async getCustomerMemberships() {
    this.membershiplevels = await this._membershipLevelService.getMembershipLevels(1, 100);
   
    let accountDetails = await firstValueFrom(this._inventoryService.getAccountbyId(this.product.id));

    if (accountDetails.message === null || accountDetails.message === undefined){
      this.customerMemberships = accountDetails.data.availableMemberships;
    }
    else{
      this.customerMemberships = [];
    }
  }

  async getProfilePlaylist() {
    this.categories = await this._categoriesService.getCategories();
    this.blends = await this._membershipLevelService.getBlends();

    this.profilePlaylist = await this._routinesService.getProfilePlaylist(this.profileId);
    this.profilePlaylist.sort((a, b) => a.sortOrder - b.sortOrder);

    for (let pp of this.profilePlaylist) {
      pp.category = this.categories.find(c => c.id === pp.categoryId);

      for (let blendItem of pp.items) {
        blendItem.blend = this.blends.find(c => c.id === blendItem.signalBundleId);        
      }
    }
  }


  async getAuthLog() {
    let logResult = await firstValueFrom(this._inventoryService.getAccountAuthLog(this.product.id));
    if (logResult.message === null || logResult.message === undefined){
      this.fullauthlogDetails = logResult.data;
      this.authlogDetails = this.fullauthlogDetails.slice(0,10);
    }
    else{
      this.authlogDetails = [];
    }   

  }

  async getIntegration() {
    let result = await firstValueFrom(this._inventoryService.getIntegrationByProfile(this.product.profileId));
    if (result !== undefined)
    {
      this.integration = result.data
      for (let item of this.integration) {
        item.lastSuccessfulSync = item.lastSuccessfulSync.split("T")[0];             
      }
    }
    else
    {
      this.integration = [];
    }
  }

  async downloadIntegrationDetailsFile(item) {
    let integrationDetails = await firstValueFrom(this._inventoryService.getIntegrationDetails(item.id));
    if (integrationDetails.message === null || integrationDetails.message === undefined){
      this.fullIntegrationDetails = integrationDetails;   
      var blob = new Blob([JSON.stringify(this.fullIntegrationDetails)], {
        type: 'application/json'
      });
      saveAs(blob, item.id + ".txt");   
    }
    else{
      this.fullIntegrationDetails = [];
      this.dialog.open(SharedAlertComponent,
      {
        width: 'auto',
        disableClose: false,
        data: {dialogMessage: `Integration details are not available for ${item.integrationName}`}
      });
    }   
    
  }

  async getDeviceLog() {
    let logResult = await firstValueFrom(this._inventoryService.getDeviceLog(this.product.id));
    if (logResult.message === null || logResult.message === undefined){
      this.fullDevicelogDetails = logResult;    
      this.DevicelogDetails = this.fullDevicelogDetails.slice(0,10);  
    }
    else{
      this.fullDevicelogDetails = [];
    }   

  }

  async getAppLog() {
    let logResult = await firstValueFrom(this._inventoryService.getAppLog(this.product.id));
    if (logResult.message === null || logResult.message === undefined){
      this.fullApplogDetails = logResult;      
    }
    else{
      this.fullApplogDetails = [];
    }   

  }

  async getNotes() {
    try{
      let notesResult = await firstValueFrom(this._inventoryService.getAccountNotes(this.product.id)); 
      if (notesResult.message === null){
        this.notesDetails = notesResult.data;
      }
      else{
        this.notesDetails = [];
      }
    }
    catch(e:unknown){
      this.notesDetails = [];
    }
    
  }

  private async getProfileByEmail(email: string) {
    let res = await firstValueFrom(this._inventoryService.getProfileByUserEmail(email)); 
    if (res.message === undefined){
      this.isProfileExists = true;   
      this.profileId = res.id;  
      this.userCategory = res.category;
    }
    else{
      this.isProfileExists = false;
    }
  }


  closeDialog(value = false) {
    this.dialogRef.close(value);
  }


  editNote(note) {
    this.sharedDataService.setData(note);
    let dialogref = this.dialog.open(NotesEditorComponent,
      {
        width: '500px',
        disableClose: true,
        data: {note: note, productId: this.product.id}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getNotes();
        this.isLoading = false;
      }
      
    });
  }

  viewNote(note){
    this.sharedDataService.setData(note);
    this.sharedDataService.setViewDataFlag(true);
    let dialogref = this.dialog.open(NotesEditorComponent,
      {width: '500px', height: 'auto', disableClose: true, data:{note: note, productId: this.product.id}});
  }

  addNote() {
    let dialogref = this.dialog.open(NotesEditorComponent,
      {
        width: '500px',
        disableClose: true,
        data: {note: null, productId: this.product.id}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getNotes();
        this.isLoading = false;
      }
    });
  }


  addCustomerMembership() {
    let dialogref = this.dialog.open(CustomerMembershipEditorComponent,
      {
        width: '500px',
        disableClose: true,
        data: {productId: this.product.id, _membershiplevels: this.membershiplevels}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getCustomerMemberships();
        this.isLoading = false;
      }
    });
  }


  async deleteCustomerMembership(custMembership) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this membership?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }
      (await this._inventoryService.deleteCustomerMembership(this.product.id, custMembership.id)).subscribe({
        next: async (e: any) => {
          if (e.modified) {
            this.isLoading = true;
            await this.getCustomerMemberships();
            this.isLoading = false;
            this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage:'Membership is deleted successfully'}
            });
          } 
        }, error: (err => {
           this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage: err.error.message}
            });
        })
      });
    });

  }

  addProfile() {
    let dialogref = this.dialog.open(ProfileEditorComponent,
      {
        width: '500px',
        disableClose: true,
        data: {product: this.product}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.linkProfileToAccount();
        this.isProfileExists = true;
      }
    });
  }

  async linkProfile() {
    this._fuseConfirmationService.open({message: 'Are you sure you want to link profile?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }
      await this.linkProfileToAccount();
    });
    
  }

  async linkProfileToAccount(){
    let result = await firstValueFrom(this._inventoryService.getProfileByEmail(this.product.email));
      let profileId = null;
      if (result.id !== null && result.statusCode === undefined){
        profileId = result.id;
        let newUpdateAccount = new UpdateAccount();
        newUpdateAccount.profileId = profileId;

        (await this._inventoryService.updateAccount(this.product.id, newUpdateAccount)).subscribe({
          next: async (e: any) => {
            if (e.message === undefined) {
              this.isLoading = true;  
              this.hasProfile = true;            
              this.dialog.open(SharedAlertComponent,
              {
                width: 'auto',
                disableClose: false,
                data: {dialogMessage:'Profile is linked successfully.'}
              });
            } 
          }, error: (err => {
             this.dialog.open(SharedAlertComponent,
              {
                width: 'auto',
                disableClose: false,
                data: {dialogMessage: "Unable to link profile. Please contact engineering team."}
              });
          })
        });
      }
      else{
        this.dialog.open(SharedAlertComponent,
          {
            width: 'auto',
            disableClose: false,
            data: {dialogMessage: "Unable to find profile for this account. Please contact engineering team."}
          });
      }
  }

  async removeNote(note) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Note?'}).afterClosed().subscribe(async e => {
      if (e !== 'confirmed') {
        return;
      }
      (await this._inventoryService.deleteAccountNotes(this.product.id, note.id)).subscribe({
        next: async (e: any) => {
          if (e.message === null) {
            this.isLoading = true;
            await this.getNotes();

            this.isLoading = false;
            this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage:'Note is deleted successfully'}
            });
          } 
        }, error: (err => {
           this.dialog.open(SharedAlertComponent,
            {
              width: 'auto',
              disableClose: false,
              data: {dialogMessage: err.error.message}
            });
        })
      });
    });

  }

  
}