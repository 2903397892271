import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class SharedDataService {

  constructor(
    private router:Router
  ) { }

  private data;
  private isViewingData: boolean;

  setData(data){
    this.data = data;
  }

  getData(){
    let temp = this.data;
    this.clearData();
    return temp;
  }

  clearData(){
    this.data = undefined;
  }

  setViewDataFlag(state){
    this.isViewingData = state;
  }

  getViewDataFlag(){
    let temp = this.isViewingData;
    this.isViewingData = undefined;
    return temp;
  }

}