<div mat-dialog-content>
  <div class="shadow-lg overflow-hidden">
    <div class="flex border-b">
      <!-- Selected product form -->
      <form
        class="flex flex-col w-full"
        [formGroup]="selectedProductForm"
      >

        <mat-form-field class="pr-2">
          <mat-label>Title</mat-label>
          <input matInput [formControlName]="'title'"/>
        </mat-form-field>

        <div class="flex">


          <mat-form-field class="pr-2">
            <mat-label>Sort Order</mat-label>
            <input matInput type="number" [formControlName]="'sortOrder'" max=2147483647/>
          </mat-form-field>

          <mat-form-field class="pr-2">
            <mat-label>Show in search</mat-label>
            <mat-select [formControlName]="'showInSearch'" >
              <mat-option [value]="true"> Yes </mat-option>
              <mat-option [value]="false"> No </mat-option>
            </mat-select>
          </mat-form-field>
        </div>



        <div mat-dialog-actions>
          <div
            class="flex items-center justify-between w-full border-t px-8 py-4"
          >
          <app-close-dialog></app-close-dialog>
            <div class="flex items-center">
              <div
                class="flex items-center mr-4"
                *ngIf="flashMessage"
              >
                <ng-container
                  *ngIf="flashMessage === 'success'"
                >
                  <mat-icon
                    class="text-green-500"
                    [svgIcon]="'heroicons_outline:check'"
                  ></mat-icon>
                  <span class="ml-2">Tag Added</span>
                </ng-container>
                <ng-container *ngIf="flashMessage === 'error'">
                  <mat-icon
                    class="text-red-500"
                    [svgIcon]="'heroicons_outline:x'"
                  ></mat-icon>
                  <span class="ml-2"
                  >{{responseMessage}}</span
                  >
                </ng-container>
              </div>
              <button

                mat-flat-button
                [color]="'primary'"
                (click)="addTag()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
