export class Signal {
  id: string;
  hardwareId: number;
  name: string;
  playbackIntensities: PlayBackIntensities[];
}

export class PlayBackIntensities {
  signalId: string;
  hardwareId: string;
  intensity: string;
}
