import { Component, OnInit } from '@angular/core';
import {Signal} from './signal';
import {SignalsService} from './signals.service';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";
import { SignalEditorComponent } from './signal-editor/signal-editor.component';
import { SignalIntensityEditorComponent } from './signal-intensity-editor/signal-intensity-editor.component';

@Component({
  selector: 'app-signals',
  templateUrl: './signals.component.html',
  styleUrls: ['./signals.component.scss']
})
export class SignalsComponent implements OnInit {
  isLoading: boolean = false;
  signals: Signal[] = [];
  fullSignals: Signal[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  query = "";
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _signalsService: SignalsService,
    private _titleService: Title,
    private _auth: AuthServiceClass,

    public dialog: MatDialog

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Signals');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:signal')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:signal');
    this.canEdit = perms.includes('update:signal');
    this.canDelete = perms.includes('delete:signal');

    await this.getSignals();
    this.fullSignals = this.signals;

    // Subscribe to search input field value changes
    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        switchMap(async (query) => {
          this.isLoading = true;
          this.query = query.trim();
          if(this.query){
            this.signals = this.signals.filter(o => o.name.toLowerCase().includes(this.query.toLowerCase()));
          }
          else{
            this.signals = this.fullSignals;
          }

        }),
        map(() => {
          this.isLoading = false;
        })
      )
      .subscribe();

  }


  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.signals = this.fullSignals;
    this.isLoading = false;
  }
 

  async onTableDataChange(event: any){
    this.page = event;
    await this.signals;
  }


  async getSignals() {
    this.signals = await this._signalsService.getSignals();
    this.signals.sort(function(a, b) {
      return (a.hardwareId < b.hardwareId) ? -1 : ((a.hardwareId > b.hardwareId) ? 1 : 0);
    });
  }

  add() {
    let dialogref = this.dialog.open(SignalEditorComponent, {disableClose: true, data: {signal: null}});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getSignals();
      }
    });
  }

  addIntensity() {
    let dialogref = this.dialog.open(SignalIntensityEditorComponent, {disableClose: true, data: {signal: null}});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getSignals();
      }
    });
  }

  edit(signal) {
    let dialogref = this.dialog.open(SignalEditorComponent, 
    {
      width: '800px',
      disableClose: true, 
      data: {signal: signal}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getSignals();
      }
      
    });
  }

  editIntensity(signal) {
    let dialogref = this.dialog.open(SignalIntensityEditorComponent, 
    {
      width: '800px',
      disableClose: true, 
      data: {signal: signal}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        await this.getSignals();
      }
      
    });
  }

  remove(signal) {
    
  }
}
