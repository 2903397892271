import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map,  tap} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import { InventoryPagination} from "app/modules/admin/apps/accounts/inventory/inventory.types";
import {Observable} from "rxjs";
import { Feature } from './feature';


@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  private count: number;
  private _pagination: InventoryPagination;
  constructor(
    private _httpClient: HttpClient
  ) {
  }

  get pagination(): InventoryPagination {
    return this._pagination;
  }


  getFeatures(page: number, size: number): Promise<Feature[]> {
    return firstValueFrom(this._httpClient
      .get<Feature[]>(
        `${environment.opsapi}/v5/featureflag/paged?itemsPerPage=${size}&pageNumber=${page}`
      )
      .pipe(
        map((entry) => entry["records"])       
      ));
  }

  addFeature(feature: Feature) {
    return this._httpClient.post(`${environment.opsapi}/v4/featureflag`, feature);
  }

  updateFeature(id: string, feature: Feature) {
    return this._httpClient.put(`${environment.opsapi}/v4/featureflag/${id}`, feature);
  }


  async removeFeature(id): Promise<any> {
    return firstValueFrom(this._httpClient.delete(`${environment.opsapi}/v4/featureflag/${id}`));

  }

  getFeatureById(feature: Feature) {
    return this._httpClient.get(`${environment.opsapi}/v4/featureflag/${feature.id}`);
  }

  
}
