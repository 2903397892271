import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map, of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Blend} from './blend';
import {Offer} from './offer';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(
    private _httpClient: HttpClient
  ) {
  }

  async getBlends(): Promise<Blend[]> {
    await new Promise(f => setTimeout(f, 1000));
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/signalBundles/`)
        .pipe(
          map((entry) => entry["data"])
        )
    );
  }

  getOffers(): Promise<Offer[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/Offers/All`)
        .pipe(
          map((entry) => entry["offers"])
        )
    );
  }

  retire(id: string): Promise<any> {
    return firstValueFrom(
      this._httpClient.put(`${environment.opsapi}/v4/Offers/${id}/retire`, null)
    );
  }

  base(id: string): Promise<any> {
    return firstValueFrom(
      this._httpClient.put(`${environment.opsapi}/v4/Offers/${id}/base`, null)
    );
  }

  deleteOffer(id: string){
    return this._httpClient.delete(`${environment.opsapi}/v4/Offers/${id}`);
  }

  activate(id: string): Promise<any> {

    return firstValueFrom(
      this._httpClient.put(`${environment.opsapi}/v4/Offers/${id}/activate`, null)
    );
  }

  addBlend(blend: any) {
    // blend.playbackModes = {sleepMode: {isEnable: false}};
    if (!blend.subTitle) {
      blend.subTitle = '';
    }
    // @ts-ignore
    if (!Array.isArray(blend.tags) || blend['tags'] === '') blend.tags = [];

    return this._httpClient.post(`${environment.opsapi}/v4/signalBundles/`, blend);
  }

  saveBlend(id: string, blend: Blend) {
    //  blend.playbackModes = {sleepMode: {isEnable: false}};
    if (!blend.subTitle) {
      blend.subTitle = '';
    }
    // @ts-ignore
    if (!Array.isArray(blend.tags) || blend['tags'] === '') blend.tags = [];

    return this._httpClient.put(`${environment.opsapi}/v4/signalBundles/${id}`, blend);

  }

  async delay(){
    await new Promise(f => setTimeout(f, 2000));
  }

  uploadBlendImages(id, images: any) {
    let formData: any = new FormData();
    if (images.herobannerFileData) {
      formData.append('herobannerFile', images.herobannerFileData);
    }
    if (images.imageFileData) {
      formData.append('imageFile', images.imageFileData);
    }
    if (images.iconFileData) {
      formData.append('iconFile', images.iconFileData);
    }

    if (images.iconWithNoBGFileData) {
      formData.append('iconWithNoBGFile', images.iconWithNoBGFileData);
    }
    if (images.smallIconWithBGFileData) {
      formData.append('smallIconWithBGFile', images.smallIconWithBGFileData);
    }
    if (images.iconWithBGFileData) {
      formData.append('iconWithBGFile', images.iconWithBGFileData);
    }
    if (images.squareImageFileData) {
      formData.append('squareImageFile', images.squareImageFileData);
    }

    if (images.herobannerFileData || images.imageFileData || images.iconFileData || images.iconWithNoBGFileData || images.smallIconWithBGFileData
      || images.iconWithBGFileData || images.squareImageFileData
    ) {
      return this._httpClient.put(`${environment.opsapi}/v4/signalBundles/${id}/upload`, formData);
    } else {
      return of(true);
    }
  }

  deleteBlend(id) {
    return this._httpClient.delete(`${environment.opsapi}/v4/signalBundles/${id}`);

  }

  newOffer(offer: Offer) {
    // offer['isBase'] = false;
    // offer['retired'] = false;
    // offer['newSelectionsAvailable'] = false;
    // offer['newSelectionsRequired'] = false;
    // offer['version'] = 0;
    // offer['availableSelections'] = offer.signalBundleIds.length;
    // @ts-ignore
    if (!Array.isArray(offer.playlistIds) || offer['playlistIds'] === '') offer.playlistIds = [];

    return this._httpClient.post(`${environment.opsapi}/v4/Offers/Create`, offer);

  }

  saveOffer(id: string, offer: Offer) {
    // @ts-ignore
    if (!Array.isArray(offer.playlistIds) || offer['playlistIds'] === '') offer.playlistIds = [];

    return this._httpClient.put(`${environment.opsapi}/v4/Offers/${id}`, offer);

  }
}
