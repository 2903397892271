
<div mat-dialog-content>
  <div class="shadow-lg overflow-hidden">
    <div class="flex border-b">
      <!-- Selected product form -->
      <form
        class="flex flex-col w-full"
        [formGroup]="selectedProductForm"
      >


        <div fxLayout="row">

          <mat-form-field class="pr-2" fxFlex="33">
            <mat-label>Blend</mat-label>
            <mat-select [formControlName]="'blend'">
              <mat-option *ngFor="let blend of blends" [value]="blend.id">
                {{ blend.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="pr-2" fxFlex="33">
            <mat-label>Sort Order</mat-label>
            <input matInput type="number" [formControlName]="'sortOrder'"/>
          </mat-form-field>

          <mat-form-field class="pr-2" fxFlex="33">
            <mat-label>Sleeppad Haptics</mat-label>
            <mat-select [formControlName]="'sleeppadhaptics'">
              <mat-option [value]="SleepPadHapticsValues.none">
                {{ SleepPadHapticsValues.none }}
              </mat-option>
              <mat-option [value]="SleepPadHapticsValues.one">
                {{ SleepPadHapticsValues.one }}
              </mat-option>
              <mat-option [value]="SleepPadHapticsValues.two">
                {{ SleepPadHapticsValues.two }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field class="pr-2" fxFlex="50">
            <mat-label>Hours (0-24)</mat-label>
            <input matInput type="number"  min="0" max="24" [formControlName]="'hours'"/>

          </mat-form-field>
          <mat-form-field class="pr-2" fxFlex="50">
            <mat-label>Minutes (0-59)</mat-label>
            <input matInput type="number"   min="0" max="59" [formControlName]="'minutes'"/>
          </mat-form-field>

        </div>

        <div fxLayout="row">
          <mat-form-field class="pr-2" fxFlex="50">
            <mat-label>Haptics</mat-label>
            <mat-select [formControlName]="'haptics'">
              <mat-option [value]="HapticsValues.none">
                {{ HapticsValues.none }}
              </mat-option>
              <mat-option [value]="HapticsValues.one">
                {{ HapticsValues.one }}
              </mat-option>
              <mat-option [value]="HapticsValues.two">
                {{ HapticsValues.two }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="pr-2" fxFlex="50">
            <mat-label>Light Mode</mat-label>
            <mat-select [formControlName]="'lightMode'">
              <mat-option [value]="LightModeValues.breathe">
                {{ LightModeValues.breathe}}
              </mat-option>
              <mat-option [value]="LightModeValues.static">
                {{ LightModeValues.static }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field class="pr-2" fxFlex="25">
            <mat-label>Brightness (0-255)</mat-label>
            <input matInput type="number"   min="0" max="255" [formControlName]="'brightness'"/>
          </mat-form-field>
          <mat-form-field class="pr-2" fxFlex="25">
            <mat-label>R</mat-label>
            <input matInput type="number"   min="0" max="255" [formControlName]="'red'"/>
          </mat-form-field>
          <mat-form-field class="pr-2" fxFlex="25">
            <mat-label>G</mat-label>
            <input matInput type="number"   min="0" max="255" [formControlName]="'green'"/>
          </mat-form-field>
          <mat-form-field class="pr-2" fxFlex="25">
            <mat-label>B</mat-label>
            <input matInput type="number"   min="0" max="255" [formControlName]="'blue'"/>
          </mat-form-field>
        </div>

        <div mat-dialog-actions>
          <div
            class="flex items-center justify-between w-full border-t px-8 py-4"
          >
          <app-close-dialog></app-close-dialog>
            <div *ngIf="edited" class="flex items-center">
              <button
                mat-flat-button
                [color]="'primary'"
                (click)="selectBlend()"
                [disabled]="selectedProductForm.invalid"
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
