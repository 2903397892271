import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {SignalsService} from '../signals.service';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-add-signal',
  templateUrl: './add-signal.component.html',
  styleUrls: ['./add-signal.component.scss']
})
export class AddSignalComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  responseMessage: string;
  closeDialogComponent: CloseDialogComponent;

  constructor(
    private _formBuilder: FormBuilder,
    private _signalsService: SignalsService,
    public dialogRef: MatDialogRef<AddSignalComponent>,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      hardwareId: ['', [Validators.required]],
      file: [''],
      fileData: [''],
    });

  }


  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success'){
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 2000);
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.selectedProductForm.patchValue({
      fileData: file,
    });
    this.selectedProductForm.get('fileData').updateValueAndValidity();
  }

  addSignal() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const signal = this.selectedProductForm.getRawValue();

      let formData: any = new FormData();
      formData.append('name', signal.name);
      formData.append('hardwareId', signal.hardwareId);
      formData.append('file', signal.fileData);

      this.saving = true;

      this._signalsService.addSignal(formData).subscribe({
        next: (e: any) => {
          if (e.data.id) {
            this.showFlashMessage('success');
          } else {
            this.showFlashMessage('error');
          }
        }, error: (err => {
          this.responseMessage = err.error.message;
          this.showFlashMessage('error');
        })
      });
    }
  }


}
