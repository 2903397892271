import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { SharedAlertComponent } from '../../../shared-alert/shared-alert.component';
import {InventoryService} from '../../inventory/inventory.service';
import {InventoryProduct} from '../../inventory/inventory.types';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
  flashMessage: 'success' | 'error' | null = null;
  formGroup: FormGroup;
  actionMessage: string;
  

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangeEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InventoryProduct,
    private _inventoryService: InventoryService,
    public dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.formGroup.controls['email'].setValue(this.data.email);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    setTimeout(async () =>  {

      if (this.flashMessage === 'success'){
        this.dialogRef.close(true);
      }
      this.flashMessage = null;
      
      // Mark for check
      this._changeDetectorRef.markForCheck();

    }, 5000);
  }

  changeEmail() {
      if (this.data.auth0Id === null){

        this.dialog.open(SharedAlertComponent,
          {
            width: 'auto',
            disableClose: false,
            data: {dialogMessage: 'Cannot change email for this account because auth0Id is null'}
          });
      }
      else{
        this._inventoryService.changeEmail(this.data.id, this.formGroup.controls['email'].value).subscribe({
          next: (e: any) => {
            if (e.message === null){
              this.showFlashMessage('success');
            } else {
              this.actionMessage = e.error.message;
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.actionMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }

     
  }

}
