<div mat-dialog-content>
  <div class="shadow-lg overflow-hidden">
    <div class="flex border-b">
      <!-- Selected product form -->
      <form
        class="flex flex-col w-full"
        [formGroup]="selectedProductForm"
      >
        <mat-form-field class="pr-2">
          <mat-label>Name</mat-label>
          <input matInput [formControlName]="'name'"/>
        </mat-form-field>

        <div class="flex">


          <mat-form-field class="pr-2">
            <mat-label>HardwareId</mat-label>
            <input matInput type="number" [formControlName]="'hardwareId'"/>
          </mat-form-field>

          <mat-form-field class="pr-2">
            <mat-label>File</mat-label>
            <ngx-mat-file-input [formControlName]="'file'" (change)="uploadFile($event)"> </ngx-mat-file-input>
          </mat-form-field>
        </div>



        <div mat-dialog-actions>
          <div
            class="flex items-center justify-between w-full border-t px-8 py-4"
          >
            <app-close-dialog></app-close-dialog>
            <div class="flex items-center">
              <div
                class="flex items-center mr-4"
                *ngIf="flashMessage"
              >
                <ng-container
                  *ngIf="flashMessage === 'success'"
                >
                  <mat-icon
                    class="text-green-500"
                    [svgIcon]="'heroicons_outline:check'"
                  ></mat-icon>
                  <span class="ml-2">Signal Added</span>
                </ng-container>
                <ng-container *ngIf="flashMessage === 'error'">
                  <mat-icon
                    class="text-red-500"
                    [svgIcon]="'heroicons_outline:x'"
                  ></mat-icon>
                  <span class="ml-2">{{responseMessage}}</span>
                </ng-container>
              </div>
              <button

                [disabled]="saving"

                mat-flat-button
                [color]="'primary'"
                (click)="addSignal()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
