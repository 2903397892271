import { Component, OnInit } from '@angular/core';
import {Membership} from './membership';
import {MembershipsService} from './memberships.service';
import {MatDialog} from '@angular/material/dialog';
import {MembershipEditorComponent} from './membership-editor/membership-editor.component';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss']
})
export class MembershipsComponent implements OnInit {
  isLoading: boolean = false;
  memberships: Membership[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  constructor(
    private _membershipsService: MembershipsService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    private _fuseConfirmationService: FuseConfirmationService,

    public dialog: MatDialog

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Memberships');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:membershiplevel')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:membershiplevel');
    this.canEdit = perms.includes('update:membershiplevel');
    this.canDelete = perms.includes('delete:membershiplevel');
  
    await this.getMemberships();
    this.isLoading = false;

  }

  async getMemberships() {
    this.isLoading = true;
    this.memberships = await this._membershipsService.getMemberships();
    
    this.memberships.sort(function(a, b) {
      return (a.value < b.value) ? -1 : ((a.value > b.value) ? 1 : 0);
    });

    this.isLoading = false;
  }


  async onTableDataChange(event: any){
    this.page = event;
    await this.memberships;
  }

  addMembership() {
    let dialogref = this.dialog.open(MembershipEditorComponent, 
    {
      width: '700px',
      disableClose: true,
      data: {membership: null}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      this.isLoading = true;
      if (res) {
        await this.getMemberships();
      }
      this.isLoading = false;
    });
  }

  editMembership(membership) {
    let dialogref = this.dialog.open(MembershipEditorComponent, 
    {
      width: '700px',
      disableClose: true,
      data: {membership: membership}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      this.isLoading = true;
      await this.getMemberships();
      this.isLoading = false;
    });
  }


removeMembership(membership) {
  this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Membership?'}).afterClosed().subscribe(async e => {
    if (e !== 'confirmed') {
      return;
    }
    (await this._membershipsService.removeMembership(membership.id)).subscribe({
      next: async (e: any) => {
        if (e.message === null) {
          this.isLoading = true;
          await this.getMemberships();
          this.isLoading = false;
          this.dialog.open(SharedAlertComponent,
          {
            width: 'auto',
            disableClose: false,
            data: {dialogMessage:'Membership : ' + membership.name + ' is deleted successfully'}
          });
        } 
      }, error: (err => {
         this.dialog.open(SharedAlertComponent,
          {
            width: 'auto',
            disableClose: false,
            data: {dialogMessage: err.error.message}
          });
      })
    });
});
}
}
