import { MembershipLevel } from "../membership-levels/membership-levels";

export class Experience {
    id: string;
    code: string;
    name: string;
    description: string;
    imageUrl: string;
    bannerUrl: string;
    repeatable: Boolean;
    expirationDate: string;
    duration: string;
    membershipLevelID: string;
    membershipLevel: MembershipLevel;
    offerDurationFormatted: string;
  }