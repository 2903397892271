import { Component, OnInit } from '@angular/core';
import {AppVersion} from './appversion';
import {AppVersionService} from './appversion.service';
import {MatDialog} from '@angular/material/dialog';

import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import { AppVersionEditorComponent } from './appversion-editor/appversion-editor.component';

@Component({
  selector: 'app-appversion',
  templateUrl: './appversion.component.html',
  styleUrls: ['./appversion.component.scss']
})
export class VersionComponent implements OnInit {
  isLoading: boolean = false;
  appVersion: any = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  constructor(
    private _versionService: AppVersionService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    public dialog: MatDialog

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - App Version');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:mobile-version')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canEdit = perms.includes('update:mobile-version');
  
    await this.getVersions();
    this.isLoading = false;

  }

  async getVersions() {
    this.isLoading = true;
    this.appVersion = [];
    let androidV = await this._versionService.getAndroidVersion();
    let iosV = await this._versionService.getIOSVersion();
    this.appVersion.push(androidV);
    this.appVersion.push(iosV);   
    this.isLoading = false;
  }

  

  updateVersion(version) {
    let dialogref = this.dialog.open(AppVersionEditorComponent, 
    {
      disableClose: true,
      data: {version: version}
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.getVersions();
        this.isLoading = false;
      }     
    });
  }



}
