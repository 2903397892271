<div mat-dialog-content>
    <div class="shadow-lg overflow-hidden">
      <div class="flex border-b">
        <!-- Selected product form -->
        <form
          class="flex flex-col w-full"
          [formGroup]="selectedProductForm"
        >
          <div fxLayout="row">

            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Code</mat-label>
              <input matInput [formControlName]="'code'"/>         
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
                <mat-label>Name</mat-label>
                <input matInput [formControlName]="'name'"/>         
              </mat-form-field>

            
          </div>

          <div fxLayout="row">
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Membership Level</mat-label>
              <mat-select [formControlName]="'membershipLevelID'" >
                <mat-option *ngFor="let level of membershipLevels" [value]="level.id">
                  {{ level.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Description</mat-label>
              <input matInput [formControlName]="'description'"/>               
           </mat-form-field>

           
          </div>

          <div fxLayout="row">
            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Image File</mat-label>
              <ngx-mat-file-input [formControlName]="'imageFile'" [accept]="'.png, .jpg, .gif, .jpeg, .webp, .bmp, .wbmp'"
                                  appearance="standard"
                                  (change)="uploadImageFile($event)"
              >
              </ngx-mat-file-input>
              <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
              <mat-label>Banner File</mat-label>
              <ngx-mat-file-input [formControlName]="'bannerFile'" [accept]="'.png, .jpg, .gif, .jpeg, .webp, .bmp, .wbmp'"
                                  appearance="standard"
                                  (change)="uploadBannerFile($event)"
              >
              </ngx-mat-file-input>
              <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>
          
            
          </div>

          <div fxLayout="row">
            <mat-label>Please select experience expiry</mat-label>
          </div>
           
          <div fxLayout="row">   
            <div class="pr-2" fxFlex="60">
              <mat-radio-group class="radio-group" formControlName="option" aria-label="Select an option" class="pr-2" fxFlex="60">
                <mat-radio-button value="1" (click)="disableDuration()">Expiration Date</mat-radio-button>
                <mat-radio-button value="2" (click)="disableExpirationDate()">Duration</mat-radio-button>
              </mat-radio-group>
            </div> 
                    
            <mat-form-field class="pr-2" fxFlex="40">
              <mat-label>Repeatable</mat-label>
              <mat-select [formControlName]="'repeatable'" >
                <mat-option [value]="true"> true </mat-option>
                <mat-option [value]="false"> false </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          
           

          <div fxLayout="row">
            <mat-form-field appearance="outline" class="pr-2" fxFlex="30" *ngIf="hideExpirationDate === false">
              <mat-label>Choose Date</mat-label>
              <input formControlName="expirationDate" matInput [matDatepicker]="picker">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="23" *ngIf="hideDuration === false">
                <mat-label>Hours (0-23)</mat-label>
                <input matInput type="number"  min="0" [formControlName]="'hours'"/>             
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="23" *ngIf="hideDuration === false">
                <mat-label>Minutes (0-59)</mat-label>
                <input matInput type="number"  min="0" max="23" [formControlName]="'minutes'"/>                  
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="23" *ngIf="hideDuration === false">
                <mat-label>Seconds (0-59)</mat-label>
                <input matInput type="number"   min="0" max="59" [formControlName]="'seconds'"/>                 
            </mat-form-field>  
          </div>

          <div mat-dialog-actions>
            <div
              class="flex items-center justify-between w-full border-t px-8 py-4"
            >
              <button
                class="-ml-4"
                mat-button
                [color]="'warn'"
                mat-dialog-close
                mat-dialog-close="true"
                (click)="closeDialog()"
              >
                Close
              </button>
              <div class="flex items-center">
                <div
                  class="flex items-center mr-4"
                  *ngIf="flashMessage"
                >
                  <ng-container
                    *ngIf="flashMessage === 'success'"
                  >
                    <mat-icon
                      class="text-green-500"
                      [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <span class="ml-2">{{actionMessage}}</span>
                  </ng-container>
                  <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon
                      class="text-red-500"
                      [svgIcon]="'heroicons_outline:x'"
                    ></mat-icon>
                    <span class="ml-2"
                    >{{responseMessage}}</span
                    >
                  </ng-container>
                </div>
                <button

                  mat-flat-button
                  [color]="'primary'"
                  (click)="addExperience()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

