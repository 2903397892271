import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import { DocumentsEditorComponent } from './documents-editor/documents-editor.component';
import { DocumentsService } from './documents.service';
import { DocumentsViewComponent } from './documents-view/documents-view.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  isLoading: boolean = false;
  docs: any = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  constructor(
    private _documentsService: DocumentsService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    public dialog: MatDialog

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Documents');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:mobile-version')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canEdit = perms.includes('update:mobile-version');
  
    await this.getDocuments();
    this.isLoading = false;

  }

  async getDocuments() {
    this.isLoading = true;
    this.docs = [];
    let TCDoc = await this._documentsService.getTCDocument();
    let PPDoc = await this._documentsService.getPrivacyPolicyDocument();

    this.docs = TCDoc.concat(PPDoc);
    this.docs.sort(function(a, b) {
        return (a.version < b.version) ? -1 : ((a.version > b.version) ? 1 : 0);
      });
    this.isLoading = false;

  }

  

  addDocument() {
    let dialogref = this.dialog.open(DocumentsEditorComponent, 
    {
      disableClose: true,
    });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.getDocuments();
        this.isLoading = false;
      }
      
    });
  }

  viewDocument(doc){
    let dialogref = this.dialog.open(DocumentsViewComponent, 
        {
          width: '900px',
          height: 'auto',
          disableClose: false,
          data: doc,
        });       
  }



}
