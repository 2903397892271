import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map,  of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Experience} from './experience';


@Injectable({
  providedIn: 'root'
})
export class ExperiencesService {
  constructor(
    private _httpClient: HttpClient
  ) {
  }

  async getExperiences(): Promise<Experience[]>  {
    await new Promise(f => setTimeout(f, 1000));
    return firstValueFrom(
        this._httpClient.get(`${environment.opsapi}/v1/experience/paged?itemsPerPage=1000&pageNumber=1`)
          .pipe(
            map((entry) => entry["records"])
          )
      );
  }

  async getExperienceByCode(experience: Experience) {
    return this._httpClient.get(`${environment.opsapi}/v1/experience/by-id/${experience.id}`);
  }


  addExperience(experience: Experience, programCount: any) {
    if(programCount === '' || programCount === undefined || programCount === null) {
      return this._httpClient.post(`${environment.opsapi}/v1/experience/`, experience);
    }
    else{
      return this._httpClient.post(`${environment.opsapi}/v1/experience/?programCodesCount=${programCount}`, experience);
    }
    
  }

  updateExperience(id: string, experience: Experience) {
    return this._httpClient.put(`${environment.opsapi}/v1/experience/by-id/${id}`, experience);
  }


  async removeExperience(experience: any): Promise<any> {
    return firstValueFrom(this._httpClient.delete(`${environment.opsapi}/v1/experience/by-id//${experience.id}`));
  }

  async delay(){
    await new Promise(f => setTimeout(f, 2000));
  }

  uploadImage(data: any, images: any) {    
    let formData: any = new FormData();
    if (images.bannerFileData) {
      formData.append('bannerFile', images.bannerFileData);
    }
    if (images.imageFileData) {
      formData.append('imageFile', images.imageFileData);
    }

    if (images.bannerFileData || images.imageFileData) {
      return this._httpClient.put(`${environment.opsapi}/v1/experience/by-id/${data.id}/upload`, formData);
    } else {
      return of(true);
    }

  }

}