import { Injectable } from "@angular/core";
import { TRANSLOCO_MISSING_HANDLER, TranslocoMissingHandler, TranslocoMissingHandlerData } from "@ngneat/transloco";

@Injectable({
    providedIn: 'root'
})
export class TranslocoCoreMissingHandler implements TranslocoMissingHandler
{
    handle(key: string, config: TranslocoMissingHandlerData)
    {
        console.warn(`Missing translation for key: ${key}`);
        return `${key}`;
    }
}