<div mat-dialog-content>
    <div class="shadow-lg overflow-hidden">
      <div class="flex border-b">
        <!-- Selected product form -->
        <form
          class="flex flex-col w-full"
          [formGroup]="selectedProductForm"
        >
          <div fxLayout="row">  
            <mat-form-field class="pr-2" fxFlex="50">
                <mat-label>Name</mat-label>
                <input matInput [formControlName]="'name'"/>              
              </mat-form-field>

              <mat-form-field class="pr-2" fxFlex="50">
                <mat-label>Type</mat-label>
                <mat-select [formControlName]="'type'">
                  <mat-option [value]="TypeValues.Free">
                    {{ TypeValues.Free }}
                  </mat-option>
                  <mat-option [value]="TypeValues.VIP">
                    {{ TypeValues.VIP }}
                  </mat-option>
                  <mat-option [value]="TypeValues.AllAccess">
                    {{ TypeValues.AllAccess }}
                  </mat-option>
                  <mat-option [value]="TypeValues.PartialAccess">
                    {{ TypeValues.PartialAccess }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          </div>
          
          <div mat-dialog-actions>
            <div
              class="flex items-center justify-between w-full border-t px-8 py-4"
            >
              <button
                class="-ml-4"
                mat-button
                [color]="'warn'"
                mat-dialog-close
                mat-dialog-close="true"
                (click)="closeDialog()"
              >
                Close
              </button>
              <div class="flex items-center">
                <div
                  class="flex items-center mr-4"
                  *ngIf="flashMessage"
                >
                  <ng-container
                    *ngIf="flashMessage === 'success'"
                  >
                    <mat-icon
                      class="text-green-500"
                      [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <span class="ml-2">{{actionMessage}}</span>
                  </ng-container>
                  <ng-container *ngIf="flashMessage === 'error'">
                    <mat-icon
                      class="text-red-500"
                      [svgIcon]="'heroicons_outline:x'"
                    ></mat-icon>
                    <span class="ml-2">{{responseMessage}}</span>
                  </ng-container>
                </div>
                <button
                  
                  mat-flat-button
                  [color]="'primary'"
                  (click)="saveMembership()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  