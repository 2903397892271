import { Component, OnInit } from '@angular/core';
import { Blend } from '../membership-levels/blend';
import {Title} from '@angular/platform-browser';
import {Routine} from '../routines/routine';
import {RoutinesService} from '../routines/routines.service';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {MatDialog} from '@angular/material/dialog';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";
import { Category } from '../categories/category';
import { ScenariosService } from './scenarios.service';
import { Scenario } from './scenario';
import { MembershipLevelService } from '../membership-levels/membership-levels.service';
import { CategoriesService } from '../categories/categories.service';
import { ViewScenarioContentComponent } from './view-scenariocontent/view-scenariocontent.component';
import { ScenarioEditorComponent } from './scenario-editor/scenario-editor.component';

@Component({
  selector: 'app-scenarios',
  templateUrl: './scenarios.component.html',
  styleUrls: ['./scenarios.component.scss']
})
export class ScenarioComponent implements OnInit {

  isLoading: boolean = false;
  blends: Blend[] = [];
  routines: Routine[] = [];
  categories: Category[] = [];
  category: Category;
  scenarios: Scenario[] = [];
  fullScenarios: Scenario[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  query = "";
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
 

  constructor(
    private _scenariosService: ScenariosService,
    private _membershipLevelService: MembershipLevelService,
    private _categoriesService: CategoriesService,
    private _routinesService: RoutinesService,
    private _auth: AuthServiceClass,
    private _titleService: Title,
    private _fuseConfirmationService: FuseConfirmationService,
    public dialog: MatDialog

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Scenarios');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:scenario')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:scenario');
    this.canEdit = perms.includes('update:scenario');
    this.canDelete = perms.includes('delete:scenario');

    this.isLoading = true;

    this.blends = await this._membershipLevelService.getBlends();
    this.routines = await this._routinesService.getRoutines();
    this.categories = await this._categoriesService.getCategories();

    await this.loadScenarios();

    // Subscribe to search input field value changes
    this.searchInputControl.valueChanges
    .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        switchMap(async (query) => {
        this.isLoading = true;
        this.query = query.trim();
        if(this.query){
            this.scenarios = this.scenarios.filter(o => o.name.toLowerCase().includes(this.query.toLowerCase()));
        }
        else{
            this.scenarios = this.fullScenarios;
        }

        }),
        map(() => {
        this.isLoading = false;
        })
    )
    .subscribe();
  }

  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.scenarios = this.fullScenarios;
    this.isLoading = false;
  }

  async loadScenarios() {
    this.isLoading = true;
    this.scenarios = await this._scenariosService.getScenarios(1, 1000);
    for (let scenario of this.scenarios) {
      scenario.availableBlends = [];
      scenario.availableRoutines = [];

      for (let blendId of scenario.signalBundleIds) {
        let found = this.blends.filter( (blend) => blend.id === blendId)[0];
        scenario.availableBlends.push(found);
      }

      for (let routineId of scenario.playlistIds) {
        let found = this.routines.filter( (routine) => routine.id === routineId)[0];
        scenario.availableRoutines.push(found);
      }

      try{scenario.scenarioCategory = this.categories.filter(o => o.id === scenario.categoryId)[0];}
      catch(e:unknown){
      }

      this.fullScenarios = this.scenarios;
    }

    this.scenarios.sort((a,b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    this.isLoading = false;

  }


  
  async onTableDataChange(event: any){
    this.page = event;
    await this.scenarios;
  }

 
  edit(scenario) {
    let dialogref = this.dialog.open(ScenarioEditorComponent,
      {
        width: '700px',
        disableClose: true,
        data: {blends: this.blends, routines: this.routines, categories: this.categories, scenario: scenario}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.loadScenarios();
        this.isLoading = false;
      }
      
    });
  }

  add() {
    let dialogref = this.dialog.open(ScenarioEditorComponent,
      {
        width: '700px',
        disableClose: true,
        data: {blends: this.blends, routines: this.routines, categories: this.categories, scenario: null}
      });
    dialogref.afterClosed().subscribe(async (res) => {
      if (res) {
        this.isLoading = true;
        await this.loadScenarios();
        this.isLoading = false;
      }
    });
  }


    delete(scenario) {
        this._fuseConfirmationService.open({message: 'Are you sure you want to delete this Scenario?'}).afterClosed().subscribe(async e => {
        if (e !== 'confirmed') {
            return;
        }
        (await this._scenariosService.deleteScenario(scenario.id)).subscribe({
            next: async (e: any) => {
            if (e.message === null || e.message === undefined) {
                this.isLoading = true;
                await this.loadScenarios();
                this.isLoading = false;
                this.dialog.open(SharedAlertComponent,
                {
                width: 'auto',
                disableClose: false,
                data: {dialogMessage:'Scenario : ' + scenario.name + ' is deleted successfully'}
                });
            } 
            }, error: (err => {
            this.dialog.open(SharedAlertComponent,
                {
                width: 'auto',
                disableClose: false,
                data: {dialogMessage: err.error.message}
                });
            })
        });
    });
  }

  viewContents(scenario: Scenario) {
      let dialogref = this.dialog.open(ViewScenarioContentComponent, {
        disableClose: false,
        width: 'auto',
        height: 'auto',
        data: scenario
      });
      
    }
  
}
