import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from "rxjs";

import {
  CreateUser,
  InventoryPagination,
  InventoryProduct,
} from "app/modules/admin/apps/accounts/inventory/inventory.types";
import { values } from "lodash";
import {environment} from '../../../../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class InventoryService {
  // Private
  private _pagination: InventoryPagination;
  private count: number;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for pagination
   */
  get pagination(): InventoryPagination {
    return this._pagination;
  }



  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get products count
   */
  getProductsCount(searchText: String): Promise<number> {
    return firstValueFrom(this._httpClient
      .get(`${environment.opsapi}/v4/account/count?searchText=${searchText}`)
      .pipe(
        tap((response: any) => {
          this.count = response.data.count;
          let pagination = {
            length: this.count,
            size: 25,
            page: 1,
          }
          this._pagination = pagination;
        }),
        map((response: any) => response.data.count)
      ));
  }

  /**
   * Get products
   *
   *
   * @param page
   * @param size
   * @param sort
   * @param order
   * @param search
   */
  getProducts(page: number, size: number, sort: String, order: String, search: String): Promise<InventoryProduct[]> {
    return firstValueFrom(this._httpClient
      .get<InventoryProduct[]>(
        `${environment.opsapi}/v5/account/paged?itemsPerPage=${size}&sortFieldName=${sort}&sortBy=${order}&pageNumber=${page}&searchText=${search}`
      )
      .pipe(
        tap((response: any) => {
          let pagination = {
            length: this.count,
            size: size,
            page: page,
          }
          this._pagination = pagination;
        }),
        map((entry) => entry["records"])
      ));
  }

  /**
   * Get product by id
   */
  getProductById(id: string): Promise<InventoryProduct> {
    return firstValueFrom(this._httpClient
      .get<InventoryProduct>(
        `${environment.opsapi}/v4/account/${id}`
      ));     
  }

  getAccountbyId(id: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/account/${id}`);
  }


  createUser(data: any): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.post(
      `${environment.opsapi}/v4/account/full/`,
      data,
      { headers: headers }
    );
  }

  


  getMembershipLevels(): Observable<any>  {

    return this._httpClient.get(`${environment.opsapi}/v5/membershiplevel/paged?itemsPerPage=100&pageNumber=1`)
       .pipe(
         map((entry) => entry["records"])
       );
 }

  changeEmail(userId: string, newEmail: string): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${userId}/email/${newEmail}`,
      { headers: headers }
    );
  }

  
  viewDevices(userId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/account/${userId}/details`,);
  }

  getAccountNotes(userId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/account/${userId}/notes`,);
  }

  getAccountUsageLog(userId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v5/UsageLogs/paged/${userId}?itemsPerPage=1000&pageNumber=1`)
      .pipe(
        map((entry) => entry["records"])       
      );
  }

  

  getAccountAuthLog(userId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/logs/auth0/${userId}?itemsperpage=25&pagenumber=1`,);
  }

  getIntegrationByProfile(profile: string): Observable<any> {
    return this._httpClient
      .get(`${environment.opsapi}/v4/integration/profile/${profile}`,)
      .pipe(
        tap((res) => console.log('Succesfull 1st service result', res)),
        catchError((error) => {
          if (error.status === 404) {
            return of(undefined);
          } else {
            throw error;
          }
        }),
      );
  }

  getDeviceLog(userId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v5/devicelogs/paged?itemsPerPage=25&pageNumber=1&searchText=${userId}`)
      .pipe(
        map((entry) => entry["records"])       
      );
  }

  getIntegrationDetails(id: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/integration/details/${id}?itemsPerPage=1000&pageNumber=1`)
      .pipe(
        map((entry) => entry["records"])       
      );
  }

  getAppLog(userId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/logs/customerlogs/${userId}?itemsPerPage=1000&pageNumber=1`)
      .pipe(
        map((entry) => entry["records"])       
      );
  }

  getProfileByEmail(email: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/profile/by-email/${email}`,);
  }

  async deleteAccountNotes(userId, notesId) {
    return this._httpClient.delete(`${environment.opsapi}/v4/account/${userId}/notes/${notesId}`);
  }

  updateAccountNotes(userId: string, notesId: string, data: any): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${userId}/notes/${notesId}`,
      data,
      { headers: headers }
    );
  }

  createAccountNotes(userId: string, data: any): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.post(
      `${environment.opsapi}/v4/account/${userId}/notes`,
      data,
      { headers: headers }
    );
  }


  createProfile(data: any): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.post(
      `${environment.opsapi}/v4/profile`,
      data,
      { headers: headers }
    );
  }


  getProfile(profileId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/profile/${profileId}`,);
  }

  getAccountRoles(accountId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/account/by-email/${accountId}/roles`,);
  }

  getAccountLock(accountId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/account/${accountId}/lockstatus`,);
  }

  getProfileByUserEmail(email: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/profile/by-email/${email}`,);
  }

  getAccountByUserEmail(email: string): Observable<any> {
    return this._httpClient.get(
      `${environment.opsapi}/v4/Account/by-email/${email}`,);
  }

  updateUser(id: string, product: any): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${id}/update`,
      product,
      { headers: headers }
    );
  }

  updateAccount(id: string, data: any): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${id}`,
      data,
      { headers: headers }
    );
  }

  lockUser(id: string): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${id}/lock`,
      { headers: headers }
    );
  }

  unlockUser(id: string): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${id}/Clearlock`,
      { headers: headers }
    );
  }

  undeleteUser(email: string): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${email}/undelete`,
      { headers: headers }
    );
  }

  resetPassword(id: string): Observable<any> {
    const headers = { "content-type": "application/json" };
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${id}/resetpassword`,
      { headers: headers }
    );
  }


  deleteUser(id) {
    const headers = { "content-type": "application/json" };
    return this._httpClient.delete(
      `${environment.opsapi}/v4/account/${id}/full`,
      { headers: headers }
    );
  }


  deleteCustomerMembership(accountId: string, membershipId: string){
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${accountId}/membership/${membershipId}/remove`, `{}`);  
  }

  addCustomerMembership(accountId: string, membershipId: string){
    return this._httpClient.put(
      `${environment.opsapi}/v4/account/${accountId}/membership/${membershipId}`, `{}`);  
  }
}
