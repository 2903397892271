import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Notification} from './notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private _httpClient: HttpClient

  ) { }

  async getNotifications(): Promise<Notification[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/notification/paged?itemsPerPage=1000&pageNumber=1`)
        .pipe(
          map((entry) => entry["records"])
        )
    );
  }

  async getNotificationsPaged(title: string, body: string, beginDate: string, endDate: string, status: string, communicationType: string, notificationType: string): Promise<Notification[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/notification/paged?itemsPerPage=100&pageNumber=1&title=${title}&body=${body}&beginDate=${beginDate}&endDate=${endDate}&status=${status}&communicationType=${communicationType}&notificationType=${notificationType}`)
        .pipe(
          map((entry) => entry["records"])
        )
    );
  }

  createNotification(notification: any) {
    return this._httpClient.post(`${environment.opsapi}/v4/notification/create`, notification);
  }

 
  async getNotificationById(id: string): Promise<Notification[]> {
    return firstValueFrom(
      this._httpClient.get(`${environment.opsapi}/v4/notification/${id}`)
        .pipe(
          map((entry) => entry["data"])
        )
    );
  }

}
