import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom,  tap} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Documents} from './documents';


@Injectable({
  providedIn: 'root'
})
export class DocumentsService {  
  constructor(
    private _httpClient: HttpClient
  ) {
  }

  

  getTCDocument(){
    return firstValueFrom(this._httpClient
      .get<Documents[]>(
        `${environment.opsapi}/v4/documents/TermsConditions`
      )
      );
  }

  getPrivacyPolicyDocument(){
    return firstValueFrom(this._httpClient
      .get<Documents[]>(
        `${environment.opsapi}/v4/documents/PrivacyPolicy`
      )
      );
  }

  addTCDocument(doc: Documents) {
    return this._httpClient.post(`${environment.opsapi}/v4/documents/TermsConditions`, doc);
  }

  addPrivacyPolicyDocument(doc: Documents) {
    return this._httpClient.post(`${environment.opsapi}/v4/documents/PrivacyPolicy`, doc);
  }

  
}
