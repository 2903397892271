import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {User} from 'app/core/user/user.types';
import {InventoryService} from '../inventory/inventory.service';
import { MembershipLevel } from '../../membership-levels/membership-levels';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  membershiplevel: MembershipLevel[];
  saving: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateUserComponent>,
    private _inventoryService: InventoryService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.selectedProductForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      membershipIds: [[]],
    });
    this.membershiplevel = await firstValueFrom(this._inventoryService.getMembershipLevels());
  }

  closeDialog() {
    this.dialogRef.close();
  }

  createUser() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const product = this.selectedProductForm.getRawValue();
      this.saving = true;
      this._inventoryService.createUser(product).subscribe((e) => {
        if (e.id) {
          this.showFlashMessage('success');
        } else {
          this.showFlashMessage('error');
        }
      });
    }
  }

   showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    setTimeout(async () => {
      this.flashMessage = null;
      this.closeDialog();
      // Mark for check
      this._changeDetectorRef.markForCheck();

    }, 2000);
  }
}
