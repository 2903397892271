import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FeaturesService} from '../features.service';
import { Feature } from '../feature';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-feature-editor',
  templateUrl: './feature-editor.component.html',
  styleUrls: ['./feature-editor.component.scss']
})
export class FeatureEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  feature: Feature;
  responseMessage: string;
  actionMessage: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _featuresService: FeaturesService,
    public dialogRef: MatDialogRef<FeatureEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private closeDialogComponent: CloseDialogComponent,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
      this.feature = data.feature;
  }

  async ngOnInit(): Promise<void> {

    this.selectedProductForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      description: ['', Validators.required],
    });
    
    if (this.feature) {
      this.selectedProductForm.get('name').setValue(this.feature.name);
      this.selectedProductForm.get('description').setValue(this.feature.description);
          
    } 
  }
  
  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 1500);
  }

  addFeature() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
      const picked = this.selectedProductForm.getRawValue();
     
      let newFeature = new Feature();
      newFeature.name = picked.name;
      newFeature.description = picked.description;

      this.saving = true;
      if (this.feature == null) {
        this._featuresService.addFeature(newFeature).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Feature Flag Added';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      else{
        this._featuresService.updateFeature(this.feature.id, newFeature).subscribe({
          next: (e: any) => {
            if (e.data.id) {
              this.actionMessage = 'Feature Flag Updated';
              this.showFlashMessage('success');
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }

      
    }
  }


}
