<div mat-dialog-content>
    <div class="shadow-lg overflow-hidden">
      <div class="flex border-b">
        <!-- Selected product form -->
        <form
          class="flex flex-col w-full"
          [formGroup]="selectedProductForm"
        >
          <div fxLayout="row"> 
            <mat-form-field class="pr-2" fxFlex="100">
              <mat-label>Title</mat-label>
              <input matInput [formControlName]="'title'"/>              
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="100">
                <mat-label>Body</mat-label>
                <input matInput [formControlName]="'body'"/>
            </mat-form-field>
        </div>
            
        <div>
            <mat-form-field class="pr-2" fxFlex="50">
                <mat-label>Begin Date</mat-label>
                <input formControlName="beginDate" matInput [matDatepicker]="picker">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>          
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="50">
                <mat-label>End Date</mat-label>
                <input formControlName="endDate" matInput [matDatepicker]="picker2">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>              
            </mat-form-field>
        </div>       

        <div>
            
            <mat-form-field class="pr-2" fxFlex="30">
                <mat-label>Status</mat-label>
                <mat-select [formControlName]="'status'" >
                    <mat-option value="created"> Created </mat-option>
                    <mat-option value="processing"> Processing </mat-option>
                    <mat-option value="processed"> Processed </mat-option>
                    <mat-option value="failed"> Failed </mat-option>
                </mat-select>                 
            </mat-form-field> 

            <mat-form-field class="pr-2" fxFlex="35">
                <mat-label>Communication Type</mat-label>
                <mat-select [formControlName]="'communicationType'" >
                    <mat-option value="Recommendations"> Recommendations </mat-option>
                    <mat-option value="Marketing"> Marketing </mat-option>
                </mat-select>          
            </mat-form-field>

            <mat-form-field class="pr-2" fxFlex="35">
                <mat-label>Notification Type</mat-label>
                <mat-select [formControlName]="'notificationType'" >
                    <mat-option value="push"> Push </mat-option>
                </mat-select>
           
            </mat-form-field>
        </div>
            
               
          <div mat-dialog-actions>
            <div
              class="flex items-center justify-between w-full border-t px-8 py-4"
            >
            <app-close-dialog></app-close-dialog>
              <div class="flex items-center">
                <button
  
                  mat-flat-button
                  [color]="'primary'"
                  (click)="filterNotification()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  