import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, firstValueFrom, map, Observable, of, switchMap, throwError} from 'rxjs';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import {environment} from '../../../environments/environment';
import {AuthService} from '@auth0/auth0-angular';
import {InventoryProduct} from '../../modules/admin/apps/accounts/inventory/inventory.types';

@Injectable()
export class AuthServiceClass {
  // -----------------------------------------------------------------------------------------------------
  _permissions: string[] = [];
  _user: InventoryProduct = null;

  private _authenticated: boolean = false;

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    @Inject(AuthService) public auth: AuthService

  ) {
    this.auth.isAuthenticated$.subscribe(async state => {
      await this.authenticate();
    });
  }

  async authenticate() {
    this.auth.user$.subscribe(async user => {
      this._user = await this.getAccountByEmail(user.email);
      
      let perms = await firstValueFrom(this._httpClient.get(`${environment.opsapi}/v4/Account/${this._user.id}/permissions`));
      
      if (perms['statusCode'] === 200) {
        this._permissions = perms['data'];
      }
    });
  }

  async userEmail(){
    let user = await firstValueFrom(this.auth.user$);
    return user.email;
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: {
    email: string;
    password: string;
    audience: string;
    client_secret: string;
    scope: string;
    grant_type: string;
    client_id: string;
  }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post(
        `${environment.opsapi}/oauth/token`,
        credentials
      )
      .pipe(
        switchMap(async (response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.access_token;

          let permissions = this.getPermissions();
          console.log('PERMISSIONS', permissions);

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return a new observable with the response
          return of(response);
        })
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .post('api/auth/refresh-access-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.accessToken;

          let permissions = this.getPermissions();
          console.log('PERMISSIONS', permissions);

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  async getPermissions(): Promise<string[]> {
    if (this._permissions && this._permissions.length !== 0) {
      console.log('Cached Permissions', this._permissions);
      return this._permissions;
    } else {
        if (!this._user) {
          let user = await firstValueFrom(this.auth.user$);
          this._user = await this.getAccountByEmail(user.email);
        }

        let perms = await firstValueFrom(this._httpClient.get(`${environment.opsapi}/v4/Account/${this._user.id}/permissions`));
        console.log('Perms', perms);
        if (perms['statusCode'] === 200) {
          this._permissions = perms['data'];
        }
        return this._permissions;
    }
  }

  async getAccountByEmail(email: string): Promise<any> {
    return firstValueFrom(this._httpClient.get(`${environment.opsapi}/v4/Account/by-email/${email}`))
  }
}
