import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MembershipLevel } from '../../membership-levels/membership-levels';
import { ExperiencesService } from '../experience.service';
import { firstValueFrom } from 'rxjs';
import moment from 'moment';
import { Experience } from '../experience';


@Component({
  selector: 'app-experience-editor',
  templateUrl: './experience-editor.component.html',
  styleUrls: ['./experience-editor.component.scss']
})
export class ExperienceEditorComponent implements OnInit {
  selectedProductForm: FormGroup;
  imagesForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  membershipLevels: MembershipLevel[];
  hours: string;
  minutes: string;
  seconds: string;
  experience: Experience;
  responseMessage: string;
  actionMessage: string;
  hideDuration: Boolean = true;
  hideExpirationDate: Boolean = true;
  messageSuccess: Boolean = true;

  constructor(
    private _formBuilder: FormBuilder,
    private _experienceService: ExperiencesService,
    public dialogRef: MatDialogRef<ExperienceEditorComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
      this.experience = data.experience;
      this.membershipLevels = data.membershiplevels;
  }

  async ngOnInit(): Promise<void> {
    this.selectedProductForm = this._formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(4)]],
      name: ['', [Validators.required, Validators.minLength(4)]],
      description: [''],
      repeatable: [''],
      expirationDate: [''],
      membershipLevelID: ['', [Validators.required]],
      hours: [''],
      minutes: [''],
      seconds: ['']
    });

    this.imagesForm = this._formBuilder.group({
      imageFile: [''],
      imageFileData: [''],
      bannerFile: [''],
      bannerFileData: [''],
    });

    if (this.experience) {
      this.selectedProductForm.get('code').setValue(this.experience.code);
      this.selectedProductForm.controls['code'].disable();
      this.selectedProductForm.get('name').setValue(this.experience.name);
      this.selectedProductForm.get('description').setValue(this.experience.description);
      this.selectedProductForm.get('repeatable').setValue(this.experience.repeatable);     
      this.selectedProductForm.get('membershipLevelID').setValue(this.experience.membershipLevelID);

      if(this.experience.expirationDate !== null && this.experience.expirationDate !== undefined && this.experience.expirationDate !==""){
        this.selectedProductForm.get('expirationDate').setValue(this.experience.expirationDate);
        this.hideExpirationDate = false;
      }

      if(this.experience.duration !== null && this.experience.duration !== undefined && this.experience.duration !==""){
        this.selectedProductForm.get('hours').setValue(this.experience.duration.split(":")[0]);
        this.selectedProductForm.get('minutes').setValue(this.experience.duration.split(":")[1]);
        this.selectedProductForm.get('seconds').setValue(this.experience.duration.split(":")[2]);
        this.hideDuration = false;
      }
    } 
    
  }

  uploadImageFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        imageFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        imageFileData: file,       
      });
      this.imagesForm.get('imageFile').updateValueAndValidity();
    }
  }
  
  uploadBannerFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file.type.includes('image')) {
      alert('File must be of type image');
      this.imagesForm.patchValue({
        bannerFile: null,
      });
    } else {
      this.imagesForm.patchValue({
        bannerFileData: file,       
      });
      this.imagesForm.get('bannerFile').updateValueAndValidity();
    }
  }

  closeDialog(value = false) {
    this.dialogRef.close(value);
  }

  disableDuration() {
    this.selectedProductForm.controls['hours'].disable();
    this.selectedProductForm.controls['minutes'].disable();
    this.selectedProductForm.controls['seconds'].disable();
    this.selectedProductForm.controls['expirationDate'].enable();
    this.hideDuration = true;
    this.hideExpirationDate = false;
  }

  disableExpirationDate() {
    this.selectedProductForm.controls['expirationDate'].disable();
    this.selectedProductForm.controls['hours'].enable();
    this.selectedProductForm.controls['minutes'].enable();
    this.selectedProductForm.controls['seconds'].enable();
    this.hideExpirationDate = true;
    this.hideDuration = false;
  }

  showFlashMessage(type: 'success' | 'error'): void {
    // Show the message
    this.flashMessage = type;

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Hide it after 2 seconds
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialog();
      }
      this.flashMessage = null;
      // Mark for check
      this._changeDetectorRef.markForCheck();

    }, 3000);
  }


  async addExperience() {
    const imageFileValue = this.imagesForm.getRawValue().imageFileData.name;  
    const bannerFileValue = this.imagesForm.getRawValue().bannerFileData.name;  
    if (this.experience === null && (bannerFileValue === undefined || imageFileValue === undefined)){      
      this.responseMessage = 'Please upload experience images';
      this.saving = false;
      this.showFlashMessage('error');
      return;
    }    
    if (this.selectedProductForm.invalid) {
      return;
    } else {  
      const picked = this.selectedProductForm.getRawValue();
      let timeSpan;
      timeSpan = `${String(picked.hours).padStart(2,'0')}:${String(picked.minutes).padStart(2,'0')}:${String(picked.seconds).padStart(2,'0')}`;
      let formattedEndDate = (moment(picked.expirationDate)).format('YYYY-MM-DD');

      let newExperience = new Experience();
      newExperience.code = picked.code;
      newExperience.name = picked.name;
      newExperience.description = picked.description;
      newExperience.repeatable = picked.repeatable;
      newExperience.membershipLevelID = picked.membershipLevelID;

      if(this.hideDuration === false) newExperience.duration = timeSpan;
      if(this.hideExpirationDate === false) newExperience.expirationDate = formattedEndDate;
      this.saving = true;
      if (this.experience == null) {
        this._experienceService.addExperience(newExperience).subscribe({           
          next: async (e: any) => {
            if (e.data.id) {
              const images = this.imagesForm.getRawValue();
              if (images['imageFile'] === '') delete images['imageFile'];   
              if (images['bannerFile'] === '') delete images['bannerFile']; 
              await this._experienceService.delay()          
              this._experienceService.uploadImage(e.data.code, images).subscribe({
                next: (e: any) => {
                  if (e) {
                    this.actionMessage = 'Experience Added';
                    this.showFlashMessage('success');
                  } else {
                    this.saving = false;
                    this.showFlashMessage('error');
                  }
                },
                error: (err) => {
                  this.responseMessage = err.error.message;
                  this.saving = false;

                  this.showFlashMessage('error');
                }
              }
                              
              );
            } else {
              this.showFlashMessage('error');
            }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }
      else{
        newExperience.bannerUrl = this.experience.bannerUrl;
        newExperience.imageUrl = this.experience.imageUrl;
        this._experienceService.updateExperience(this.experience.code, newExperience).subscribe({
          next: async (e: any) => {
            if (e.data.id) {
              const images = this.imagesForm.getRawValue();
              if (images['imageFile'] === '') delete images['imageFile'];   
              if (images['bannerFile'] === '') delete images['bannerFile']; 
              await this._experienceService.delay()                               
              this._experienceService.uploadImage(e.data.code, images).subscribe({
                next: (e: any) => {
                  if (e) {
                    this.actionMessage = 'Experience Updated';
                    this.showFlashMessage('success');
                  } else {
                    this.saving = false;
                    this.showFlashMessage('error');
                  }
                },
                error: (err) => {
                  this.responseMessage = err.error.message;
                  this.saving = false;

                  this.showFlashMessage('error');
              }
              }
            );
            } else {
              this.showFlashMessage('error');
          }
          }, error: (err => {
            this.responseMessage = err.error.message;
            this.showFlashMessage('error');
          })
        });
      }


    }
  }


}