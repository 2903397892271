import { Component, OnInit } from '@angular/core';
import {Notification} from './notifications';
import {NotificationsService} from './notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {NotificationEditorComponent} from './notification-editor/notification-editor.component';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import { NotificationFilterComponent } from './notification-filter/notification-filter.component';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  isLoading: boolean = false;
  notifications: Notification[] = [];
  fullnotifications: Notification[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  query = '';

  constructor(
    private _notificationsService: NotificationsService,
    private _titleService: Title,
    private _auth: AuthServiceClass,
    public dialog: MatDialog

  ) { }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Notifications');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:notification')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:notification');
    this.canEdit = perms.includes('update:notification');
    this.canDelete = perms.includes('delete:notification');

    
    await this.getNotifications();
    this.isLoading = false;

    this.searchInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        switchMap(async (query) => {
          this.isLoading = true;
          this.query = query.trim();
          if(this.query){
            this.notifications = this.notifications.filter(t => t.title.toLowerCase().includes(query.toLowerCase()));
          }
          else{
            this.notifications = this.fullnotifications;
          }
    
        }),
        map(() => {
          this.isLoading = false;
        })
      )
      .subscribe();

  }

  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.notifications = this.fullnotifications;
    this.isLoading = false;
  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.notifications;
  }

  async getNotifications() {
    this.isLoading = true;
    this.notifications = await this._notificationsService.getNotifications();
    for (let notification of this.notifications) {
      notification.created = notification.created.split("T")[0];
    }
    
    this.notifications.sort(function(a, b) {
      return (a.created > b.created) ? -1 : ((a.created < b.created) ? 1 : 0);
    });


    this.isLoading = false;
    this.fullnotifications = this.notifications;
  }

  addNotification() {
    let dialogref = this.dialog.open(NotificationEditorComponent, 
    {
      disableClose: true,
      data: {notification: null}
    });
    dialogref.afterClosed().subscribe(async (res) => {
        this.isLoading = true;
        await this.getNotifications();
        this.isLoading = false;     
    });
  }

  filterNotification() {
    console.log('open')
    let dialogref = this.dialog.open(NotificationFilterComponent, 
    {
      disableClose: true
    });
    dialogref.afterClosed().subscribe(async (res) => {
        this.isLoading = true;
        await this.getNotifications();
        this.isLoading = false;
    });
  }

}

