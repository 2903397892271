<div fxLayout="row wrap" fxLayoutGap="20px" class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title class="text-2xl font-extrabold tracking-tight">Name: {{ experience.name }}</mat-card-title>
      <mat-card-title class="text-2xl font-extrabold tracking-tight">Total Codes: {{ programCodes.length }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="divider"></div>
      <mat-divider class="divider"></mat-divider>
      <div class="divider"></div>
      

      <div *ngIf="programCodes.length > 0 ;else no_codes">
          <h3 class="bold">Program Codes (Not Redeemed)</h3>
          <div *ngFor="let codes of programCodes">
            <mat-chip *ngIf="codes.redeemed == false">
              {{ codes.code }}
            </mat-chip>
          </div>

          <div class="divider"></div>
          <mat-divider class="divider"></mat-divider>
          <div class="divider"></div>

          <h3 class="bold">Program Codes (Redeemed)</h3>
          <div *ngFor="let codes of programCodes">
            <mat-chip *ngIf="codes.redeemed == true">
              {{ codes.code }}
            </mat-chip>
          </div>
      </div>

    </mat-card-content>   
    
      <ng-template #no_codes>
        <mat-chip-list >
          <mat-chip>
            Program codes are not available for this experience.
          </mat-chip>             
        </mat-chip-list>

    </ng-template>  
  </mat-card>
</div>

<div mat-dialog-actions>
  <app-close-dialog></app-close-dialog>
</div>