import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {TagsService} from '../tags.service';
import { CloseDialogComponent } from 'app/modules/admin/common-components/close-dialog/close-dialog.component';

@Component({
  providers:[CloseDialogComponent ],
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss']
})
export class AddTagComponent implements OnInit {
  selectedProductForm: FormGroup;
  flashMessage: 'success' | 'error' | null = null;
  saving: boolean = false;
  responseMessage: string;
  

  constructor(
    private _formBuilder: FormBuilder,
    private _tagsService: TagsService,
    public dialogRef: MatDialogRef<AddTagComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private closeDialogComponent: CloseDialogComponent,
  ) {
  }

  ngOnInit(): void {
    this.selectedProductForm = this._formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(4)]],
      sortOrder: ['', [Validators.required]],
      showInSearch: ['', [Validators.required]],
    });

    this.selectedProductForm.controls.sortOrder.setValue(1);
    this.selectedProductForm.controls.showInSearch.setValue(true);

  }


  showFlashMessage(type: 'success' | 'error'): void {
    this.flashMessage = type;
    this._changeDetectorRef.markForCheck();
    setTimeout(async () => {
      if (this.flashMessage === 'success')
      {
        this.closeDialogComponent.closeDialog(true)
      }
      this.flashMessage = null;
      this._changeDetectorRef.markForCheck();
    }, 1500);
  }

  addTag() {
    if (this.selectedProductForm.invalid) {
      return;
    } else {
      const tag = this.selectedProductForm.getRawValue();

      this.saving = true;

      this._tagsService.addTag(tag).subscribe({
        next: (e: any) => {
          if (e.data.id) {
            this.showFlashMessage('success');
          } else {
            this.showFlashMessage('error');
          }
        }, error: (err => {
          this.responseMessage = err.error.message;
          this.showFlashMessage('error');
        })
      });
    }
  }
}
