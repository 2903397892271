import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-shared-alert',
  templateUrl: './shared-alert.component.html',
  styleUrls: ['./shared-alert.component.scss']
})
export class SharedAlertComponent implements OnInit{
    message: string;
    dialogMessage: string;

  constructor(
    public dialogRef: MatDialogRef<SharedAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
    

  async ngOnInit(): Promise<void> {
    this.message = this.data.dialogMessage;
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

}
