<div *ngIf="hasAccess === 'yes'"
  class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"
>
  <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
    <!-- Loader -->
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>

    <div class="text-4xl font-extrabold tracking-tight">Routines</div>

    <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
      <div style="height: 20px">
        <mat-form-field appearance="outline">
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
          <input matInput [formControl]="searchInputControl" [autocomplete]="'off'" [placeholder]="'Search Routine'"/>
          <button matSuffix mat-icon-button aria-label="Clear" (click)="clearInput()">
            <mat-icon>Close</mat-icon>
          </button>
        </mat-form-field>
      </div>


      <div class="add-button" style="height: 10px">       
        <button *ngIf="canAdd" class="ml-4" mat-flat-button  [color]="'primary'" (click)="add()">        
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">Add</span>
        </button>
      </div>
    </div>
  </div>

  <div class="flex flex-auto overflow-hidden">
    <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
      <ng-container>
        <ng-container>

          <div class="grid">
            <!-- Header -->
            <div
              class="inventory-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
            >
              <div class="hidden md:block">
                Name
              </div>

              <div class="hidden md:block">
                Id
              </div>

              <div class="hidden md:block">
                Category
              </div>

              

              <div class="hidden md:block">
                Description
              </div>


              <div class="hidden md:block">
                Blends
              </div>
              <div class="hidden md:block">
                Actions
              </div>
            </div>
            <!-- Rows -->
            <ng-container *ngIf="routines.length > 0 ;else no_routines">
              <ng-container *ngFor="let routine of routines | paginate: {itemsPerPage: tableSize, currentPage: page, totalItems: count}; let i = index">
                <div class="inventory-grid2 grid items-center gap-4 py-3 px-6 md:px-8 border-b">
                  <!-- Name -->
                  <div >
                    {{ routine.name }}                    
                  </div>

                  <div >
                    {{ routine.id }}
                  </div>

                  <div >
                    {{ routine.category.name }}
                  </div>

                 

                  <div >
                    {{ routine.description }}
                  </div>

                  <mat-chip-list >
                    <mat-chip *ngFor="let blendItem of routine.items">
                      <ng-container *ngIf="blendItem && blendItem.blend">{{ blendItem.blend.name }} ({{ blendItem.suggestedDuration}})</ng-container>
                    </mat-chip>
                  </mat-chip-list>

                  <div>
                    <button mat-flat-button [matMenuTriggerFor]="menu">Actions Menu</button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item *ngIf="canEdit" (click)="clone(routine)">Clone</button>
                      <button mat-menu-item *ngIf="canEdit" (click)="edit(routine)">Edit</button>
                      <button mat-menu-item *ngIf="canDelete" (click)="delete(routine)">Remove</button>
                      <button mat-menu-item (click)="viewImages(routine)">View Images</button>
                    </mat-menu>
                  </div>
                </div>

              </ng-container>
            </ng-container>
            <ng-template #no_routines>
              <mat-chip-list >
                <mat-chip>
                  No Search Result found.
                </mat-chip>             
              </mat-chip-list>
  
          </ng-template>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </div>

  <div class='parent' > 
    <div class="child" align="end">
      <pagination-controls (pageChange)="onTableDataChange($event)"></pagination-controls>
    </div>
  </div>
</div>
<div *ngIf="hasAccess === 'no'" class="flex flex-col flex-auto min-w-0">
  <h1>No Access</h1>
</div>
<div *ngIf="hasAccess === 'validating'" class="flex flex-col flex-auto min-w-0">
  <h1>Validating Access</h1>
</div>
