import {Component, OnInit} from '@angular/core';
import {Tag} from './tags';
import {TagsService} from './tags.service';
import {AddTagComponent} from './add-tag/add-tag.component';
import {MatDialog} from '@angular/material/dialog';
import {EditTagComponent} from './edit-tag/edit-tag.component';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {Title} from '@angular/platform-browser';
import {Access} from '../../../../core/auth/access';
import {AuthServiceClass} from '../../../../core/auth/auth.service';
import { SharedAlertComponent } from '../shared-alert/shared-alert.component';
import {FormControl} from "@angular/forms";
import {debounceTime, map, Subject, switchMap, takeUntil,} from "rxjs";

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  isLoading: boolean = false;
  tags: Tag[] = [];
  fullTags: Tag[] = [];
  hasAccess: Access = Access.validating;
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;  
  query = "";
  searchInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _tagsService: TagsService,
    private _auth: AuthServiceClass,
    private _fuseConfirmationService: FuseConfirmationService,
    private _titleService: Title,

    public dialog: MatDialog
  ) {
  }

  async ngOnInit() {
    this._titleService.setTitle('Hapbee OpsPortal - Tags');
    let perms = await this._auth.getPermissions();
    if (perms.find(s => s === 'read:tag')) {
      this.hasAccess = Access.yes;
    } else {
      this.hasAccess = Access.no;
      return;
    }
    this.canAdd = perms.includes('create:tag');
    this.canEdit = perms.includes('update:tag');
    this.canDelete = perms.includes('delete:tag');

    await this.getTags();
    
        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges
        .pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(500),
          switchMap(async (query) => {
            this.isLoading = true;
            this.query = query.trim();
            if(this.query){
              this.tags = this.tags.filter(o => o.title.toLowerCase().includes(this.query.toLowerCase()));
            }
            else{
              this.tags = this.fullTags;
            }
  
          }),
          map(() => {
            this.isLoading = false;
          })
        )
        .subscribe();
        
  }

  async clearInput() {
    this.isLoading = true;
    this.searchInputControl.setValue('');
    this.tags = this.fullTags;
    this.isLoading = false;
  }


  edit(tag) {
    let dialogref = this.dialog.open(EditTagComponent, {disableClose: true, data: tag});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res){
        await this.getTags();
      }
    });
  }

  async remove(tag) {
    this._fuseConfirmationService.open({message: 'Are you sure you want to delete this TAG?'}).afterClosed().subscribe(e => {
      if (e !== 'confirmed') {
        return;
      }
      this._tagsService.removeTag(tag).subscribe(async (e: any) => {
        this.isLoading = true;
        await this.getTags();
        this.isLoading = false;
        this.dialog.open(SharedAlertComponent,
        {
          width: 'auto',
          disableClose: false,
          data: {dialogMessage:'Tag : ' + tag.title + ' is deleted successfully'}
        });
      });
    });
  }

  async onTableDataChange(event: any){
    this.page = event;
    await this.tags;
  }

  add() {
    let dialogref = this.dialog.open(AddTagComponent, {disableClose: true});
    dialogref.afterClosed().subscribe(async (res) => {
      if (res){
        await this.getTags();
      }     
    });
  }

  async getTags() {
    this.tags = await this._tagsService.getTags();
    this.tags.sort(function(a, b) {
      return (a.sortOrder < b.sortOrder) ? -1 : ((a.sortOrder > b.sortOrder) ? 1 : 0);
    });

    this.fullTags = this.tags;
  }
}
